.pageBody {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wifiScanDiv {
    position: relative;
    height: 100%;
    width: 70%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.initiatingText {
    position: absolute;
    left: 5vw;
    top: 5vh;
}

.wifiDiv {
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 30%;
    background-color: rgb(197, 193, 193);
    height: 89.6vh;
    padding-top: 5vh;
}
.wifiImage {
    /* cursor: pointer; */
    animation: ripple 1.5s linear infinite;
    border-radius: 100%;
}

.wifiName {
    border-bottom: 1px solid rgb(110, 110, 110);
    margin-bottom: 0;
    padding: 5px;
    width: 90%;
    cursor: pointer;
}

.wifiName:hover {
    background-color: gray; /* Change this to your desired hover color */
}

.passwordInput {
    width: 300px;
    padding: 10px;
    border-radius: 8px;
}
.processBarModel {
    /* position: absolute; */
    height: 85vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* z-index: 100000; */
}
.step-text {
    margin-bottom: 40px;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.3),
            0 0 0 3px rgba(0, 0, 0, 0.3), 0 0 0 5px rgba(0, 0, 0, 0.3);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3), 0 0 0 40px rgba(0, 0, 0, 0.1),
            0 0 0 200px rgba(0, 0, 0, 0), 0 0 0 400px rgba(0, 0, 0, 0);
    }
}
