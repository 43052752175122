.carousal_container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ui-card {
    height: 12rem;
    width: 12rem;
    position: relative;
    z-index: 1;
    transform: scale(0.6) translateY(-2rem);
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
    background: transparent;
    /* background: #2e5266; */
    /* background: linear-gradient(to top, #2e5266, #6e8898); */
    transition: 1s;
}

.ui-card:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: -5rem;
    filter: blur(4px);
}

.active {
    z-index: 3;
    transform: scale(1) translateY(0) translateX(0);
    opacity: 1;
    pointer-events: auto;
    transition: 1s;
}

.prev,
.next {
    z-index: 2;
    transform: scale(0.8) translateY(-1rem) translateX(0);
    opacity: 0.6;
    pointer-events: auto;
    transition: 1s;
}

.mx-5 {
    margin-left: 6rem !important;
    margin-right: 0rem !important;
}
