* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Halcyon_SemiBold';
    src: url('./Halcyon/Halcyon-SemiBold.otf');
}

@font-face {
    font-family: 'Halcyon_Medium';
    src: url('./Halcyon/Halcyon-Medium.otf');
}

@font-face {
    font-family: 'Halcyon_Regular';
    src: url('./Halcyon/Halcyon-Regular.otf');
}

.iconBtnSize {
    height: 60px;
    width: 60px;
    cursor: pointer;
}

a {
    text-decoration: none !important;
}
.react-flow__edge {
    /* cursor: crosshair; */
    cursor: url('./Assets/flowchart/cursor.cur') 10 0, auto;
}
