.InternalAccessoriesScreen_Main {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 80% 10%;
}

.InternalAccessoriesScreen_Main .Item-1 {
    /* height: 70%; */
    width: 80%;
    /* border: 1px solid purple; */
    position: relative;
    /* margin: 3% 0% 0% 12%; */
    transform: translate(15%, 15%);
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: 100%;
}
.InternalAccessoriesScreen-Item2 .input {
    margin-top: 10px;
}
.InternalAccessoriesScreen-Item2 .onput {
    margin-top: 10px;
}
.InternalAccessoriesScreen-Item2 .input .inputText {
    font-size: 18px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}
.onput .txtTitle {
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}
.InternalAccessoriesScreen-Item1 {
    position: relative;
    height: 80%;
    width: 100%;
    top: 50%;
    transform: translate(-0%, -50%);
}

/* .InternalAccessoriesScreen-Item1 .micPc {
    width: 2%;
    height: auto;
    position: fixed;
    top: 45%;
    left: 25%;
    transform: translate(-25%, -45%);
  } */

.device .deviceContainer {
    height: 75%;
    width: 80%;
    /* border: 1px solid green; */
    position: relative;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.device .deviceContainer .imgStyleMic {
    width: 10%;
    position: absolute;
    top: -30%;
    left: 50%;
    transform: translate(-48%, -5%);
    z-index: -1;
}

.device .deviceContainer .imgStyleBuzzer {
    /* border: 1px solid red; */
    width: 100%;
    height: 40%;
    position: absolute;
    top: -15%;
    left: 1%;
    transform: translate(-1.5%, 0%);
    z-index: 3;
}

.device .deviceContainer .imgStyleEyeL {
    width: 12%;
    position: absolute;
    top: 36%;
    left: 31%;
}

.device .deviceContainer .imgStyleEyeR {
    width: 12%;
    position: absolute;
    top: 36%;
    left: 59%;
}

.device .deviceContainer .imgStyleTeeth1 {
    width: 2.7%;
    height: 18px;
    position: absolute;
    top: 71.6%;
    left: 39.7%;
}
.device .deviceContainer .imgStyleTeeth2 {
    width: 2.7%;
    height: 18px;
    position: absolute;
    top: 73.1%;
    left: 43%;
}

.device .deviceContainer .imgStyleTeeth3 {
    width: 2.7%;
    height: 18px;
    position: absolute;
    top: 73.2%;
    left: 55.1%;
}

.device .deviceContainer .imgStyleTeeth4 {
    width: 2.7%;
    height: 16px;
    position: absolute;
    top: 71.6%;
    left: 58.6%;
}

.device .deviceContainer .imgStyle4in1 {
    width: 7.5%;
    height: 16px;
    position: absolute;
    top: 73%;
    left: 47%;
}

.device .deviceContainer .imgStyleTouchpads1,
.device .deviceContainer .imgStyleTouchpads2,
.device .deviceContainer .imgStyleTouchpads3 {
    height: 16.5%;
    position: absolute;
    bottom: -22.5%;
    left: 15.3%;
    transform: translateX(-23.5%);
}

.device .deviceContainer .imgStyleTouchpads2 {
    left: 31%;
    transform: translateX(-34.8%);
}

.device .deviceContainer .imgStyleTouchpads3 {
    left: 49.5%;
    transform: translateX(-47.5%);
}

.InternalAccessoriesScreen-Item2 {
    height: 100%;
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
}

.InternalAccessoriesScreen-Item2 .input .inputContiner,
.InternalAccessoriesScreen-Item2 .onput .outputContiner {
    width: 70%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: repeat(3, 33%);
    grid-template-columns: 100%;
}

.InternalAccessoriesScreen-Item2 .onput .container-row,
.InternalAccessoriesScreen-Item2 .input .container-row {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.InternalAccessoriesScreen-Item2 .input .inputText {
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}
.InternalAccessoriesScreen-Item2 .onput .outputContiner {
    width: 75%;
}

.input .inputContiner .imgStyle {
    width: 20%;
    height: 70%;
    margin-right: 4%;
    cursor: pointer;
}
.input .inputContiner .space1 {
    margin-left: 2%;
}
.onput .outputContiner .imgStyle {
    width: 20%;
    height: 70%;
    margin-right: 4%;
    cursor: pointer;
}

.onput .outputContiner .space {
    margin-left: 3%;
}

#snackbar1,
#snackbar2,
#snackbar3,
#snackbar4,
#snackbar5,
#snackbar6,
#snackbar7,
#snackbar8,
#snackbar9,
#snackbar10,
#snackbar11,
#snackbar12,
#snackbar13,
#snackbar14,
#snackbar15,
#snackbar16,
#snackbar17,
#snackbar18,
#snackbar19 {
    visibility: hidden;
    min-width: 150px;
    margin-left: -125px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: -50%;
    font-size: 17px;
}

#snackbar1.show,
#snackbar2.show,
#snackbar3.show,
#snackbar4.show,
#snackbar5.show,
#snackbar6.show,
#snackbar7.show,
#snackbar8.show,
#snackbar9.show,
#snackbar10.show,
#snackbar11.show,
#snackbar12.show,
#snackbar13.show,
#snackbar14.show,
#snackbar15.show,
#snackbar16.show,
#snackbar17.show,
#snackbar18.show,
#snackbar19.show,
#snackbar12_M1.show,
#snackbar13_M2.show,
#snackbar14_M3.show,
#snackbar15_M4.show,
#snackbar16_D1.show,
#snackbar17_D1.show,
#snackbar18_D1.show,
#snackbar19_D1.show,
#snackbar6_A1.show,
#snackbar3_A1.show,
#snackbar4_B1.show,
#snackbar7_B1.show,
#snackbar5_C1.show,
#snackbar8_C1.show,
#snackbar3_A2.show,
#snackbar4_B2.show,
#snackbar5_C2.show,
#snackbar16_D2.show,
#snackbar17_D2.show,
#snackbar18_D2.show,
#snackbar19_D2.show {
    visibility: visible;
    /* -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s; */
}
.isDisableBtn {
    opacity: 0.5;
}
#snackbar3_A2,
#snackbar4_B2,
#snackbar5_C2,
#snackbar16_D2,
#snackbar17_D2,
#snackbar18_D2,
#snackbar19_D2 {
    visibility: hidden;
    min-width: 150px;
    margin-left: -125px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 10px;
    position: fixed;
    z-index: 1;
    left: 45%;
    bottom: -50%;
    font-size: 17px;
}

.Ss_slide {
    width: 101vw;
    height: 100vh;
    position: absolute;
    /* margin-left: 15px; */
    z-index: 100000;
    background: #fff;
    /* top: 118%; */
    top: 49vh;
    left: 48%;
    transform: translate(-50%, -50%);
}
.helpClo {
    position: absolute;
    top: 19%;
    left: 78%;
    transform: translate(-50%, -50%);
    /* margin-right: 17%; */
    /* top: 70%; */
    /* height: 7%; */
    width: 2.5%;
    /* margin-top: 42%;
  margin-right: 33%; */
    z-index: 100000;
}

@-webkit-keyframes fadein {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@keyframes fadein {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@keyframes fadeout {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@media screen and (max-width: 768px) and (min-width: 508px) {
    .imgStyleMic {
        margin-top: -10px;
    }
}
@media screen and (max-width: 1124px) and (min-width: 600px) {
    .device {
        margin-top: 45px;
    }
    /* changes start */
    .input_one {
        top: 65px !important;
        /* border: 1px solid red; */
    }
    /* changes end */
    .onput {
        top: 65px;
    }
    /* .imgStyleMic {
    margin-top: -20px;
  } */
    .imgStyleEyeL {
        margin-top: -5px;
    }
    .imgStyleEyeR {
        margin-top: -5px;
    }
    .imgStyleTeeth1,
    .imgStyleTeeth2,
    .imgStyleTeeth3,
    .imgStyleTeeth4 {
        margin-top: -2px;
    }
    #snackbar3_A2,
    #snackbar4_B2,
    #snackbar5_C2,
    #snackbar16_D2,
    #snackbar17_D2,
    #snackbar18_D2,
    #snackbar19_D2 {
        left: 45%;
        bottom: -70%;
    }
}

/* @media screen and (max-width: 2124px) and (min-width: 1868px) {
    .imgStyleMic {
      margin-top: -10px;
    }
    .device .deviceContainer .imgStyleEyeL {
      width: 9.5%;
      height: 27px;
      position: absolute;
      top: 34%;
      left: 32%;
    }
  
    .device .deviceContainer .imgStyleEyeR {
      width: 9.5%;
      height: 27px;
      position: absolute;
      top: 34%;
      left: 60%;
    }
  
    .device .deviceContainer .imgStyleTeeth1 {
      width: 2.7%;
      height: 18px;
      position: absolute;
      top: 70.6%;
      left: 39.7%;
    }
    .device .deviceContainer .imgStyleTeeth2 {
      top: 72.1%;
      left: 43%;
    }
  
    .device .deviceContainer .imgStyleTeeth3 {
      top: 72.2%;
    }
  
    .device .deviceContainer .imgStyleTeeth4 {
      top: 70.6%;
    }
  
    .device .deviceContainer .imgStyle4in1 {
      top: 73%;
    }
  } */

/* CSS for sliding from left to right */
.slide-left {
    animation: slideLeft 0.6s ease;
}

/* CSS for sliding from right to left */
.slide-right {
    animation: slideRight 0.6s ease;
}

.slide-animation img.no-animation {
    animation-play-state: paused;
}
/* Animation keyframes for sliding from left to right */
@keyframes slideLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

/* Animation keyframes for sliding from right to left */
@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

/* Apply the animation to your page element */
.slideDown {
    animation: slideDown 1s ease;
}
