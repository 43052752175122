.main {
    position: relative;
    width: 85%;
    height: 50%;
    /* background-color:grey; */
    left: 10%;
    top: 20%;
}

.saveFlowHeadingTxt2 {
    font-family: 'Halcyon_Regular' !important;
}
.saveFlowHeadingTxt {
    font-size: 23px;
    margin-top: 2%;
    font-family: 'Halcyon_SemiBold' !important;
}
.main > div {
    padding-top: 50px;
}
.main > div > input {
    width: 80%;
    height: 30px;
    border: 2px solid rgb(63, 4, 110);
    border-radius: 30px;
    outline: transparent;
    text-align: center;
}
.main > div > label {
    font-size: 16px;
    font-weight: 500;
    color: rgb(63, 4, 110);
}
.right_div {
    position: relative;
    height: 56%;
    width: 86%;
    border: 3px solid rgb(63, 4, 110);
    border-radius: 30px;
    margin: 25% 0 0 5%;
}
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading_gif {
    position: relative;
    left: 37%;
}
.project_card {
    /* border: 2px solid black !important;
    border-radius: 5% !important;
    box-shadow: 5px 5px lightgrey; */
}

.savedFlowContainer {
    width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
}
#SaveAlert {
    visibility: hidden;
    min-width: 160px;
    margin-left: -75px;
    background-color: #e8e6e6;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 12px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 10%;
    font-size: 17px;
}
#SaveAlert.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.SavedFlowRow {
    height: 72%;
    width: 82%;
    display: grid;
    margin: 7% 0% 0% 5%;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: repeat(3, 33.3%);

    column-gap: 35px;
    row-gap: 30px;
    /* flex-wrap: wrap; */
}

.SavedProgramCard {
    width: 17.6%;
    height: 165px;
    top: 13%;
    left: 8%;
    position: absolute;
    /* margin-left: 14px;
    margin-right: 1%;
    margin-bottom: 1%; */
    /* position: relative; */
    background-image: url('../../Assets/Bisoft_UI/Program/PNG/default image.png');
    /* background-color: #f8f9fa; */
    /* border: 1px solid rgba(0, 0, 0, 0.125); */
    /* border-radius: 0.25rem; */
    /* display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 53% 45%; */
}

.cardImg {
    height: 148%;
    width: 117%;
    margin-left: -8%;
    margin-top: -16%;
    border-radius: 0.25rem;
}

.SaveProgCard_body {
    height: 100%;
    width: 100%;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1.2vw;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}
.btn-primary.focus,
.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}
.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.ml-2 {
    margin-left: 0.5rem;
}

.SaveProgcard-title {
    font-size: 18px;
    font-weight: 500;
}

.SaveProgcard-text {
    font-size: 14px;
}

.projectContainer {
    height: 100vh;
    width: 100vw;
}

.projectMain {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-template-rows: 100%;
    column-gap: 2%;
}

.Projectitem_1 {
    margin-left: 15px;
}

.Projectitem_2 {
    margin-right: 15px;
}

.mb-3,
.my-3 {
    margin-bottom: 0.7rem;
}

.mt-5,
.my-5 {
    margin-top: 3rem;
}
.formProject input[type='text'] {
    width: 96%;
    /* padding: 12px 20px;
    
    box-sizing: border-box;
    border: 2px solid red;
    border-radius: 4px; */
    margin: 8px 0;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;
}

.formProject input[type='text']:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.formProject input[type='text']:read-only {
    background-color: #e9ecef;
}

.Slide {
    width: 100vw;
    height: 15vh;
    /* margin-top: 1%; */
    position: absolute;
    left: -4%;
    z-index: 100000;
    background: #fff;
}
.hpClose {
    position: absolute;
    margin-top: 45%;
    margin-left: 92%;
    /* height: 10%; */
    /* width: 5%; */
    z-index: 100000;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .btn {
        font-size: 1vw;
    }
}

@media screen and (max-width: 768px) and (min-width: 450px) {
    .btn {
        font-size: 0.9vw;
    }
}
