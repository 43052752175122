.aiModeSelectDiv {
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translateY(-50%);
    height: 41%;
    width: 18%;
    background-color: white;
    z-index: 1000001;
    border-radius: 20px;
    box-shadow: 13px 0px 39px -9px rgba(0, 0, 0, 0.25);
}
.aiModeSelect_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1%;
    width: 100%;
    height: 100%;
}
.aiModeSelect_row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    width: 100%;
}

.aiModel_Selectpopup {
    min-width: 150px;
    margin-left: -125px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 9%;
    font-size: 17px;
}
