body {
    background-color: #fafafa;
}
.Main_Remote {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 12% 78% 10%;
    grid-template-columns: 100%;
}
.Remote_Header {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 7% 13% 66% 7% 7%;
    grid-template-rows: 100%;
}
.Back_BTN {
    top: 4.5%;
    position: absolute;
    width: 4%;
    left: 2.5%;
    cursor: pointer;
}
.Play_Remote {
    height: auto;
    width: 10%;
    font-size: 25px;
    position: absolute;
    top: 5.5%;
    font-family: 'Halcyon_SemiBold' !important;
}
.Help_Btn {
    height: auto;
    width: 4%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 6%;
}
.Bluetooth_Button {
    height: auto;
    width: 6%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 0.1%;
}
.Remote_Body {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 35% 30% 35%;
    grid-template-rows: 100%;
}
.Buzzer_Rgbeye {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
    /* position: absolute; */
    /* top: 10%; */
}
.Ace_Teeth {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 70% 30%;
    grid-template-columns: 100%;
    /* border: 1px solid red; */
}

.Left_Eye_Backdround_v1 {
    width: 22%;
    position: absolute;
    top: 68%;
    left: 19.5%;
    transform: translate(-50%, -50%);
}

.Right_Eye_Backdround_v1 {
    width: 22%;
    position: absolute;
    top: 68%;
    right: -2%;
    transform: translate(-50%, -50%);
}

.Buzzer_Partition {
    height: 100%;
    width: 100%;
    display: grid;
    /* position: relative; */
    grid-template-rows: 80% 20%;
    grid-template-columns: 100%;
}

.Slider_Div1_v1 {
    height: 24%;
    width: 20%;
    display: grid;
    position: absolute;
    top: 55.5%;
    left: 9.9%;
    grid-template-rows: repeat(3, 33%);
    grid-template-columns: 100%;
}
.Slider_Div2_v1 {
    height: 24%;
    width: 20%;
    display: grid;
    position: absolute;
    top: 55.5%;
    right: 9.9%;
    grid-template-rows: repeat(3, 33%);
    grid-template-columns: 100%;
}
.Slider_Div3_v1 {
    height: 5%;
    width: 16%;
    display: grid;
    position: absolute;
    top: 54%;
    left: 42%;
}

.Buzzer_Bg_v1 {
    height: auto;
    width: 20%;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Buzzer_txt_v1 {
    height: 34px;
    width: 64px;
    font-size: 18px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Left_Eye_txt_v1 {
    height: 34px;
    width: auto;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    font-size: 18px;
    bottom: 5%;
    left: 19.5%;
    transform: translate(-50%, -50%);
}

.Right_Eye_txt_v1 {
    height: 34px;
    width: auto;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    font-size: 18px;
    bottom: 5%;
    right: 14.5%;
    transform: translate(-50%, -50%);
}

.Level_IA {
    height: 73px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 37.5%;
    left: 13%;
    transform: translate(-50%, -50%);
}

.Disco_IA_v1 {
    height: 80px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 38%;
    left: 19.5%;
    transform: translate(-50%, -50%);
}

.Gesture_IA_v1 {
    height: 80px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 38%;
    left: 26%;
    transform: translate(-50%, -50%);
}

.Level_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 14.5%;
    transform: translate(-50%, -50%);
}

.Disco_txt_v1 {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 21%;
    transform: translate(-50%, -50%);
}

.Gesture_txt_v1 {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 27%;
    transform: translate(-50%, -50%);
}

.Dice_IA {
    height: 72px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 37.5%;
    left: 73%;
    transform: translate(-50%, -50%);
}
.Guitar_IA {
    height: 72px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 37.5%;
    left: 79.5%;
    transform: translate(-50%, -50%);
}
.Simon_IA {
    height: 72px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 37.5%;
    left: 86%;
    transform: translate(-50%, -50%);
}

.Dice_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 74.8%;
    transform: translate(-50%, -50%);
}
.Guitar_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 81%;
    transform: translate(-50%, -50%);
}
.Simon_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 87%;
    transform: translate(-50%, -50%);
}

.PC_img {
    height: auto;
    width: 50.5%;
    position: relative;
    /* background-repeat: no-repeat;
  background-size: 2%; */
    z-index: 0;
    top: 23.8%;
    left: 22.8%;
    /* transform: translate(-50%, -50%); */
}

/* .PC_img {
  width: 300px;
  height: 320px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.Rgb_Eyes {
    width: 2.6%;
    height: auto;
    position: absolute;
    transform: translate(-50%, -50%);
}

.Buzzer_Inactive {
    /* height: 65px;
  width: 220px; */
    height: auto;
    width: 80%;
    position: absolute;
    top: 11%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth1_v1 {
    width: 1.5%;
    height: auto;
    position: absolute;
    top: 61%;
    left: 45.5%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth2_v1 {
    width: 1.5%;
    height: auto;
    position: absolute;
    top: 61.8%;
    left: 47.2%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth3_v1 {
    width: 1.5%;
    height: auto;
    position: absolute;
    top: 61.8%;
    left: 53%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth4_v1 {
    width: 1.5%;
    height: auto;
    position: absolute;
    top: 61%;
    left: 55%;
    transform: translate(-50%, -50%);
}
.Ace_4Teeth_v1 {
    width: 3.5%;
    height: auto;
    position: absolute;
    top: 60.6%;
    left: 48%;
    transform: translate(-50%, -50%);
    transform: rotate(90deg);
}

.Teeth_Bg_v1 {
    height: auto;
    width: 20%;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Teeth_Smile1_v1 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 80%;
    left: 43.7%;

    transform: translate(-50%, -50%);
}
.Teeth_Smile2_v1 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 80%;
    left: 48%;
    transform: translate(-50%, -50%);
}
.Teeth_Smile3_v1 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 80%;
    left: 52.3%;
    transform: translate(-50%, -50%);
}
.Teeth_Smile4_v1 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 80%;
    left: 56.5%;
    transform: translate(-50%, -50%);
}
.Teeth_text {
    position: absolute;
    left: 50%;
    bottom: 2%;
    font-size: 20px;
    transform: translate(-50%, -50%);
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
}

.Rem_Slider {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0%;
    z-index: 100000;
    background: #fff;
    border: 1px solid red;
}
.Cros {
    position: absolute;
    right: 13%;
    top: 8%;
    height: 10%;
    width: 5%;
    z-index: 100000;
}

#toastMessage {
    left: 50%;
    position: absolute;
    bottom: 8%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    padding: 16px;
    border-radius: 25px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    font-family: 'Halcyon_Regular' !important;
}

.Roboki_Rgb_Eye {
    width: 4.5%;
    height: auto;
    position: absolute;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1300px) and (min-width: 1124px) {
    .Ace_Teeth {
        margin-top: 10%;
    }
}
@media screen and (max-width: 1124px) and (min-width: 600px) {
    .Ace_Teeth {
        margin-top: 80px;
    }
    .Play_Remote {
        /* height: auto; */
        /* width: 10%; */
        font-size: 23px;
        /* position: absolute; */
        top: 4.5%;
        /* font-family: "Halcyon_SemiBold" !important; */
    }
    .Talkback_IA {
        height: 65px;
        width: 65px;
    }
    .Disco_IA {
        height: 65px;
        width: 65px;
    }
    .Disco_txt {
        left: 21.5%;
        font-size: 15px;
        top: 47.5%;
    }
    .Talkback_txt {
        font-size: 15px;
        top: 47.5%;
    }

    .Gesture_txt {
        font-size: 15px;
        top: 47.5%;
    }
    .Gesture_IA {
        height: 65px;
        width: 65px;
    }
    .Teeth_Smile1 {
        height: 38px;
        width: 38px;
    }
    .Teeth_Smile2 {
        height: 38px;
        width: 38px;
    }
    .Teeth_Smile3 {
        height: 38px;
        width: 38px;
    }
    .Teeth_Smile4 {
        height: 38px;
        width: 38px;
    }

    .Rgb_Eye_txt {
        font-size: 19px;
    }
    .Buzzer_txt {
        font-size: 16px;
        top: 36%;
        left: 12%;
    }
    .Slider_Div2 {
        height: 18%;
        width: 18%;
    }
    .Slider_Div1_v1 {
        height: 18%;
        width: 18%;

        left: 11%;
    }
    .Slider_Div3 {
        width: 15%;
        left: 15%;
    }
}

@media screen and (max-width: 1000px) and (min-width: 850px) {
    .Slider_Div2 {
        height: 16%;

        top: 57.5%;
    }
    .Slider_Div1_v1 {
        height: 16%;

        top: 57.5%;
    }
    .Slider_Div3 {
        top: 33%;
    }
    .Play_Remote {
        font-size: 21px;

        top: 4.1%;
    }
    .Disco_txt {
        left: 82%;
        font-size: 14px;
    }
    .Talkback_txt {
        font-size: 14px;
    }

    .Gesture_txt {
        left: 88%;
        font-size: 14px;
    }
}
@media screen and (max-width: 851px) and (min-width: 750px) {
    .Slider_Div2 {
        height: 13%;

        top: 58.5%;
    }
    .Slider_Div1_v1 {
        height: 13%;

        top: 58.5%;
    }
    .Slider_Div3 {
        top: 33%;
    }
    .Play_Remote {
        font-size: 20px;

        top: 4%;
    }
    .Disco_txt {
        left: 82%;
        font-size: 14px;
    }
    .Talkback_txt {
        font-size: 14px;
    }

    .Gesture_txt {
        left: 88%;
        font-size: 14px;
    }
    .Teeth_Bg {
        top: 69%;
    }

    .Teeth_Smile1,
    .Teeth_Smile2,
    .Teeth_Smile3,
    .Teeth_Smile4 {
        height: 33px;
        width: 33px;
        top: 69.5%;
    }
}
@media screen and (max-width: 750px) and (min-width: 600px) {
    .Slider_Div2 {
        height: 13%;

        top: 58.5%;
    }
    .Slider_Div1_v1 {
        height: 13%;
        left: 12%;
        top: 58.5%;
    }
    .Slider_Div3 {
        top: 33%;
    }
    .Play_Remote {
        font-size: 19px;

        top: 4%;
    }

    .Rgb_Eye_txt {
        font-size: 16px;
        top: 66%;
    }
    .Rgb_Eye_txt {
        left: 11%;
    }
    .Talkback_IA,
    .Disco_IA,
    .Gesture_IA {
        height: 58px;
        width: 58px;
    }
    .Gesture_IA {
        left: 88%;
    }
    .Disco_IA {
        left: 80.5%;
    }
    .Disco_txt {
        left: 83.8%;
        font-size: 13px;
    }
    .Buzzer_txt {
        font-size: 13px;
        top: 36.5%;
        left: 13%;
    }
    .Talkback_txt {
        font-size: 13px;
    }

    .Gesture_txt {
        left: 90.5%;
        font-size: 13px;
    }
    .Teeth_Bg {
        top: 69%;
    }

    .Teeth_Smile1,
    .Teeth_Smile2,
    .Teeth_Smile3,
    .Teeth_Smile4 {
        height: 28px;
        width: 28px;
        top: 69.3%;
    }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
    .Ace_Teeth {
        margin-top: 80px;
    }
    .Slider_Div2 {
        height: 9%;
        width: 18%;
        top: 57%;
        right: 7%;
    }
    .Slider_Div1_v1 {
        height: 9%;
        width: 18%;
        left: 13%;
        top: 57%;
    }
    .Slider_Div3 {
        top: 35.5%;
        width: 14%;

        left: 16%;
    }
    .Play_Remote {
        font-size: 17px;
        top: 4.2%;
    }
    .Rgb_Eye_txt {
        font-size: 16px;
    }
    .Rgb_Eye_txt {
        left: 12%;
    }
    .Talkback_IA,
    .Disco_IA,
    .Gesture_IA {
        height: 42px;
        width: 42px;
    }
    .Gesture_IA {
        left: 88%;
    }
    .Disco_IA {
        left: 80.5%;
    }
    .Disco_txt {
        left: 85%;
        font-size: 11px;
        top: 46.5%;
    }
    .Buzzer_Bg {
        top: 38%;
    }
    .Buzzer_txt {
        font-size: 12px;
        top: 38.5%;
        left: 14%;
    }
    .Talkback_txt {
        font-size: 11px;
        top: 46.5%;
        left: 74.5%;
    }

    .Gesture_txt {
        left: 92%;
        top: 46.5%;
        font-size: 11px;
    }
    .Teeth_Bg {
        top: 65%;
    }

    .Teeth_Smile1,
    .Teeth_Smile2,
    .Teeth_Smile3,
    .Teeth_Smile4 {
        height: 24px;
        width: 24px;
        top: 65.5%;
    }

    .Rgb_Eye_Backdround,
    .Rgb_Eye_txt {
        top: 63%;
    }
    .Right_Eye_txt {
        left: 73%;
    }
}
