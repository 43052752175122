.Select_Device {
    height: 100vh;
    width: 100vw;
    background-color: #fafafa;
}

.Select_Device_Panel {
    position: relative;
    height: 100%;
    width: 100%;
}

.Back_Button {
    height: 60px;
    width: 60px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: 31px;
}
.Help_Button {
    height: 60px;
    width: 60px;
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 31px;
}
.Title {
    width: 350px;
    height: 45px;
    position: absolute;
    font-family: 'Halcyon_SemiBold' !important;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.PC_Image {
    width: 220px;
    height: 240px;
    position: absolute;
    cursor: pointer;
    top: 44%;
    left: 49.5%;
    transform: translate(-50%, -50%);
}
.Zing_Image {
    width: 220px;
    height: 240px;
    position: absolute;
    cursor: pointer;
    top: 44%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.Play_PC {
    width: 150px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    color: #707070;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    top: 63%;
    left: 49.5%;
    transform: translate(-50%, -50%);
}
.Play_Zing {
    width: 150px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    color: #707070;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    top: 63%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.Add_Button {
    height: 80px;
    width: 80px;
    position: absolute;
    bottom: 3%;
    right: 2%;
}

.mod {
    /* transform: translate(-50%,-50%); */
    z-index: 1000;
    background-color: #fff;
    /* border-radius: 50%; */
}
.helpClose {
    position: absolute;
    right: 13%;
    top: 8%;
    height: 10%;
    width: 5%;
    z-index: 100000;
}

.cc {
    height: 80px;
    width: 80px;
    position: absolute;
    bottom: 15%;
    right: 10%;
}

@media only screen and (max-device-width: 1053px) {
    .Play_PC {
        top: 65.5%;
    }
}
@media only screen and (max-device-width: 1440px) {
    .PC_Image {
        width: 180px;
        height: 200px;
    }
}
@media only screen and (max-device-width: 1024px) {
    .PC_Image {
        width: 253px;
        height: 273px;
        /* position: absolute; */
        top: 46%;
        /* left: 50%; */
        /* transform: translate(-50%, -50%); */
    }
    .Play_PC {
        /* width: 100px; */
        /* height: 20px; */
        font-size: 14px;
        /* color: #707070; */
        /* position: absolute; */
        top: 64%;
        left: 49.5%;
    }
}
/* changes start */
@media only screen and (max-device-width: 768px) {
    .Title {
        /* top: 10%; */
        left: 55%;
        /* transform: translate(-50%, -10%); */
    }
}
/* changes end */
