.Arm_Body {
    height: 100%;
    width: 100%;
    display: grid;
    /* grid-template-columns: 35% 35% 30%; */
    grid-template-rows: 100%;
}

.Arm_Left {
    align-self: center;
    justify-self: center;
}

.Arm_Main {
    display: grid;
    grid-template-rows: 20% 20%;
    grid-template-columns: 19% 20% 20%;
    margin-left: 5%;
    margin-top: 35%;
    row-gap: 5%;
    column-gap: 10%;
}

.Main_Img {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    justify-self: end;
    align-self: end;
    cursor: pointer;
}

.Main_Img_Txt {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    justify-self: end;
    cursor: pointer;
}

.Arm_Right {
    display: grid;
    grid-template-rows: 30% 30% 30%;
    grid-template-columns: 25% 25%;
    margin-left: 10%;
    margin-top: 30%;
    column-gap: 10%;
}


.loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    gap:30px;
    align-items: center;
    justify-content: center;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    position: absolute;
    top: 0;
    content: '';
  }
  
  .jimu-primary-loading:before {
    left: -19.992px;
  }
  
  .jimu-primary-loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after,
  .jimu-primary-loading {
    background: #fa9158;
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
  }
  
  .jimu-primary-loading {
    text-indent: -9999em;
    position: relative; /* Changed from absolute */
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
  }
  
  @-webkit-keyframes loading-keys-app-loading {
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #fa9158;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #fa9158;
      height: 40px;
    }
  }
  
  @keyframes loading-keys-app-loading {
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 #fa9158;
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px #fa9158;
      height: 40px;
    }
  }
  
  .loading-text {
    display: absolute; 
    margin-right:10px;
    font-size: 24px;
    font-weight: 400;
    color: black; 
  }
  