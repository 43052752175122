.ButtonDivInput {
    position: relative;
    left: 15%;
    top: 4%;
}

.Inputs_flow_left_upper_ultrasonic {
    width: 260pt;
    height: 130pt;
    margin-top: 5%;
    background-image: url('../../../../../../Assets/popup\ card-5.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 140%;
}

.Inputs_flow_left_upper_grp_ultrasonic {
    position: relative;
    top: 10%;
    left: 30%;
}
