.loopSlider {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 5rem;
    width: 200px;
    height: 10px;
    background: #f7cc5b;
}

.loopSlider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 35px;
    height: 35px;

    background: url('../../../Assets/hollowcircle.png');
    background-size: 35px 35px;
    /* background: red; */
    cursor: pointer; /* Cursor on hover */
}
