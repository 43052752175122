.actionCard-container-humanoid {
    position: relative;
    /* height: 20%;
    width: 20%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 10px; */
}

.actionCard-container-humanoid > img {
    height: 80px;
    width: 80px;
}

.actionCard-container-humanoid > p {
    position: absolute;
    bottom: -18px;
}

.actionCard-container-hexapod {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actionCard-container-hexapod > img {
    height: 100px;
    width: 100px;
    cursor: pointer;
}

.actionCard-container-hexapod > p {
    position: relative;
    bottom: 15px;
    font-size: 1rem;
}

.actionCard-title {
    font-size: 12px;
    color: #707070;
    font-family: halcyon-Medium !important;
}

.actionCard-img {
    cursor: pointer;
}
