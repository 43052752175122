.container {
    position: absolute;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Halcyon_SemiBold' !important;

    background: rgba(255, 255, 255, 0.7);
    z-index: 95000;

    /* background-color: red; */
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 400px;
    min-height: 150px;
    padding: 1rem 2rem;

    background: linear-gradient(to right, #f0eded, white);
    border-radius: 4rem;
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 2rem #d9d5d4;
}

.btngroup {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.closeBtn {
    position: relative;
    width: 3.5rem;
    /* left: 100%;
    top: -40px; */
    /* overflow: hidden; */
    left: 95%;
    top: -20px;
    cursor: pointer;
}

.btn {
    min-width: 2rem;
    padding: 0.65rem 2rem;
    border: 0px;
    border-radius: 1.8rem;
    height: 40px;

    color: white;
    background: linear-gradient(to right, #fda84e, #f97d62);
    cursor: pointer;
}

.btn:hover {
    box-shadow: 0 0 0.5rem #aaa9a8;
}

.text {
    /* margin: 0 2rem; */
    margin-top: -2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family: 'Halcyon_SemiBold' !important;
    position: relative;
    top: 0.5rem;
    white-space: pre-line;
}

.selectionContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    font-family: 'Halcyon_SemiBold' !important;
    background: rgba(255, 255, 255, 0.7);
    z-index: 95000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.selectionbox {
    width: 55vh;
    height: 55vh;
    padding: 1rem 1rem;
    background: linear-gradient(to right, #f0eded, white);
    border-radius: 7vh;
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 2rem #d9d5d4;
    position: relative;
}
.selection_SubBox {
    width: 53vh;
    height: 94%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.selection_SubBox .img {
    width: 18vh;
    margin: -5% 20% 0% 31.5%;
}
.closeBtns {
    position: absolute;
    top: -4.5%;
    right: -1.8%;
    width: 5vh;
}

.btns {
    padding: 1.5vh 3vh;
    border: 0px;
    border-radius: 1.8rem;
    color: white;
    background: linear-gradient(to right, #fda84e, #f97d62);
    cursor: pointer;
    font-family: 'Halcyon_Medium' !important;
    font-size: 1.5vh !important;
    /* position: absolute; */
}
.btns_disabled {
    opacity: 0.3;
    padding: 1.5vh 3vh;
    border: 0px;
    border-radius: 1.8rem;
    color: white;
    background: linear-gradient(to right, #fda84e, #f97d62);
    cursor: pointer;
    font-family: 'Halcyon_Medium' !important;
    font-size: 1.5vh !important;
    /* position: absolute; */
    /* bottom: 20px; */
}

.mismatchContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    font-family: 'Halcyon_SemiBold' !important;
    background: rgba(255, 255, 255, 0.7);
    z-index: 95000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mismatchbox {
    min-width: 45vh;
    /* border: 2px solid red; */
    width: fit-content;
    min-height: 38vh;
    height: fit-content;
    padding: 1rem 1rem;
    background: linear-gradient(to right, #f0eded, white);
    border-radius: 7vh;
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 2rem #d9d5d4;
    position: relative;
}
.mismatch_SubBox {
    min-width: 45vh;
    min-height: 38vh;
    width: fit-content;
    background: linear-gradient(to right, #f0eded, white);
    display: grid;
    padding: 1rem 1rem;
    grid-template-columns: 100%;
    /* grid-template-rows: 15% 20% 25% 40%; */
    grid-template-rows: 10% 15% 25% 46%;
    border-radius: 7vh;
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 2rem #d9d5d4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mismatch_SubBox .img {
    height: 10vh;
    position: relative;
    left: 50%;
    top: -25%;
    transform: translate(-50%, 0%);
}

.connect_container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    font-family: 'Halcyon_SemiBold' !important;
    background: rgba(255, 255, 255, 0.7);
    z-index: 95000;

    display: flex;
    justify-content: center;
    align-items: center;
}
.connect_container1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    font-family: 'Halcyon_SemiBold' !important;
    background: rgba(255, 255, 255, 0.7);
    z-index: 95000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.connect_box {
    width: 42vh;
    min-height: 20vh;
    height: auto;
    padding: 1rem 1rem;
    background: linear-gradient(to right, #f0eded, white);
    border-radius: 7vh;
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 2rem #d9d5d4;
    position: relative;
    /* top: 10vh; */
}
.connect_box1 {
    width: 30vh;
    position: relative;
    top: 10vh;
    display: flex;
    align-items: center;
}
.connect_SubBox {
    width: 37vh;
    height: 94%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60% 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.connect_SubBox .text {
    /* margin: 0 2rem; */
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Halcyon_SemiBold' !important;
}
.connect_SubBox .text .content {
    width: 80%;
    font-size: 2vh;
    text-transform: capitalize;
}

.connect_SubBox .text .error {
    width: 13%;
}
.connect_SubBox .text .error img {
    width: 100%;
}
.connect_box .btns {
    width: 30%;
    padding: 1vh 2vh;
}

.connect_box1 .content1 {
    font-size: 2vh;
    text-transform: capitalize;
    color: darkblue;
}
.connectingSpinner {
    width: 7vh;

    display: inline-block;
    position: relative;
    /* width: 50px; */
    height: 5vh;
}
.connectingSpinner div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 10px;
    background: rgb(73, 71, 71);
    animation: connectingSpinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.connectingSpinner div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.connectingSpinner div:nth-child(2) {
    left: 25px;
    animation-delay: -0.12s;
}
.connectingSpinner div:nth-child(3) {
    left: 42px;
    animation-delay: 0;
}
@keyframes connectingSpinner {
    0% {
        top: 5px;
        height: 30px;
    }
    50%,
    100% {
        top: 20px;
        height: 15px;
    }
}
