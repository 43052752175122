.buttonGrp {
    padding: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 2rem;
    flex-wrap: wrap;
}

.buttonGrp button {
    font-family: 'Halcyon_SemiBold' !important;
    padding: 1rem;
    width: 200px;
    border: none;
    border-radius: 10px;
    background-image: linear-gradient(to right, #a6a6a6, #e4e4e4);
}

.buttonGrp .enabled {
    color: white;
    background-image: linear-gradient(to right, #ffbb46, #f97d62);
}
