.modelDiv {
    /* padding:2vw; */
    /* overflow:hidden */
    position: absolute;
    margin-left: 8vw;
    /* margin-top:0.5vw */
}

.NextButton {
    background-color: #4caf50; /* Green */
    border: none;
    /* color: white; */
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;

    position: absolute;
    top: 83vh;
    left: 75vw;
}

.button {
    background-color: rgb(160, 224, 122);
    color: black;
    border: 2px solid rgb(94, 76, 175);
    border-radius: 3vw;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.button:hover {
    background-color: rgb(76, 175, 106);
    color: white;
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.button:active {
    background-color: #da5151;
    box-shadow: 0 5px rgb(169, 199, 59);
    transform: translateY(4px);
}
