h1 {
    margin: 0;
    padding: 0;
}
html,
body,
.app {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;
}
.popup {
    position: fixed;
    width: 50%;
    height: 90%;
    top: 0;
    left: 10%;
    right: 0;
    bottom: 0;
    opacity: 1;
    margin: auto;
    background-color: rgba(12, 11, 11, 0.5);
}
.popup_inner {
    position: absolute;
    left: 125%;
    right: 25%;
    top: 125%;
    bottom: 25%;
    margin: auto;
    background: white;
}
