.Savecard_main {
    height: 100%;
    width: 100%;
    display: grid;
    /* position: relative; */
    /* margin-top: 6%; */
    /* left: 10%; */

    grid-template-columns: 100%;
    grid-template-rows: 90% 10%;
    /* border: 1px solid red; */
    background-color: #fafafa;
    color: rgb(11, 11, 11);
}
.img_Save {
    width: 100%;
    height: 100%;
    /* background: url("../../../Assets/Bisoft_UI/Program/PNG/default image.png"); */
}
.Save_deleted {
    position: absolute;
    width: 15px;
    margin-left: 15%;
    z-index: 100;
}
.Save_Names {
    font-size: 1.5rem;
    margin: -18% 0 0 0%;
    text-align: center;
    font-size: 18px;
}
