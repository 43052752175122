.Main_Container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 70% 15%;
    grid-template-areas:
        'Navbar'
        'Body'
        'empty';
}

#Navbar {
    grid-area: Navbar;
}

#Body {
    grid-area: Body;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 100%;
    grid-template-areas: ' lineGraph barGraph meterGraph subempty';
}
#empty {
    grid-area: empty;
}

#lineGraph {
    grid-area: lineGraph;
    position: relative;
}
.lineImg {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(20%, -50%);
    cursor: pointer;
}
.lineText {
    position: absolute;
    top: 60%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.lineText > p {
    font-family: Halcyon_Medium !important;
    font-size: 3vh;
}

#barGraph {
    grid-area: barGraph;
    position: relative;
}
.barImg {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}
.barText {
    position: absolute;
    top: 60%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.barText > p {
    font-family: Halcyon_Medium !important;
    font-size: 3vh;
}

#meterGraph {
    grid-area: meterGraph;
    position: relative;
}
.meterImg {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-18%, -50%);
    cursor: pointer;
}
.meterText {
    position: absolute;
    top: 60%;
    left: 20%;
    transform: translate(-50%, -50%);
}

.meterText > p {
    font-family: Halcyon_Medium !important;
    font-size: 3vh;
}
#subempty {
    grid-area: subempty;
}
