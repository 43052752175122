.Connect_Device {
    width: 100vw;
    height: 100vh;
    display: grid;
    background-color: #fafafa;
    /* border: 1px solid red; */
    grid-template-rows: 8% 58% 18% 12%;
    grid-template-columns: 100%;
}

.Connect_Header {
    height: 100%;
    width: 98%;
    display: grid;
    margin: 1.5% 0% 0% 1.5%;
    /* border: 1px solid red; */
    grid-template-columns: 5% 20% 61% 7% 7%;
    grid-template-rows: 100%;
}
.back_btn {
    text-align: center;
    height: auto;
    /* changes start*/
    width: 4%;
    /* changes start */
    position: absolute;
    cursor: pointer;
    top: 4%;
    left: 2%;
    /* margin-top: 10%; */
    /* margin-left: 10%; */
}

.PlayComp_txt {
    font-size: 25px;
    height: auto;
    width: 18%;
    position: absolute;
    /* changes start*/
    top: 5.5%;
    left: 8%;
    /* changes end */
    /* border: 1px solid red; */
    /* font-weight: bold; */
    font-family: 'Halcyon_SemiBold';
    /* margin-top: 7.5%; */
    /* margin-left: 3%; */
}
.helpBtn {
    height: auto;
    position: absolute;
    cursor: pointer;
    top: 4%;
    width: 4%;
    right: 4%;
}
.Devices_Button {
    height: auto;
    width: 4%;
    position: absolute;
    top: 4%;
}

.Connect_div {
    height: 60%;
    width: 45%;
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Computer_Img {
    width: 65%;
    height: auto;
    position: absolute;
    top: 55%;
    left: 51%;
    transform: translate(-50%, -50%);
}
.Pc_Image {
    width: 13%;
    height: auto;
    position: absolute;
    top: 53.5%;
    left: 21%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.Connect_Footer {
    height: 100%;
    width: 98%;
    display: grid;
    margin: 1.5% 0% 0% 1.5%;
    grid-template-columns: 80% 20%;
    grid-template-rows: 100%;
}
.Instructions {
    text-align: center;
    height: 20px;
    width: 600px;
    font-family: 'Halcyon_Medium';
    font-size: 15px;
    color: #707070;
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.version {
    font-family: 'Halcyon_Medium';
    font-size: 15px;
    color: #707070;
    position: relative;
    bottom: 23%;
    right: 79.5vw;
}
.Skip_Button {
    height: 90px;
    width: 90px;
    position: absolute;
    bottom: 1%;
    right: 3%;
    cursor: pointer;
}
.instr {
    position: absolute;
    top: 23%;
    right: 3.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 9%; */
    width: 43%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.instr_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.instr3 {
    position: absolute;
    top: 76%;
    right: 1%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fafafa;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 12%; */
    width: 38%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.modall {
    position: absolute;
    background-color: white;
    z-index: 10;
    width: 100%;
    height: 100%;

    /* background: #fff; */
}

.Cross {
    position: absolute;
    top: 17%;
    transform: translate(-50%, -50%);
    left: 79%;
    width: 2.5%;
    z-index: 100000;
    cursor: pointer;
}
.instr2_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.instr2 {
    position: absolute;
    top: 40%;
    right: 3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 9%; */
    width: 45%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.instr3_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}

.scanDevice {
    position: absolute;
    top: 70%;
    left: 44%;
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 23px;
    color: #fafafa;
    text-transform: uppercase;
    font-family: 'Halcyon_SemiBold';
    /* outline: none; */
    background-color: #fa9258;
    border: none;
    cursor: pointer;
}
.Scan_Button {
    width: 168px;
    height: 60px;
    text-align: center;
    position: absolute;
    cursor: pointer;

    top: -82%;
    /* changes start */
    left: 50%;
    /* changes end */
    transform: translate(-50%, -50%);
}
.Scan_txt {
    width: 130px;
    /* height: 20px; */
    margin-bottom: 0 !important;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Halcyon_SemiBold';
    color: #fff;
    cursor: pointer;
    text-align: center;
    position: absolute;
    /* changes start*/
    top: -83%;
    /* changes end */
    left: 50%;
    transform: translate(-50%, -50%);

    /* border: 1px solid red; */
}
.notify {
    /* margin-top: 5%; */
    /* margin-bottom: 10%; */
    color: red;
    font-family: 'Halcyon_SemiBold';
    text-align: center;
    cursor: pointer;
    opacity: 0.75;
}
