.ExternalAccessories-Main {
    height: 100%;
    width: 100%;
    background-color: #fcfcfc;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 90%;
    /* border: 1px solid red; */
}

.page2-content {
    animation: slide-ins 1s ease;
}

.slide-outs {
    animation: slide-out 1s ease-in-out;
    animation-direction: reverse;
}
.External_Description {
    font-size: 2.3vh;
    color: #707070;
    font-family: Halcyon_Medium !important;
}

@keyframes slide-ins {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slide-outs {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}

@media only screen and (max-width: 2500px) and (min-width: 1400px) {
    .External_Description {
        font-size: 1.7vh;
    }
}
