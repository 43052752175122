.outertabDiv-Conditions {
    position: relative;
    color: #000;
    height: 80%;
    top: 40% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-position: -61px -37px;
    background-size: 112% 112%;
    background-repeat: no-repeat;
    background-image: url('../../Assets/img/propertypanel@2x.png');
}
.outertabDiv-subConditions {
    height: 100%;
    width: 96%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 25% 18% 31% 22%;
    border-radius: 11%;
}

.outertabDiv-Condition-nobackground {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    height: 100%;
    width: 85%;
    overflow: hidden;
}

.HexDecisionPanel_Container {
    height: 109%;
    width: 84%;
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 19999;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 25% 18% 32% 25%;
    border-radius: 11%;
}

.hexsub_Container1 {
    display: grid;
    grid-template-columns: 30% 50% 20%;
    grid-template-rows: 100%;
    grid-template-areas:
        'hexcontent1_text1 hexcontent1_text2 hexcontent1_text3'
        ' hexcontent1_text1 hexcontent1_text2 hexcontent1_text3'
        'hexcontent1_text1 hexcontent1_text2 hexcontent1_text3 ';
}
#hexcontent1_text1 {
    grid-area: hexcontent1_text1;
    position: relative;
}
#hexcontent1_text2 {
    grid-area: hexcontent1_text2;
    position: relative;
}
#hexcontent1_text3 {
    grid-area: hexcontent1_text3;
    position: relative;
}

/* Second */
.hexsub_Container2 {
    border-radius: 10px;
    margin: 0 10% 0 10%;
    background-color: #dff5f2;
    display: grid;
    grid-template-columns: 20% 20% 20% 20%;
    grid-template-rows: 80% 20%;
    grid-template-areas:
        'hexcontent_div1 hexcontent_div2 hexcontent_div3 hexcontent_div4 hexcontent_div5'
        'hexsensors-txt1 hexsensors-txt2 hexsensors-txt3 hexsensors-txt4 hexsensors-txt5';
}
#hexcontent_div1 {
    grid-area: hexcontent_div1;
    /* background-color: rgb(234, 109, 109); */
    position: relative;
}

#hexcontent_div1 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
#hexcontent_div2 {
    grid-area: hexcontent_div2;
    position: relative;
    /* background-color: rgb(163, 133, 244); */
}
#hexcontent_div2 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#hexcontent_div3 {
    grid-area: hexcontent_div3;
    position: relative;
    /* background-color: rgb(243, 236, 176); */
}
#hexcontent_div3 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#hexcontent_div4 {
    grid-area: hexcontent_div4;
    position: relative;
    /* background-color: rgb(147, 246, 161); */
}
#hexcontent_div4 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#hexcontent_div5 {
    grid-area: hexcontent_div5;
    position: relative;
    /* background-color: rgb(236, 16, 250); */
}
#hexcontent_div5 > img {
    width: 6vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

#hexsensors-txt1 {
    grid-area: hexsensors-txt1;
    position: relative;
    font-size: 12px;
    color: #707070;
    align-items: center;
    display: flex;
    justify-content: center;
    /* transform: translate(-50%, -50%); */
}

#hexsensors-txt2 {
    grid-area: hexsensors-txt2;
    font-size: 12px;
    color: #707070;
    align-items: center;
    display: flex;
    justify-content: center;
}
#hexsensors-txt3 {
    grid-area: hexsensors-txt3;
    font-size: 12px;
    color: #707070;
    align-items: center;
    display: flex;
    justify-content: center;
}
#hexsensors-txt4 {
    grid-area: hexsensors-txt4;
    font-size: 12px;
    color: #707070;
    align-items: center;
    display: flex;
    justify-content: center;
}
#hexsensors-txt5 {
    grid-area: hexsensors-txt5;
    font-size: 12px;
    color: #707070;
    align-items: center;
    display: flex;
    justify-content: center;
}

/* fourth */
.hexsub_Container4 {
    border: 1px solid white;
    margin: 2% 10% 2% 10%;
    background-color: #dff5f2;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-template-rows: 100%;
    grid-template-areas:
        'hexcontent5_text1 hexcontent5_text2 hexcontent5_text3'
        ' hexcontent5_text1 hexcontent5_text2 hexcontent5_text3'
        'hexcontent5_text1 hexcontent5_text2 hexcontent5_text3 ';
}
#hexcontent5_text1 {
    grid-area: hexcontent5_text1;
    position: relative;
    color: black;
}
#hexcontent5_text2 {
    grid-area: hexcontent5_text2;
    position: relative;
}
#hexcontent5_text3 {
    grid-area: hexcontent5_text3;
    position: relative;
    cursor: pointer;
}
.hexsub1_content5 {
    position: absolute;
    top: 50%;
    transform: translate(10%, -50%);
    /* width: 9vh; */
    min-width: 9vh;
    padding: 5px;
    height: 4.5vh;
    border-radius: 15px;
}
