.select-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 80% 10%;
}

.select-item {
    height: 100%;
    width: 15%;
    /* position: absolute; */
}

.navbar-item-select {
    color: #f0f7fa;
    font-size: 20px;
    font-family: 'Halcyon-SemiBold' !important;
    position: absolute;
    top: 5%;
    left: 5%;
    transform: translate(-5%, -5%);
}

.navbar-item-assemble {
    color: black;
    font-size: 20px;
    font-family: 'Halcyon-SemiBold' !important;
    position: absolute;
    top: 5%;
    left: 22%;
    transform: translate(-22%, -5%);
}

.navbar-item-code {
    color: black;
    font-size: 20px;
    font-family: 'Halcyon-SemiBold' !important ;
    position: absolute;
    top: 5%;
    left: 43%;
    transform: translate(-43%, -5%);
}
.navbar-item-simulate {
    color: black;
    font-size: 20px;
    font-family: 'Halcyon-SemiBold' !important ;
    position: absolute;
    top: 5%;
    left: 62%;
    transform: translate(-62%, -5%);
}

.select-savebutton {
    height: 73%;
    width: 50px;
    position: absolute;
    top: 45%;
    right: 11%;
    transform: translate(-11%, -45%);
}

.select-helpbutton {
    height: 73%;
    width: 50px;
    position: absolute;
    top: 45%;
    right: 6%;
    transform: translate(-6%, -45%);
}
.select-bluetooth {
    height: 55px;
    width: 80px;
    position: absolute;
    top: 75%;
    right: 0%;
    transform: translate(-0%, -75%);
}

.body-container {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 26% 26% 5%;
}
.body-container-Klaw {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 30%;
}
.select-humanoid {
    height: 30vw;
    width: 25vw;
    position: relative;
    background-size: 80% 80%;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 1px solid red; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.select-buzzer {
    position: absolute;
    height: 20%;
    width: 50%;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
}
.select-leftEye {
    position: absolute;
    height: 3.2%;
    width: 4.4%;
    top: 15.8%;
    left: 46.7%;
    transform: translate(-50%, -50%);
}
.select-rightEye {
    position: absolute;
    height: 3.2%;
    width: 4.4%;
    top: 15.8%;
    left: 53.2%;
    transform: translate(-50%, -50%);
}
.select-buzzer {
    position: absolute;
    width: 80%;
    top: 15.8%;
    transform: translate(-50%, -50%);
}
.select-popupcard1 {
    height: 32vw;
    width: 28vw;
    position: relative;
    background-size: 100% 90%;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 1px solid red; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.select-inputs {
    position: absolute;
    font-size: 20px;
    /* font-family: 'Halcyon-SemiBold'; */
    top: 23%;
    left: 50%;
    transform: translate(-50%, -18%);
}
.select-popupcardKlaw {
    height: 25vw;
    width: 40vw;
    position: relative;
    background-size: 100% 90%;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 1px solid red; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sm-popupcard-button {
    height: 26%;
    width: 24%;
    cursor: pointer;
    user-select: none;
}
.sm-popupcard-button-klaw {
    width: 20%;
    cursor: pointer;
    user-select: none;
}
.sm-outputpopupcard-button {
    height: 26%;
    width: 22%;
    cursor: pointer;
    user-select: none;
}
.battery {
    position: absolute;
    top: 10%;
    left: 32%;
    transform: translate(-36%, -35%);
}

.ultrasonic {
    position: absolute;
    top: 10%;
    left: 69%;
    transform: translate(-62%, -35%);
    /* opacity: 0.6 !important; */
    /* pointer-events: none; */
}
.accelerometerX {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translate(-25%, -52%);
}
.accelerometerY {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -52%);
}
.accelerometerZ {
    position: absolute;
    top: 50%;
    left: 87%;
    transform: translate(-75%, -52%);
}
.gyroX {
    position: absolute;
    top: 90%;
    left: 12%;
    transform: translate(-25%, -70%);
}
.gyroY {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -70%);
}

.gyroZ {
    position: absolute;
    top: 90%;
    left: 87%;
    transform: translate(-75%, -70%);
}

.select-Outputs {
    position: absolute;
    font-size: 20px;
    /* font-family: 'Halcyon-SemiBold'; */
    top: 23%;
    left: 50%;
    transform: translate(-50%, -18%);
}
.leftRGB {
    position: absolute;
    top: 10%;
    left: 12%;
    transform: translate(-25%, -35%);
    /* opacity: 0.6 !important; */
    /* pointer-events: none; */
}

.rightRGB {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -35%);
    /* opacity: 0.6 !important; */
    /* pointer-events: none; */
}

.mp3 {
    position: absolute;
    top: 50%;
    left: 87%;
    transform: translate(-75%, -35%);
}
.mp3-hexapod {
    position: absolute;
    top: 10%;
    left: 87%;
    transform: translate(-75%, -35%);
    opacity: 0.6 !important;
    pointer-events: none;
}

.individualServo {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translate(-25%, -52%);
}
.individualServo_klaw {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-25%, -52%);
}
.actionGroup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -52%);
}

.headServo {
    position: absolute;
    top: 10%;
    left: 87%;
    transform: translate(-75%, -35%);
}

.M1 {
    position: absolute;
    top: 84%;
    left: 18%;
    transform: translate(-50%, -50%);
}
.M2 {
    position: absolute;
    top: 84%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pot1 {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translate(-25%, -52%);
}
.pot2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -52%);
}
.pot3 {
    position: absolute;
    top: 50%;
    left: 87%;
    transform: translate(-75%, -52%);
}
.pot4 {
    position: absolute;
    top: 90%;
    left: 12%;
    transform: translate(-25%, -70%);
}
.pot5 {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -70%);
}
.pot6 {
    position: absolute;
    top: 90%;
    left: 87%;
    transform: translate(-75%, -70%);
}
.switch1 {
    position: absolute;
    top: 10%;
    left: 32%;
    transform: translate(-36%, -35%);
}
.switch2 {
    position: absolute;
    top: 10%;
    left: 69%;
    transform: translate(-62%, -35%);
}
.SimpleServo {
    position: absolute;
    top: 84%;
    left: 82%;
    transform: translate(-50%, -50%);
}
.SimpleServo-hexapod {
    position: absolute;
    top: 50%;
    left: 82%;
    transform: translate(-50%, -50%);
}
.select-bottom {
    /* height: 10%; */
    width: 100%;
    position: relative;
    /* border: 1px solid black; */
}
.select-backbutton {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 69%;
    transform: translateY(-50%);
    z-index: 150;
    left: 2.5%;
    cursor: pointer;
}

.select-description {
    position: absolute;
    font-size: 18px;
    color: #707070;
    top: 43%;
    left: 50%;
    transform: translate(-50%, 0%);
    /* border: 1px solid red; */
}

.select-nextbutton {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 69%;
    right: 2.5%;
    transform: translateY(-50%);
    cursor: pointer;
}
.toast {
    min-width: 150px;
    /* margin-left: -125px; */
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    position: absolute;
    z-index: 5;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
}
.toast-active {
    display: block;
}
.toast-inactive {
    display: none;
}

/* hexapod */

.leftRGBHexapod {
    position: absolute;
    top: 50%;
    left: 12%;
    transform: translate(-25%, -52%);
    /* transform: translate(-25%, -35%); */
}

.rightRGBHexapod {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -52%);
    /* transform: translate(-50%, -35%); */
}
.individualServoHexapod {
    position: absolute;
    top: 50%;
    left: 87%;
    transform: translate(-75%, -52%);
}

.actionGroupHexapod {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -35%);
    /* transform: translate(-50%, -52%); */
}
