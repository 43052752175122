.create-Container {
    height: 100vh;
    width: 100vw;
    user-select: none;
}
.main-createNavBar {
    height: 10%;
    width: 100%;
    position: relative;
    /* border: 1px solid red; */
    background-color: #f0f7fa;
}

.select-backbutton {
    height: 82px;
    width: 82px;
    position: absolute;
    bottom: 2%;
    left: 5%;
    transform: translate((-2%, -5%));
}

.select-nextbutton {
    height: 82px;
    width: 82px;
    position: absolute;
    bottom: 2%;
    right: 5%;
    transform: translate((-5%, -2%));
}

.create-Select {
    height: 100%;
    width: 450px;
    position: absolute;
}

.navbar-createitem-select {
    color: #fff;
    font-size: 18px;
    font-family: halcyon-SemiBold !important;
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translate(-8%, -50%);
}

.navbar-createitem-center {
    color: #fff;
    font-size: 18px;
    font-family: halcyon-SemiBold;
    position: absolute;
    top: 50%;
    left: 22%;
    transform: translate(-20%, -50%);
}

.create-helpbutton {
    height: 73%;
    width: 50px;
    position: absolute;
    top: 45%;
    right: 6%;
    transform: translate(-6%, -45%);
}

.create-bluetooth {
    height: 60px;
    width: 80px;
    position: absolute;
    top: 75%;
    right: 0%;
    transform: translate(-0%, -75%);
}

.create-savebutton {
    height: 73%;
    width: 50px;
    position: absolute;
    top: 45%;
    right: 11%;
    transform: translate(-11%, -45%);
}

.create-body-container {
    height: 80%;
    width: 100%;
    /* border: 1px solid rebeccapurple; */
    display: grid;
    grid-template-columns: 20% 40% 40%;
}

.sc-toggle-button {
    height: 33px;
    width: 33px;
}

.create-humanoid {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 60% 80%;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 1px solid red; */
}

.create-hexapod {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 80% 80%;
    background-position: center;
    background-repeat: no-repeat;
    /* border: 1px solid red; */
}

.action-card {
    padding-top: 5%;
    margin-left: 10%;
    height: 110%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.disabledBtn,
.enabledBtn,
.addBtn {
    width: 90%;
    height: 4rem;

    margin: 1rem;
    border: 0;
    border-radius: 1rem;
    font-family: 'Halcyon_SemiBold' !important;
    z-index: 100;
    /* text-align: left; */
}

.disabledBtn {
    background: linear-gradient(to right, #9f9f9f, #e3e3e3);
    color: black;
}

.enabledBtn {
    background: linear-gradient(to right, #feba45, #f97e61);
    color: #ffffff;
}

.addBtn {
    /* position: absolute; */
    /* width: 16rem; */
    border: 2px dotted black;
    /* top: 30rem; */
    background: none;
}

.action-sidebar-scrollZone {
    height: 80%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.action-sidebar {
    height: 72vh;
    width: 100%;
    background: #ebf6fa;
}

.sc-btn-humanoid-one {
    position: absolute;
    bottom: 14%;
    right: 40%;
}

.sc-btn-humanoid-two {
    position: absolute;
    bottom: 23%;
    right: 40%;
}

.sc-btn-humanoid-three {
    position: absolute;
    bottom: 34%;
    right: 40%;
}
.sc-btn-humanoid-four {
    position: absolute;
    bottom: 46%;
    right: 40%;
}
.sc-btn-humanoid-five {
    position: absolute;
    bottom: 56%;
    right: 40%;
}

.sc-btn-humanoid-six {
    position: absolute;
    top: 38%;
    right: 24%;
    transform: translate(-38%, -24%);
}

.sc-btn-humanoid-seven {
    position: absolute;
    top: 28%;
    right: 28%;
}
.sc-btn-humanoid-eight {
    position: absolute;
    top: 23%;
    right: 35%;
}
.sc-btn-humanoid-nine {
    position: absolute;
    bottom: 14%;
    right: 53%;
}
.sc-btn-humanoid-ten {
    position: absolute;
    bottom: 23%;
    right: 53%;
}
.sc-btn-humanoid-eleven {
    position: absolute;
    bottom: 34%;
    right: 53%;
}
.sc-btn-humanoid-tweleve {
    position: absolute;
    bottom: 46%;
    right: 53%;
}

.sc-btn-humanoid-thirteen {
    position: absolute;
    bottom: 56%;
    right: 53%;
}

.sc-btn-humanoid-fourteen {
    position: absolute;
    top: 37%;
    left: 26%;
}

.sc-btn-humanoid-fifteen {
    position: absolute;
    top: 28%;
    left: 28%;
}

.sc-btn-humanoid-sixteen {
    position: absolute;
    top: 23%;
    left: 35%;
}
.sc-btn-H {
    position: absolute;
    top: 28%;
    left: 49%;
    transform: translate(-28%, -49%);
}

.sc-btn-klaw-one {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 35%;
    right: 17%;
}
.sc-btn-klaw-two {
    position: absolute;
    bottom: 45%;
    right: 16%;
}
.sc-btn-klaw-three {
    position: absolute;
    bottom: 57%;
    right: 23%;
}
.sc-btn-klaw-four {
    position: absolute;
    top: 23%;
    right: 20%;
}
.sc-btn-klaw-five {
    position: absolute;
    top: 26%;
    right: 14%;
}

.sc-btn-hexapod-one {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 59%;
    right: 43%;
}
.sc-btn-hexapod-two {
    position: absolute;
    top: 28%;
    right: 37%;
}
.sc-btn-hexapod-three {
    position: absolute;
    top: 21%;
    right: 32%;
}
.sc-btn-hexapod-four {
    position: absolute;
    bottom: 47%;
    right: 40%;
}
.sc-btn-hexapod-five {
    position: absolute;
    bottom: 47%;
    right: 32%;
}

.sc-btn-hexapod-six {
    position: absolute;
    bottom: 47%;
    right: 25%;
}

.sc-btn-hexapod-seven {
    position: absolute;
    bottom: 34%;
    right: 42%;
}
.sc-btn-hexapod-eight {
    position: absolute;
    bottom: 28%;
    right: 37%;
}
.sc-btn-hexapod-nine {
    position: absolute;
    bottom: 21%;
    right: 32%;
}
.sc-btn-hexapod-ten {
    position: absolute;
    bottom: 59%;
    right: 51%;
}
.sc-btn-hexapod-eleven {
    position: absolute;
    top: 28%;
    left: 39%;
}
.sc-btn-hexapod-tweleve {
    position: absolute;
    top: 21%;
    left: 34%;
}

.sc-btn-hexapod-thirteen {
    position: absolute;
    bottom: 47%;
    right: 54%;
}

.sc-btn-hexapod-fourteen {
    position: absolute;
    bottom: 47%;
    right: 61%;
}

.sc-btn-hexapod-fifteen {
    position: absolute;
    bottom: 47%;
    right: 68%;
}

.sc-btn-hexapod-sixteen {
    position: absolute;
    bottom: 34%;
    right: 51%;
}
.sc-btn-hexapod-seventeen {
    position: absolute;
    bottom: 28%;
    right: 56%;
}

.sc-btn-hexapod-eighteen {
    position: absolute;
    bottom: 21%;
    right: 61%;
}

.create-popupcard {
    height: 50%;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    /* border: 1px solid red; */
}

.create-popupcard2 {
    height: 100%;
    width: 100%;
    /* border: 1px solid red; */
    position: absolute;
    left: -11%;
    top: 38%;
    background-size: 85% 50%;
    background-repeat: no-repeat;
}

.create-rectangle {
    height: 35%;
    width: 60%;
    position: absolute;
    top: 50%;
    left: 58%;
    transform: translate(-50%, -50%);
    /* border: 1px solid red; */
}

.create-settime {
    height: 60px;
    width: 60px;
    /* border: 1px solid red; */
    position: absolute;
    top: 50%;
    left: 18%;
    transform: translate(-50%, -50%);
}

.create-detail-container {
    height: 40%;
    width: 55%;
    font-size: 18px;
    /* font-family: halcyon-SemiBold !important; */
    /* border: 1px solid red; */
    position: absolute;
    top: 50%;
    left: 58%;
    transform: translate(-50%, -50%);
    color: black;
}

.create-detail-title {
    position: absolute;
    top: 30%;
    left: 15%;
    transform: translate(-50%, -50%);
    color: black;
    font-family: halcyon-SemiBold !important;
}

.slider {
    -webkit-appearance: none;
    position: absolute;
    left: 32%;
    top: 25%;
    height: 10px;
    width: 60%;
    background: #ffcb6f;
    border-radius: 12px;
}

/* for removing silder button */

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;

    background-image: url('../../../Assets/images/Create\ Actions/sliderButton.png');
    background-repeat: no-repeat;
    background-size: 130%;

    background-position: 50% -10%;
}

.slider::after {
    content: '1000';
    color: black;
    font-size: 1.5rem;
    position: absolute;
    left: 80%;
    top: 450%;
}

.slider::before {
    content: '0';
    color: black;
    font-size: 1.5rem;
    position: absolute;
    left: 0%;
    top: 450%;
}

/* .slider-Button::-webkit-slider-thumb{
  height: 25px;
  width: 25px;
  
} */

.value {
    height: 38px;
    width: 35px;
    border: 1px solid #fff;
    border-radius: 256px;
    position: absolute;
    left: 7%;
    top: 50%;
    background-color: white;
    font-family: halcyon-SemiBold !important;

    /* border: 1px solid red; */
}

.value > p {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
}

.create-backbutton {
    height: 60px;
    width: 60px;
    position: absolute;
    bottom: 2%;
    left: 2%;
    transform: translate((-2%, -5%));
    cursor: pointer;
}

.create-addbutton {
    height: 82px;
    width: 82px;
    position: absolute;
    bottom: 12%;
    left: 5%;
    /* transform: translate((-5%, -2%)); */
    cursor: pointer;
}

.create-nextbutton {
    position: absolute;
    bottom: 2%;
    right: 3%;
    width: 60px;
    /* transform: scale(0.8); */
    cursor: pointer;
}
.create-upload {
    position: absolute;
    bottom: 3.5% !important;
    right: 15%;
    width: 53px;
    /* transform: scale(0.8); */
    cursor: pointer;
    rotate: 180deg;
}

.create-playgroup {
    position: absolute;
    bottom: 2%;
    right: 9%;
    width: 60px;
    cursor: pointer;
}

.create-servoAngle {
    height: 27%;
    width: 10vw;
    /* border: 1px solid red; */
    border-radius: 26px;
    position: absolute;
    top: 50%;
    left: 13%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    font-family: halcyon-SemiBold !important;
    font-size: 22px;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.create-setTime {
    height: 15%;
    width: 20%;
    background-color: aqua;
}
.tooltip {
    /* position: absolute; */
    /* background-color: #f0f7fa; */
    background-color: #ffffff;
    box-shadow: inset 0 0 0.5rem #e0ebf7;
    border: 1px solid #b6b6db;
    text-align: center;
    padding: 6px 7px;
    font-weight: lighter;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* visibility: hidden; */
    /* top: 15vh; */
    /* left: 94%; */
    transform: translate(-50%, -50%);
}

.savbtn,
.readbtn,
.groupPlaydbtn {
    visibility: hidden;
}
.create-nextbutton:hover + .savbtn,
.create-nextbutton:hover ~ .savbtn {
    visibility: visible;
}
.create-upload:hover + .readbtn,
.create-upload:hover ~ .readbtn {
    visibility: visible;
}
.create-playgroup:hover + .groupPlaydbtn,
.create-playgroup:hover ~ .groupPlaydbtn {
    visibility: visible;
}
.create-playgroup:hover + .groupPlaydbtn,
.create-playgroup:hover ~ .groupPlaydbtn {
    visibility: visible;
}

@media (min-width: 768px) and (max-width: 1224px) {
    .create-Select {
        width: 300px;
    }
    .create-helpbutton {
        height: 73%;
        width: 40px;
        margin-right: 40px;
    }

    .create-bluetooth {
        height: 50px;
        width: 80px;
        margin-top: 5px;
    }
    .create-savebutton {
        height: 73%;
        width: 40px;
        margin-right: 60px;
    }
}

@media (max-width: 768px) {
    .create-Select {
        width: 200px;
    }
    .create-helpbutton {
        height: 73%;
        width: 40px;
        margin-right: 40px;
    }

    .create-bluetooth {
        height: 50px;
        width: 80px;
        margin-top: 5px;
    }
    .create-savebutton {
        height: 73%;
        width: 40px;
        margin-right: 60px;
    }
}

@media (max-width: 900px) {
    .sc-toggle-button {
        height: 22px;
        width: 22px;
    }
    .create-backbutton,
    .create-nextbutton {
        height: 62px;
        width: 62px;
    }
}

@media (max-width: 768px) {
    .create-popupcard {
        height: 35%;
        width: 90%;
    }

    .create-servoAngle {
        height: 50%;
        width: 55px;
        font-size: 16px;
    }

    .create-detail-container {
        font-size: 12px;
    }

    .value {
        height: 20px;
        width: 30px;
    }

    .slider::after {
        font-size: 12px;
    }

    .slider::before {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 1224px) {
    .slider::after {
        font-size: 20px;
    }

    .slider::before {
        font-size: 20px;
    }

    .value {
        height: 25px;
        width: 50px;
        position: absolute;
        left: 0%;
        top: 50%;
    }
    .create-servoAngle {
        height: 50%;
        width: 55px;
        font-size: 16px;
    }
}
