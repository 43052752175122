.pwm {
    margin-right: 5px;
    margin-left: 10px;
}

.pwm,
.dac {
    border-radius: 10px;
    outline: none;
    border: none;
    position: relative;
    left: 7px;
    padding: 3px 5px 2px 5px;
    text-align: center;
    vertical-align: middle;
    color: white;
}
.spanspace {
    display: block;
    margin-bottom: 5px;
}

.lower-digital {
    label {
        position: relative;
        top: 3px;
    }
}

.upper-digital {
    position: relative;
    left: 2px;
    top: 2px;

    label {
        position: relative;
        left: -1px;
    }
}

.right-uart {
    position: relative;
    top: 1px;
    left: 12px;
    color: #311b92;
    box-sizing: border-box;
    padding: 5px;
    font-weight: 600;
}

.true {
    background-color: green;
}

.false {
    background-color: #d3d2d2;
}

.trueinput-text {
    color: #d3d2d2;
    position: relative;
    top: 1px;
}

.falseinput-text {
    position: relative;
    top: 1px;
}

@media (max-width: 766px) {
    .right-uart {
        left: 8px;
    }
}
