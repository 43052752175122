.container {
    display: flex;
    gap: 9% !important;
    /* height: 100px; */
    margin-bottom: 2rem;
    overflow: visible;
}

.slidercontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.slider,
.enabledSlider {
    display: flex;
    width: 56%;
    padding: 1rem;
    color: black;
    position: relative;
    transform: translateX(-5%);
}

.enabledSlider {
    background: #dff5f2;
}

.disabledBtn,
.enabledBtn {
    height: 100%;
    padding: 1rem;
    border: 0;
    /* border-radius: 1rem; */

    font-family: 'Halcyon_SemiBold' !important;
}

.disabledBtn {
    background: linear-gradient(to right, #9f9f9f, #e3e3e3);
    color: black;
}

.enabledBtn {
    background: linear-gradient(to right, #feba45, #f97e61);
    color: #ffffff;
}

.servoBtnGrp {
    width: 20.6%;
    /* height: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    color: black;
}

.servoBtnGrp button:first-child {
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.servoBtnGrp button:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}
.servoBtnGrp_1 button:last-child {
    height: 40px !important;
}
.liveServoBtnOn,
.liveServoBtnOff,
.disabledLiveServoBtn {
    padding: 0.25rem 0rem;
    border: 1px solid black;
}

.liveServoBtnOff {
    background: white;
}

.disabledLiveServoBtn {
    opacity: 0.4;
    pointer-events: none;
}

.liveServoBtnOn {
    color: white;
    background: linear-gradient(to right, #feba45, #f97e61);
    border: none;
}

.bidBtnActive {
    background: #09b8a7;
    color: #fff;
}
.bidBtnActive:hover {
    background: rgb(0, 0, 0, 0.9);
    color: #fff;
    cursor: pointer;
}
.bidBtn:hover {
    background: rgb(0, 0, 0, 0.7);
    color: #fff;
    cursor: pointer;
}
