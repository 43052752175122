.btn-micro {
    background: transparent linear-gradient(150deg, #ff6838 0%, #ffec7e 100%) 0%
        0% no-repeat padding-box;
    box-shadow: 0px 7px 5px #ba50ff8c;
    width: 4vw;
    height: 4vw;
    padding: 2vh;
    border-radius: 5vw;
    position: absolute;
    top: 25vw;
    left: 48vw;
    background-size: cover;
}
.usb {
    position: absolute;
    top: -0.2vw;
    left: 63vw;

    /* background-image: url('/imagesplay/usb connected.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    /* width: 6vh;
  height: 3.23vw; */
}

/* .microphone{
  background-image: url('/imagesplay/play_speak_button_bg.png');
  background-position:cover;
  background-repeat:no-repeat;
  width: 5vw;
  height: 5vw;
  border-radius: 5vw;
  position: absolute;
  top:25vw;
  left:48vw
} */

.human-speak {
    /* background-image: url('/imagesplay/play_speak_illus_voice.png'); */
    /* background-size:cover;
  background-repeat:no-repeat;
  width: 40vw;
  height: 30vh;
  position: absolute;
  top: 8vw;
  left: 28vw; */
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.human-speak_Ace1 {
    position: absolute;
    height: 40vh;
    width: 100vh;
    top: 8vw;
    left: 28vw;
}
.suggest {
    position: absolute;
    height: 12vh;
    width: 39vh;
    top: 22vw;
    left: 43vw;
}
.human-speak_Ace {
    position: relative;
    /* background-image: url('/imagesplay/illus_voice.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    /* top: 8vw;
  left: 28vw; */
}

.microBack {
    width: 11vw;
    height: 11vw;
    position: relative;
    top: 71vh;
    left: 47vw;
}
.microBack_effect:active {
    /* background-image: url('/imagesplay/play_speak_button_bg.png'); */
    background-size: contain;
    background-repeat: no-repeat;
}

.microphoneBtn {
    /* background-image: url('/imagesplay/play_speak_button_talk.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 6vw;
    height: 6vw;
    position: absolute;
    top: 2.5vw;
    left: 2.5vw;
}

.speechOP {
    position: absolute;
    top: 80vh;
    left: 50vw;
    color: #311b92;
    font-size: 2vh;
    text-align: center;
}
@media only screen and (max-device-width: 768px) {
    .human-speak {
        position: absolute;
        top: 5%;
        left: -14%;
    }
    .microBack {
        top: 47vw;
    }
    .suggest {
        top: 34vw;
    }
}
