.MainContainerInputs {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden;
}

.CenterImg {
    display: flex;
    width: 25%;
    position: relative;
    left: 5rem;
    top: -2vh;
}

.Pcv1CenterImg {
    display: flex;
    width: 25%;
    position: relative;
    left: 5rem;
    top: -4vh;
}

.ImgPcv0 {
    width: 85%;
    z-index: 110;
    top: 7%;
    position: relative;
}

.ImgPcv1 {
    width: 87%;
    z-index: 110;
    top: 7%;
    position: relative;
}

.Inputs_ports_Container {
    display: flex;
    position: relative;
    background-color: #eee;
    width: 100%;
    left: 10%;
    height: 90vh;
    margin-top: -10vh;
    overflow-y: auto;
}

/* PCV0.1 */
.imgStyleEyeL {
    width: 10%;
    height: 24px;
    position: absolute;
    top: 28%;
    left: 25.5%;
    z-index: 999;
}

.imgStyleEyeR {
    width: 10%;
    height: 24px;
    position: absolute;
    top: 28%;
    left: 51%;
    z-index: 999;
}

.imgStyleTeeth1 {
    width: 2.7%;
    height: 16px;
    position: absolute;
    top: 56%;
    left: 33%;
    z-index: 999;
}

.imgStyleTeeth2 {
    width: 2.7%;
    height: 16px;
    position: absolute;
    top: 58%;
    left: 36%;
    z-index: 999;
}

.imgStyleTeeth3 {
    width: 2.7%;
    height: 16px;
    position: absolute;
    top: 58%;
    left: 47%;
    z-index: 999;
}

.imgStyleTeeth4 {
    width: 2.7%;
    height: 16px;
    position: absolute;
    top: 56.6%;
    left: 50%;
    z-index: 999;
}

.imgStyle4in1 {
    width: 6.5%;
    height: 14px;
    position: absolute;
    top: 58.5%;
    left: 40%;
    z-index: 999;
}

.imgStyleMic {
    width: 80px;
    /* height: 80px; */
    position: absolute;
    top: -33.5%;
    left: 43%;
    -webkit-transform: translate(-48%, -5%);
    transform: translate(-48%, -5%);
    z-index: -1;
    max-height: 38% !important;
}

.imgStyleBuzzer {
    width: 100%;
    height: 40%;
    position: absolute;
    top: -15%;
    left: -5.5%;
    -webkit-transform: translate(-1.5%);
    transform: translate(-1.5%);
    z-index: 3;
    z-index: 999;
}

.imgStyleTouchpads1,
.imgStyleTouchpads2,
.imgStyleTouchpads3 {
    height: 12%;
    position: absolute;
    bottom: 3%;
    left: 15.3%;
    -webkit-transform: translateX(-23.5%);
    transform: translateX(-23.5%);
    z-index: 999;
}
.imgStyleTouchpads1,
.imgStyleTouchpads2,
.imgStyleTouchpads3 {
    height: 12%;
    position: absolute;
    bottom: 3%;
    left: 15.3%;
    -webkit-transform: translateX(-23.5%);
    transform: translateX(-23.5%);
    z-index: 999;
}

.imgStyleTouchpads1,
.imgStyleTouchpads2,
.imgStyleTouchpads3 {
    height: 12%;
    position: absolute;
    bottom: 3%;
    left: 13%;
    -webkit-transform: translateX(-23.5%);
    transform: translateX(-23.5%);
    z-index: 999;
}

.imgStyleTouchpads1 {
    -webkit-transform: translateX(-50.8%);
    transform: translateX(-50.8%);
    z-index: 999;
}

.imgStyleTouchpads2 {
    left: 27.2%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 999;
}

.imgStyleTouchpads3 {
    left: 45%;
    -webkit-transform: translateX(-90%);
    transform: translateX(-90%);
    z-index: 999;
}

/* end */

/* PCV1.0 */

.isPCv1Teeth1 {
    position: absolute;
    width: 1.7%;
    height: 10%;
    left: 37%;
    top: 55.8%;
    z-index: 1000;
}

.isPCv1Teeth2 {
    position: absolute;
    width: 1.7%;
    height: 10%;
    left: 39.5%;
    top: 58%;
    z-index: 1000;
}

.isPCv1Teeth3 {
    position: absolute;
    width: 1.7%;
    height: 10%;
    left: 45.8%;
    top: 58%;
    z-index: 1000;
}

.isPCv1Teeth4 {
    position: absolute;
    width: 1.7%;
    height: 10%;
    left: 48%;
    top: 56%;
    z-index: 1000;
}

.isPCv1Teeth5 {
    position: absolute;
    width: 3%;
    left: 42%;
    top: 59.5%;
    z-index: 1000;
}

.isPCv1Mic {
    position: absolute;
    width: 8%;
    left: 39.5%;
    top: -17%;
    z-index: 1000;
}

.isPCv1Buzzer {
    position: absolute;
    width: 80%;
    left: 3%;
    top: -3%;
    z-index: 1000;
}

.isPCv1TouchZero {
    position: absolute;
    bottom: 6%;
    left: 10%;
    width: 16%;
    height: 15%;
    border-radius: 15% 26% 1% 13%;
    z-index: 1000;
}

.isPCv1TouchOne {
    position: absolute;
    bottom: 6%;
    left: 27%;
    width: 16%;
    height: 15%;
    border-radius: 15% 26% 1% 13%;

    z-index: 1000;
}

.isPCv1TouchTwo {
    position: absolute;
    bottom: 6%;
    left: 44%;
    width: 16%;
    height: 15%;
    border-radius: 15% 26% 1% 13%;
    z-index: 1000;
}

.isPCv1TouchThree {
    position: absolute;
    bottom: 6%;
    left: 61%;
    width: 16%;
    height: 15%;
    border-radius: 15% 26% 1% 13%;
    z-index: 1000;
}

.isPCv1LeftEye {
    position: absolute;
    width: 3.25%;
    top: 38.5%;
    z-index: 1000;
}

.isPCv1RightEye {
    position: absolute;
    width: 3.25%;
    top: 42.5%;
    z-index: 1000;
}
/* end */

.toast-msg {
    z-index: 10000;
    /* height: 60px; */
    width: 18vw;
    position: absolute;
    top: 93%;
    right: 32%;
    transform: translate(-50%, -50%);
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    border-radius: 25px;
    background-color: #d2d2d2;
}

.toast-msg-active {
    display: block;
}
.toast-msg-inactive {
    display: none;
}
