.trainingDeviceSidebar {
    /* margin-top: 5%; */
    width: 20%;
    background-color: rgb(241, 241, 241);
    /* border: red solid 1px; */
}
.sidebarDeviceNameDiv {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30%;
}
.sidebarDeviceName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    /* border: green solid 1px; */
}
.sidebarDeviceName:hover {
    border-radius: 25px;
    background: rgb(161, 160, 160);
}
.choseTrainingDeviceBody {
    /* border: 1px solid red; */
    width: 80%;
    height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 5%;
    margin-left: 5%; */
}
.detectionModeDiv {
    display: flex;
    align-items: center;
    gap: 40px;
}
.detectionModeEachContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.detectionModeImg {
    width: 10vw;
    cursor: pointer;
}
.detectionModeEachContent p {
    font-size: 1.5rem;
}
.detectionModeTitel {
    cursor: pointer;
}
