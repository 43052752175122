.actionCard-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actionCard-container > img {
    height: 80px;
    width: 80px;
}

.actionCard-container > p {
    position: relative;
    bottom: 15px;
}

.actionCard-title {
    width: 50%;
    font-size: 12px;
    color: #707070;
    font-family: halcyon-Medium !important;
    margin-top: 0.5rem;
    text-align: center;
}

.actionCard-img {
    cursor: pointer;
}
@media screen and (max-width: 768px) {
   
    .actionCard-container > img {
        height: 60px;
        width: 60px;
    }
}
@media screen and (max-width: 1400px) {
    .actionCard-title {
        width: 70%;
    }
}
