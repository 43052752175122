.joystick-image {
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
    position: relative;
}

.up-button {
    position: absolute;
    width: 25%;
    left: 51%;
    top: 8%;
    transform: translate(-55%, -0%);
    /* border: 1px solid black; */
    cursor: pointer;
}

.down-button {
    position: absolute;
    width: 25%;
    left: 51%;
    bottom: 8%;
    transform: translate(-55%, -0%);
    cursor: pointer;
}

.right-button {
    position: absolute;
    width: 25%;
    right: 13%;
    top: 50%;
    transform: translate(-0%, -50%);
    cursor: pointer;
}

.left-button {
    position: absolute;
    width: 25%;
    left: 15%;
    top: 50%;
    transform: translate(-10%, -50%);
    cursor: pointer;
}
.mid-button {
    position: absolute;
    width: 15%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
