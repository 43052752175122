.container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Halcyon_SemiBold' !important;
    background: rgba(255, 255, 255, 0.7);
    z-index: 95000;
}

.closeBtn {
    width: 2.5rem;
    top: 19vh;
    left: 72vw;
    position: absolute;
    cursor: pointer;
}

.box {
    display: flex;
    justify-content: center;
    width: 50vw;
    height: 60vh;
    padding: 1rem 2rem;
    background: linear-gradient(to right, #f0eded, white);
    border-radius: 4rem;
    border: 1px solid white;
    box-shadow: 0.5rem 0.5rem 2rem #d9d5d4;
    flex-wrap: wrap;
    overflow: auto;
    gap: 4vh;
}

.box div {
    flex: 0 0 20%;
    margin: 5px;
    flex-shrink: 1;
}

.containerImage {
    position: relative;
    text-align: center;
    margin: 5px;
}

.circularImage {
    border-radius: 50%;
    height: 6vh;
    cursor: pointer;
}

.textContainer {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    cursor: pointer;
}

.centeredText {
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: rgb(155, 153, 153);
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes loader {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}
