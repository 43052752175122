.bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(256, 255, 255, 0.5);
    z-index: 2000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.panel {
    text-align: center;
    height: 65vh;
    width: 60vw;
    border: 2px solid white;
    border-radius: 4rem;
    background-image: linear-gradient(to right, #f2f2f2, #fff);
    box-shadow: 20px 20px 100px #d3d1d1;

    display: flex;
    justify-content: center;
    align-items: center;

    transition-property: all 2s;
    transition-duration: 2s;
}

.closeButton {
    position: absolute;
    top: 17vh;
    left: 77vw;
    height: 60px;
    width: 60px;
    background-image: url('../../../Assets/img/close.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;

    transition-property: all 2s;
    transition-duration: 2s;
}
