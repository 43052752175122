.container {
    height: 20%;
    width: 100%;
    display: grid;
    grid-template-columns: 7% 13% 66% 7% 7%;
    grid-template-rows: 100%;
}
.importBtn {
    position: absolute;
    top: 30px;
    right: 175px;
    height: 2.75rem;
    width: 2.75rem;

    border: solid 2px #2f2e65;
    border-radius: 60px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}
.connected_back_btn{
    height: auto;
    width: 4%;
    position: absolute;
    top: 4%;
    left: 2%;
    z-index: 10000;
    opacity: 0.3;
}
.back_btn {
    height: auto;
    width: 4%;
    position: absolute;
    top: 4%;
    left: 2%;
    cursor: pointer;
    z-index: 10000;
}
.title {
    height: auto;
    width: 100%;
    font-size: 25px;
    position: absolute;
    top: 5.5%;
    left: 8%;
    font-family: 'Halcyon_SemiBold' !important;
}

.help_btn {
    height: auto;
    width: 4%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 6%;
    z-index: 10000;
}
.help_slider {
    width: 100%;
    /* height: 100vh; */
    position: absolute;
    width: 100%;
    top: -1%;
    left: -20%;
    transform: translate(10px, 10px);
    z-index: 100000;
    background: #fff;
    /* border: 1px solid red; */
}
.bluetooth_Btn {
    height: auto;
    width: 6%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 0.1%;
}
.cross {
    position: absolute;
    top: 17%;
    transform: translate(-50%, -50%);
    left: 79%;
    width: 2.5%;
    z-index: 100000;
    cursor: pointer;
}

.userAccount_Btn {
    height: auto;
    width: 3.2%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 9.5%;
    border-radius: 60%;
}

.userAccount_settingBtn {
    display: block;
    height: auto;
    width: 4%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 9.5%;
    border-radius: 60%;
}

.help_Body .tooltiptext {
    position: absolute;
    top: 11.7%;
    left: 85%;
    background-color: #3b4441;
    text-align: center;
    padding: 6px 7px;
    font-weight: lighter;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    visibility: hidden;
}

.help_Body:hover .tooltiptext {
    visibility: visible;
}

.tooltiptext > p {
    margin-bottom: -2.5% !important;
    color: #a4a9ac;
    font-size: 1.5vh;
}

@media (max-width: 1080px) {
    .cross {
        top: 29%;
        left: 74%;
    }
}

.popupCard {
    position: absolute;
    top: 11.5%;
    right: 19px;
    width: 330px;
    background-color: #f2f6fc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 34px;
    height: 300px;
    z-index: 999;
}

.popupCard p {
    margin: 0;
    padding: 5px 0;
}

.popupCard button {
    background-color: #f2f6fc;
    color: #0a0909de;
    border: none;
    width: 280px;
    height: 60px;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Halcyon_SemiBold' !important;
    font-size: 19px;
}

.popupCard button:hover {
    background-color: #dce7f7;
}
