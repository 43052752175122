.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.Connect_Header {
    height: 7vh;
    position: relative;
    /* top: 4%; */
    width: 98%;
    display: grid;
    place-items: center;
    margin: 1.5% 0% 0% 1.5%;
    /* border: 1px solid red; */
    grid-template-columns: 5% 20% 61% 7% 7%;
    grid-template-rows: 100%;
    /* border:2px solid red; */
}
.text {
    font-size: 3vh;
    position: absolute;
    top: 3%;
    left: 8%;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transform: translateY(25vh); */
    /* color: white; */
}
.back_btn {
    text-align: center;
    height: 8vh;
    /* changes start*/
    /* width: 4%; */
    /* changes start */
    position: absolute;
    cursor: pointer;
    top: 4%;
    left: 2%;
    /* border:2px solid red; */
    /* margin-top: 10%; */
    /* margin-left: 10%; */
}
.helpBtn {
    height: 8vh;

    position: absolute;
    cursor: pointer;
    top: 4%;
    /* border:2px solid red; */
    /* width: 4%; */
    right: 4%;
}
.Cross {
    position: absolute;
    top: 17%;
    transform: translate(-50%, -50%);
    left: 79%;
    width: 2.5%;
    z-index: 100000;
    cursor: pointer;
}
.instr {
    position: absolute;
    top: 23%;
    right: 3.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 9%; */
    width: 43%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.instr_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.instr3 {
    position: absolute;
    top: 76%;
    right: 1%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fafafa;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 12%; */
    width: 38%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.instr2_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.instr2 {
    position: absolute;
    top: 40%;
    right: 3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 9%; */
    width: 45%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.instr3_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.web_button {
    position: absolute;
    width: 4%;
    right: 4%;
    top: 5%;
}
.web_button img {
    width: 100%;
}

.searchInput {
    background-color: transparent;
    position: relative;
    width: 15vw;
    height: 6vh;
    top: 4%;
    left: 65vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 2px solid rgb(84, 83, 83);
}
.selectedSuggestion {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 1.2rem;
}
.selectedSuggestion p {
    text-align: center;
    width: 100%;
    padding: 0.3rem 2rem;
    margin-bottom: 0;
    border-radius: 10px;
    height: fit-content;
    background-color: rgb(238, 238, 238);
}
.searchInput input {
    width: 85%;
    height: 100%;
    font-size: 1.1rem;
    padding-left: 5px;
    border: 0;
    /* padding: 1rem; */
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
}
.searchInput input:focus {
    outline: none;
}
.searchIcon {
    width: 7%;
    /* position: absolute; */
    /* right: 8%; */
    /* top: 25%; */
    /* z-index: 100; */
}

.binIcon {
    width: 5%;
    /* position: absolute; */
    /* right: 8%; */
    /* top: 25%; */
    z-index: 100;
    cursor: pointer;
}
.searchIcon img,
.binIcon img {
    width: 100%;
}
.Connect_Body {
    width: 100%;
    /* height: 45%; */
    padding: 1rem 4rem;
    display: flex;
    gap: 4rem;
    align-items: start;
    position: relative;
    top: 1rem;
    /* border: 2px solid red; */
}
.header {
    color: #8c8cf2;
    font-size: 1.4rem;
    position: relative;
    left: 0.5rem;
    position: relative;
    /* top: 5rem; */
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgb(218, 214, 214);
}

.tabs {
    display: flex;
    flex-direction: column;
    min-width: 17%;
    gap: 1.5rem;
    width: 20%;
    /* border: 2px solid red; */
}

.tab {
    width: 100%;
    padding: 15px 1.5rem;
    cursor: pointer;
    /* background: rgb(241, 241, 241);
    background: linear-gradient(
        90deg,
        rgba(241, 241, 241, 1) 0%,
        rgba(252, 252, 252, 1) 100%
    ); */
    /* border-bottom: 1px solid #ccc; */
    /* border-radius: 25px; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    transition: background 0.3s ease;
    /* border: 2px solid red; */
}

.tab:hover {
    border-radius: 25px;
    background: rgb(241, 241, 241);
}

.tab.active {
    background: rgb(241, 241, 241);
    background: linear-gradient(
        90deg,
        rgb(234, 232, 232) 0%,
        rgba(252, 252, 252, 1) 100%
    );
    border-radius: 25px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 18px 0px 36px -28px inset; */
    /* background: #ddd; */
}

.content {
    flex: 1;
    padding: 20px;
}
.title {
    font-size: 1.2rem;
    width: 80%;
    /* text-align: center; */
    white-space: nowrap;
    /* border: 2px solid red; */
    display: flex !important;
    align-items: center !important;
    justify-content: start !important;
    margin-bottom: 0 !important;
}
.tab.active .title {
    padding-left: 15px;
}
.tab .arrow {
    width: 8%;
    display: flex;
    align-items: start;
    justify-content: center;
    /* border: 2px solid red; */
}
.arrow img {
    width: 100%;
}
.tabContent {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 3rem;
    width: 100%;
    /* border: 2px solid red; */
}
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 20%;
    padding: 0.15rem;
    border-radius: 45px;
    background: rgb(241, 241, 241);
    cursor: pointer;
    background: linear-gradient(
        90deg,
        rgb(240, 239, 239) 0%,
        rgba(252, 252, 252, 1) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.cardImg {
    width: 100%;
}
.cardImg img {
    width: 100%;
}
.card:hover .cardImg {
    opacity: 0.7;
}
.cardName {
    white-space: nowrap;
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* footer */
.Connect_Footer {
    /* position: absolute; */
    position: fixed;
    bottom: 2rem;
    right: 8%;
    /* display: flex;
    align-items: center; */
    /* gap: 2rem; */
    /* flex-direction: column; */
}
.navigate {
    position: relative;
    /* right: 1.15rem; */
    width: 13%;
    height: 13%;
    display: flex;
    padding: 13px;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(52, 51, 51);
    border-radius: 50%;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    cursor: pointer;
}
.auto {
    /* border: 2px solid red; */
    position: relative;
    width: 20%;
    /* right: 5%; */
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.navigate img,
.auto img {
    width: 100%;
}

.Nodevice {
    font-size: 1.3rem;
    opacity: 0.7;
    font-family: 'Halcyon_Regular' !important;
}

.suggestions {
    width: 100%;
    z-index: 1000;
    position: absolute;
    top: 6.5vh;
    list-style: none;
    padding: 1rem 1.5rem;
    border-radius: 20px;
    background: rgb(241, 241, 241);
    cursor: pointer;
    background: linear-gradient(
        90deg,
        rgb(240, 239, 239) 0%,
        rgba(252, 252, 252, 1) 100%
    );
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.suggestions li {
    padding: 0.5rem 0 0 0;
    cursor: pointer;
    opacity: 0.7;
    white-space: nowrap;
    display: flex;
    align-items: center;
    /* max-width: 90%; */
}
.suggestions li:hover {
    opacity: 1;
}
.suggestion_title {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
}
.suggestion_name {
    font-size: 14px;
    margin-left: 7%;
    /* width: 50%; */
}

.language_select {
    width: 200px;
    height: 45px;
    padding: 5px;
    border-radius: 10px;
}
@media screen and (max-width: 1350px) {
    .tabs {
        width: 25%;
    }
    .title {
        font-size: 1rem;
    }
    .searchInput {
        right: 15%;
    }
    .searchIcon {
        width: 11%;
        /* position: absolute;
        top:18%; */
    }
    .card {
        width: 26%;
    }
    .Connect_Footer {
        right: -20%;
    }
    .auto {
        width: 18%;
    }
    .navigate {
        width: 11%;
        height: 11%;
    }
}
@media screen and (max-width: 768px) {
    /* .tabContent{
        flex-direction: column;
    } */
    .searchIcon {
        width: 16%;
    }
    .card {
        width: 40%;
    }
}
