.flex {
    width: 58%;
    padding: 12px;
    display: flex;
    justify-content: space-around;
    transform: scale(1.5);
    height: 100%;
}

.input_output_right {
    border: 1px solid #311b92;
    padding: 0rem 0.64rem 0.8rem 5.5rem;
    margin-left: 4%;
    max-height: 169px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    background-color: #311b92;
}

.left-upper,
.right-upper,
.left-lower,
.right-lower {
    display: flex;
    flex-direction: column;
}

.left-upper,
.right-upper,
.right-lower,
.left-lower {
    margin-bottom: 10px;
    border: 1px solid #311b92;
    background-color: white;
    font-weight: 600;
    color: #311b92;
}

.right-upper {
    width: 100%;
    label {
        padding: 0px;
    }
}

.right-lower {
    width: 100%;
}

.sp {
    position: relative;
    right: 110px;
    top: 100px;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
}

.upper-label-input > span,
.lower-label-input > span {
    position: relative;
    top: 3px;
}

.lower {
    position: relative;
    left: -1px;
}

.lower-label-input > span {
    left: 5px;
    top: 2px;
}

.input-left > .ls {
    position: relative;
    left: 10px;
}

.input-bd > .ls {
    position: relative;
    left: 8px;
}

.circle {
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 25px;
    background-color: #e7e6e7;
    position: relative;
    top: 5px;
    &:checked {
        background-color: green;
    }

    &:focus {
        outline: none !important;
    }
}

.spicircle {
    left: -8px;
}

.right-lower,
.right-upper {
    position: relative;
    top: -8px;
    padding: 15px 10px 13px 11px;
    text-align: center;
    border-radius: 15px;
}

.left-lower {
    padding: 11px;
    padding-left: 0;
    padding-right: 0;
    border-radius: 20px;
    width: 120px;
    text-align: center;
}

.false-span {
    color: #d3d2d2;
}

.left-upper {
    padding: 10px 0;
    border-radius: 20px;
    width: 120px;
    text-align: center;
}

.input-bd {
    position: relative;
    right: 2px;
}

.input-left {
    position: relative;
    right: 6px;
}
.input-D2 {
    position: relative;
    right: 7px;
    span {
        position: relative;
        left: 6px;
    }
}

.upper-label-input > span {
    position: relative;
    right: 3px;
}

.input-bd > span {
    position: relative;
    left: 0;
}

.falseinput {
    color: #d3d2d2;
}

.falsetext-circle {
    color: #d3d2d2;
}

.I2c {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    font-size: 0.8rem;
}

.i2cinput {
    margin-left: 7px;
}

.input_output_left {
    transform: scale(1.1);
    position: relative;
    top: 8px;
}

.uart {
    position: relative;
    top: -5px;
}

.activatedUart {
    color: white;
    border: 1px solid #311b92;
    background-color: #311b92;
    padding: 5px;
    min-width: 100px;
    min-height: 80px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        text-align: center;
    }
}

.activatedSp1 {
    color: white;
    background-color: #311b92;
    margin-left: 10px;
    padding: 8px;
    min-width: 100px;
    min-height: 157px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
        text-align: center;
    }
}

.exLower {
    background-color: white;
    color: #311b92;
    padding: 1rem 0.6rem 1.2rem 0.7rem;

    .arrangecircleI2c {
        margin-left: 42%;
    }
}

@media (max-width: 768px) {
    .input_output_right {
        padding: 0rem 0.65rem 0.8rem 5.5rem;
    }
}

@media (max-width: 724px) {
    .input_output_left {
        position: relative;
        bottom: 5px;
    }
    .flex {
        bottom: 0;
    }
}

@media (max-width: 575px) {
    .flex {
        transform: scale(1.2);
    }

    .sp {
        right: 90px;
    }
}

@media (max-width: 555px) {
    .right-lower,
    .right-upper {
        padding: 14px 12px;
    }
    .input_output_right {
        padding: 0rem 0.75rem 0.8rem 5.5rem;
    }
    .exLower {
        padding: 1rem 0.8rem 1.2rem 0.7rem;
    }
}

@media (max-width: 400px) {
    .flex {
        transform: scale(1);
        width: 65%;
    }
    .input_output_left {
        margin-right: 5px;
    }
    .input_output_right {
        margin-left: 5px;
    }
}

@media (max-width: 375px) {
    @media (max-height: 812px) {
        .flex {
            position: relative;
            bottom: 80px;
        }
    }
}

@media (min-width: 1024px) {
    @media (min-height: 1366px) {
        .flex {
            position: relative;
            top: 150px;
            transform: scale(1.8);
        }
    }
}

@media (max-width: 321px) {
    .flex {
        margin-left: 2.5%;
    }
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;

        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}
.popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    background-color: white;
    color: #ed5d56;
    height: 35%;
    text-align: center;
    width: auto;
    border-radius: 20px;
    border: 1px solid red;
    display: flex;
    justify-content: center;

    button {
        padding: 5px;
        border-radius: 7px;
        background-color: #f3743f;
        outline: none;
        width: 50px;
        position: relative;
        top: 30px;
        border: none;
        margin: 10px;
        color: white;
    }
}
