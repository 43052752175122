.LeftButton {
    margin-top: 5%;
    height: 40px;
    padding: 5px;
    margin-left: 2.5%;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 25px;
    outline: none;
    border: none;
    background: transparent url('../../Assets/img/button 48x48 - fill2.png') 0%
        0% no-repeat padding-box;
    color: white;
    font-size: 1.2rem;
    @media (max-width: 644px) {
        right: 70%;
        padding: 3px;
        padding-right: 30px;
        padding-left: 30px;
    }
    &:hover {
        opacity: 0.9;
    }
}

.RightButton {
    margin-top: 4%;
    height: 54px;
    margin-right: 1%;
    padding: 5px;
    padding-right: 40px;
    padding-left: 40px;
    outline: none;
    border: none;
    border-radius: 25px;
    background: transparent url('../../Assets/img/button 48x48 - fill.png') 0%
        0% no-repeat padding-box;
    color: white;
    font-size: 1.2rem;
    @media (max-width: 644px) {
        left: 70%;
        padding: 3px;
        padding-right: 30px;
        padding-left: 30px;
    }
    &:hover {
        opacity: 0.9;
    }
}

.description {
    font: Medium 20px Quicksand;
    letter-spacing: 0px;
    color: #311b92;
    height: 20px;
    font-weight: 640;
    font-size: 3.1vh;
    padding-top: 4%;
}
