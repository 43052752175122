.port{
    width: 37vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 50% 50%;
}
.port1,.port2{
    position: relative;
}
.togglePort{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.portnameActive{
    position: absolute;
    transform: translate(101%,-168%);
    font-size: 2vh;
    pointer-events: none;
    color: white
}

.portnameInActive{
    position: absolute;
    transform: translate(101%,-168%);
    font-size: 2vh;
    pointer-events: none;
}

.orangeBtn,.whiteBtn{
    width: 65%;                          
    cursor: pointer;
}
.toast{
    z-index: 10000;
    /* height: 60px; */
    width: 18vw;
    position: relative;
    top: 93%;
    right: 32%;
    transform: translate(-50%, -50%);
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    border-radius: 25px;
    background-color: #d2d2d2;
}