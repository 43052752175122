.InternalAccessoriesScreen_Main_v1 {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 80% 10%;
}

.InternalAccessoriesScreen_Main_v1 .Item-1_v1 {
    /* height: 70%; */
    width: 80%;
    /* border: 1px solid purple; */
    position: relative;
    /* margin: 3% 0% 0% 12%; */
    transform: translate(15%, 15%);
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: 100%;
}
.InternalAccessoriesScreen-Item2_v1 .input_v1 {
    margin-top: 30px;
}
.InternalAccessoriesScreen-Item2_v1 .onput_v1 {
    margin-top: 30px;
}
.InternalAccessoriesScreen-Item2_v1 .input_v1 .inputText_v1 {
    font-size: 18px;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}
.onput_v1 .txtTitle_v1 {
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}
.InternalAccessoriesScreen-Item1_v1 {
    position: relative;
    height: 80%;
    width: 100%;
    top: 50%;
    transform: translate(-0%, -50%);
}

/* .InternalAccessoriesScreen-Item1 .micPc {
    width: 2%;
    height: auto;
    position: fixed;
    top: 45%;
    left: 25%;
    transform: translate(-25%, -45%);
  } */

.device_v1 .deviceContainer_v1 {
    height: 75%;
    width: 80%;
    /* border: 1px solid green; */
    position: relative;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
}

.device_v1 .deviceContainer_v1 .imgStyleMic_v1 {
    width: 9%;
    position: absolute;
    top: -23%;
    left: 50%;
    transform: translate(-48%, -5%);
    z-index: -1;
}

.device_v1 .deviceContainer_v1 .imgStyleBuzzer_v1 {
    /* border: 1px solid red; */
    width: 90%;
    height: 40%;
    position: absolute;
    top: -8%;
    left: 1%;
    transform: translate(-1.5%, 0%);
    z-index: 3;
}

/* /////// version 1.0.03 ////// */
.device_v1 .deviceContainer_v1 .imgStyleEye_v1 {
    width: 3.8%;
    position: absolute;
    top: 42.5%;
    left: 32.5%;
}

.device_v1 .deviceContainer_v1 .imgStyleEyeL_v1 {
    width: 12%;
    position: absolute;
    top: 36%;
    left: 31%;
}

.device_v1 .deviceContainer_v1 .imgStyleEyeR_v1 {
    width: 2%;
    position: absolute;
    top: 36%;
    left: 59%;
}

.device_v1 .deviceContainer_v1 .imgStyleTeeth_v1 {
    width: 1.7%;
    height: 16px;
    position: absolute;
    top: 67.6%;
    left: 43.1%;
}
/* .device .deviceContainer .imgStyleTeeth2 {
  width: 1.7%;
  height: 16px;
  position: absolute;
  top: 67.6%;
  left: 43.1%;
}
.device .deviceContainer .imgStyle .device .deviceContainer .imgStyleTeeth3 {
  width: 2.7%;
  height: 18px;
  position: absolute;
  top: 73.2%;
  left: 55.1%;
}

.device .deviceContainer .imgStyleTeeth4 {
  width: 1.7%;
  height: 16px;
  position: absolute;
  top: 67.6%;
  left: 55.5%;
} */

.device_v1 .deviceContainer_v1 .imgStyle4in1_v1 {
    width: 7.5%;
    height: 16px;
    position: absolute;
    top: 73%;
    left: 47%;
}

.device_v1 .deviceContainer_v1 .imgStyleTouchpads1_v1,
.device_v1 .deviceContainer_v1 .imgStyleTouchpads2_v1,
.device_v1 .deviceContainer_v1 .imgStyleTouchpads3_v1 {
    height: 13.5%;
    position: absolute;
    bottom: -11.5%;
    left: 20.3%;
    transform: translateX(-16.5%);
}

.device_v1 .deviceContainer_v1 .imgStyleTouchpads2_v1 {
    left: 31%;
    transform: translateX(15.2%);
}

.device_v1 .deviceContainer_v1 .imgStyleTouchpads3_v1 {
    left: 49.5%;
    transform: translateX(-47.5%);
}

.InternalAccessoriesScreen-Item2_v1 {
    height: 100%;
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
}

.InternalAccessoriesScreen-Item2_v1 .input_v1 .inputContiner_v1,
.InternalAccessoriesScreen-Item2_v1 .onput_v1 .outputContiner_v1 {
    width: 70%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: repeat(3, 33%);
    grid-template-columns: 100%;
}

.InternalAccessoriesScreen-Item2_v1 .onput_v1 .container-row_v1,
.InternalAccessoriesScreen-Item2_v1 .input_v1 .container-row_v1 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.InternalAccessoriesScreen-Item2_v1 .input_v1 .inputText_v1 {
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}
.InternalAccessoriesScreen-Item2_v1 .onput_v1 .outputContiner_v1 {
    width: 75%;
}

.input_v1 .inputContiner_v1 .imgStyle_v1 {
    width: 20%;
    height: 70%;
    margin-right: 5%;
    cursor: pointer;
}
.input_v1 .inputContiner_v1 .space1_v1 {
    margin-left: 2%;
}
.onput_v1 .outputContiner_v1 .imgStyle_v1 {
    width: 20%;
    height: 70%;
    margin-right: 4%;
    cursor: pointer;
}

.onput_v1 .outputContiner_v1 .space_v1 {
    margin-left: 3%;
}

.bottom0 {
    bottom: 0% !important;
}

#snackbar1,
#snackbar2,
#snackbar3,
#snackbar4,
#snackbar5,
#snackbar6,
#snackbar7,
#snackbar8,
#snackbar9,
#snackbar10,
#snackbar11,
#snackbar12,
#snackbar13,
#snackbar14,
#snackbar15,
#snackbar16,
#snackbar17,
#snackbar18,
#snackbar19,
#snackbar20,
#snackbar21,
#snackbar22,
#snackbar23,
#snackbar24,
#snackbar25,
#snackbar26,
#snackbar_atrMotor1,
#snackbar_atrMotor2,
#snackbar_atrMotor3 {
    visibility: hidden;
    min-width: 150px;
    margin-left: -125px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: -50%;
    font-size: 17px;
}

#snackbar1.show,
#snackbar2.show,
#snackbar3.show,
#snackbar4.show,
#snackbar5.show,
#snackbar6.show,
#snackbar7.show,
#snackbar8.show,
#snackbar9.show,
#snackbar10.show,
#snackbar11.show,
#snackbar12.show,
#snackbar13.show,
#snackbar14.show,
#snackbar15.show,
#snackbar16.show,
#snackbar17.show,
#snackbar18.show,
#snackbar19.show,
#snackbar20.show,
#snackbar21.show,
#snackbar22.show,
#snackbar23.show,
#snackbar24.show,
#snackbar25.show,
#snackbar26.show,
#snackbar12_M1.show,
#snackbar13_M2.show,
#snackbar14_M3.show,
#snackbar15_M4.show,
#snackbar16_D1.show,
#snackbar17_D1.show,
#snackbar18_D1.show,
#snackbar19_D1.show,
#snackbar6_A1.show,
#snackbar3_A1.show,
#snackbar4_B1.show,
#snackbar7_B1.show,
#snackbar5_C1.show,
#snackbar8_C1.show,
#snackbar_atrMotor1.show,
#snackbar_atrMotor2.show,
#snackbar_atrMotor3.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.isDisableBtn {
    opacity: 0.5;
}

.Ss_slide_v1 {
    width: 100vw;
    height: 98vh;
    position: absolute;
    /* margin-left: 15px; */
    z-index: 100000;
    background: #fff;
}
.helpClo_v1 {
    position: absolute;
    margin-right: 17%;
    /* top: 70%; */
    height: 85%;
    width: 12%;
    margin-top: 12%;
    margin-right: 43%;
    z-index: 100000;
}

@-webkit-keyframes fadein {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@keyframes fadein {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

@keyframes fadeout {
    from {
        bottom: -50%;
        opacity: 10%;
    }
    to {
        bottom: -50%;
        opacity: 80%;
    }
}

/* @media screen and (max-width: 2124px) and (min-width: 1868px) {
    .imgStyleMic {
      margin-top: -10px;
    }
    .device .deviceContainer .imgStyleEyeL {
      width: 9.5%;
      height: 27px;
      position: absolute;
      top: 34%;
      left: 32%;
    }
  
    .device .deviceContainer .imgStyleEyeR {
      width: 9.5%;
      height: 27px;
      position: absolute;
      top: 34%;
      left: 60%;
    }
  
    .device .deviceContainer .imgStyleTeeth1 {
      width: 2.7%;
      height: 18px;
      position: absolute;
      top: 70.6%;
      left: 39.7%;
    }
    .device .deviceContainer .imgStyleTeeth2 {
      top: 72.1%;
      left: 43%;
    }
  
    .device .deviceContainer .imgStyleTeeth3 {
      top: 72.2%;
    }
  
    .device .deviceContainer .imgStyleTeeth4 {
      top: 70.6%;
    }
  
    .device .deviceContainer .imgStyle4in1 {
      top: 73%;
    }
  } */
