.flowchart-InternalAccessoriesScreen_Main {
    height: 98vh !important;
    width: 100%;
}
.flowchart-InternalAccessoriesScreen_Main .flowchart-Item-1 {
    height: 70%;
    width: 80%;
    position: relative;
    transform: translate(15%, 15%);
    display: grid;
    grid-template-columns: 35% 65%;
    grid-template-rows: 100%;
}

.flowchart-InternalAccessoriesScreen-Item1 {
    position: relative;
    height: 80%;
    width: 100%;
    top: 50%;
    transform: translate(-0%, -50%);
}

.flowchart-InternalAccessoriesScreen-Item2 {
    height: 100%;
    width: 100%;
    display: flex;
}

.flowchart-device .deviceContainer {
    height: 75%;
    width: 80%;
    position: relative;
    top: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, -10%);
    transform: translate(-50%, -10%);
}

.flowchart-InternalAccessoriesScreen-Item2 .input .flowchart-inputText {
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}

.output .flowchart-txtTitle {
    font-size: 18px;
    position: absolute;
    top: 20%;
    left: 45%;
    -webkit-transform: translate(-30%, -50%);
    transform: translate(-30%, -50%);
    font-family: 'Halcyon_SemiBold' !important;
}

.flowchart-device .deviceContainer .imgStyleEyeL {
    width: 12% !important;
    height: 24px !important;
    position: absolute;
    top: 36.5% !important;
    left: 31% !important;
}

.flowchart-device .deviceContainer .imgStyleEyeR {
    width: 12% !important;
    height: 24px !important;
    position: absolute;
    top: 36.5% !important;
    left: 59% !important;
}

.flowchart-device .deviceContainer .imgStyleTeeth1 {
    width: 2.7%;
    height: 18px;
    position: absolute;
    top: 71.6%;
    left: 39.7%;
}

.flowchart-device .deviceContainer .imgStyleTeeth2 {
    width: 2.7%;
    height: 18px;
    position: absolute;
    top: 73.5%;
    left: 43%;
}

.flowchart-device .deviceContainer .imgStyleTeeth3 {
    width: 2.7%;
    height: 18px;
    position: absolute;
    top: 73.5%;
    left: 55.1%;
}

.flowchart-device .deviceContainer .imgStyleTeeth4 {
    width: 2.7%;
    height: 16px;
    position: absolute;
    top: 71.6%;
    left: 58.6%;
}

.flowchart-device .deviceContainer .imgStyle4in1 {
    width: 7.5%;
    height: 16px;
    position: absolute;
    top: 73%;
    left: 47%;
}

.flowchart-device .deviceContainer .imgStyleMic {
    width: 80px;
    /* height: 91px !important; */
    position: absolute;
    top: -33% !important;
    left: 50%;
    transform: translate(-48%, -5%);
    z-index: -1 !important;

    max-height: 38% !important;
}

.flowchart-device .deviceContainer .imgStyleBuzzer {
    width: 100%;
    height: 40%;
    position: absolute;
    top: -15%;
    left: 0.5%;
    -webkit-transform: translate(-1.5%);
    transform: translate(-1.5%);
    z-index: 3;
}

.flowchart-device .deviceContainer .imgStyleTouchpads1,
.flowchart-device .deviceContainer .imgStyleTouchpads2,
.flowchart-device .deviceContainer .imgStyleTouchpads3 {
    height: 16.5%;
    position: absolute;
    bottom: -22.5%;
    left: 15.3%;
    -webkit-transform: translateX(-23.5%);
    transform: translateX(-23.5%);
}
.flowchart-device .deviceContainer .imgStyleTouchpads1,
.flowchart-device .deviceContainer .imgStyleTouchpads2,
.flowchart-device .deviceContainer .imgStyleTouchpads3 {
    height: 16.5%;
    position: absolute;
    bottom: -22.5%;
    left: 15.3%;
    -webkit-transform: translateX(-23.5%);
    transform: translateX(-23.5%);
}

.flowchart-device .deviceContainer .imgStyleTouchpads1,
.flowchart-device .deviceContainer .imgStyleTouchpads2,
.flowchart-device .deviceContainer .imgStyleTouchpads3 {
    height: 16.5%;
    position: absolute;
    bottom: -22.5%;
    left: 15.3%;
    -webkit-transform: translateX(-23.5%);
    transform: translateX(-23.5%);
}

.flowchart-device .deviceContainer .imgStyleTouchpads1 {
    -webkit-transform: translateX(-34.8%);
    transform: translateX(-34.8%);
}

.flowchart-device .deviceContainer .imgStyleTouchpads2 {
    left: 31%;
    -webkit-transform: translateX(-34.8%);
    transform: translateX(-34.8%);
}

.flowchart-device .deviceContainer .imgStyleTouchpads3 {
    left: 49%;
    -webkit-transform: translateX(-34.8%);
    transform: translateX(-34.8%);
}

.flowchart-InternalAccessoriesScreen-Item2 .input .inputContiner,
.flowchart-InternalAccessoriesScreen-Item2 .output .outputContiner {
    width: 70%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: repeat(3, 33%);
    grid-template-columns: 100%;
}
.flowchart-InternalAccessoriesScreen-Item2 .input .container-row,
.flowchart-InternalAccessoriesScreen-Item2 .output .container-row {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.output .outputContiner .imgStyle {
    width: 20%;
    height: 70%;
    margin-right: 4%;
    cursor: pointer;
}
.input .inputContiner .imgStyle {
    width: 20% !important;
    height: 70% !important;
    margin-right: 4% !important;
    cursor: pointer;
}
#snackbar1,
#snackbar2,
#snackbar3,
#snackbar4,
#snackbar5,
#snackbar6,
#snackbar7,
#snackbar8,
#snackbar9,
#snackbar10,
#snackbar11,
#snackbar12,
#snackbar13,
#snackbar14,
#snackbar15,
#snackbar16,
#snackbar17,
#snackbar18,
#snackbar19,
#snackbar12_M1,
#snackbar13_M2,
#snackbar14_M3,
#snackbar15_M4,
#snackbar16_D1,
#snackbar17_D1,
#snackbar18_D1,
#snackbar19_D1,
#snackbar6_A1,
#snackbar3_A1,
#snackbar4_B1,
#snackbar7_B1,
#snackbar5_C1,
#snackbar8_C1 {
    visibility: hidden;
    min-width: 150px;
    margin-left: -125px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: -50%;
    font-size: 17px;
}
