.DesImg {
    width: 100%;
    height: 65px;
}
.DesboxText {
    position: absolute;
    top: 8px;
    left: 16px;
    color: #0278ae;
}
@media (max-width: 600px) {
    .DesImg {
        width: 100%;
        height: 65px;
    }
}
