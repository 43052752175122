.animation .react-flow__node {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
}

.animation .react-flow__node-default.selected {
    box-shadow: none !important;
    background-color: none !important;
}

.animation .react-flow__node-default,
.animation .react-flow__node-input,
.animation .react-flow__node-output {
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
}

.animation .react-flow__handle-left {
    position: absolute !important;
    // top: 50px !important;
    left: 30px !important;
    z-index: -1 !important;
}

.animation .react-flow__handle-right {
    position: absolute !important;
    left: 30px !important;
    // top: 50px !important;
    z-index: -1 !important;
}

.mainAssembly::-webkit-scrollbar {
    overflow: hidden;
}

.mainAssembly {
    overflow: hidden;
}

.animation path.react-flow__edge-path {
    stroke-width: 4 !important;
    stroke-linecap: round !important;
    z-index: 100 !important;
    cursor: pointer !important;
}

.animation .react-flow__handle-top {
    position: absolute !important;
    top: -10px !important;
    left: 32px !important;
    z-index: -1 !important;
}

// .react-flow__handle-left{
//   position: absolute !important;
//   top: 10px !important;
//   left: 31px !important;
// }

// .target1 {
//   position: absolute !important;
//   left: 55px !important;
//   top: 40px !important;
// }

// .target3 {
//   position: absolute !important;
//   // left: 55px !important;
//   // top: -4px !important;
// }

// .target2 {
//   position: absolute !important;
//   left: 18px !important;
//   top: 40px !important;
// }

.animation .faltuA {
    position: absolute !important;
    left: 10px !important;
}

.animation .faltuB {
    position: absolute !important;
    // right: 10px !important;
    left: 152px !important;
}
