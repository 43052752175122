.select-container {
    height: 100vh;
    width: 100vw;
}

.select-navbar {
    height: 10%;
    width: 100%;
    background-color: #f0f7fa;
    position: relative;
}

.select-item {
    height: 100%;
    width: 250px;
}

.btnGroup-hexapod {
    position: absolute;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5rem;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    width: 23rem;
    height: 26rem;
}

.btnGroup-humanoid {
    position: absolute;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5rem;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    width: 23rem;
    height: 26rem;
}

.btnGroup-klaw {
    position: absolute;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1.5rem;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
    width: 23rem;
    height: 26rem;
    align-content: center;
    justify-content: center;
}

.btnGroup_H {
    position: absolute;
    padding: 2rem;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
}

.offBtn,
.onBtn {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.2rem;
    padding-top: 0.5rem;
    border: None;
    border-radius: 2rem;
    font-family: halcyon_SemiBold;
}

.offBtn {
    color: black;
    background: linear-gradient(to right, #f0eded, white);
}

.onBtn {
    color: white;
    background: linear-gradient(to right, #fda84e, #f97d62);
}

.navbar-item-select {
    color: #fff;
    font-size: 18px;
    font-family: halcyon_SemiBold !important;
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translate(-8%, -50%);
}
.navbar-item-center {
    color: black;
    font-size: 18px;
    font-family: halcyon-SemiBold;
    position: absolute;
    top: 50%;
    left: 22%;
    transform: translate(-25%, -50%);
}

.select-helpbutton {
    height: 73%;
    width: 50px;
    position: absolute;
    top: 45%;
    right: 6%;
    transform: translate(-6%, -45%);
    cursor: pointer;
}

.select-bluetooth {
    height: 60px;
    width: 80px;
    position: absolute;
    top: 75%;
    right: 0%;
    transform: translate(-0%, -75%);
    cursor: pointer;
}

.body-container {
    height: 80%;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
}

.select-savebutton {
    height: 73%;
    width: 50px;
    position: absolute;
    top: 45%;
    right: 11%;
    transform: translate(-11%, -45%);
    cursor: pointer;
}

.select-humanoid {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 60% 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.select-hexapod {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 80% 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.sm-toggle-button {
    height: 33px;
    width: 33px;
    cursor: pointer;
}

.btn-humanoid-one {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 14%;
    right: 40%;
}
.btn-humanoid-two {
    position: absolute;
    bottom: 23%;
    right: 40%;
}
.btn-humanoid-three {
    position: absolute;
    bottom: 34%;
    right: 40%;
}
.btn-humanoid-four {
    position: absolute;
    bottom: 46%;
    right: 40%;
}
.btn-humanoid-five {
    position: absolute;
    bottom: 56%;
    right: 40%;
}

.btn-humanoid-six {
    position: absolute;
    top: 38%;
    right: 24%;
    transform: translate(-38%, -24%);
}

.btn-humanoid-seven {
    position: absolute;
    top: 28%;
    right: 28%;
}
.btn-humanoid-eight {
    position: absolute;
    top: 23%;
    right: 35%;
}
.btn-humanoid-nine {
    position: absolute;
    bottom: 14%;
    right: 53%;
}
.btn-humanoid-ten {
    position: absolute;
    bottom: 23%;
    right: 53%;
}
.btn-humanoid-eleven {
    position: absolute;
    bottom: 34%;
    right: 53%;
}
.btn-humanoid-tweleve {
    position: absolute;
    bottom: 46%;
    right: 53%;
}

.btn-humanoid-thirteen {
    position: absolute;
    bottom: 56%;
    right: 53%;
}

.btn-humanoid-fourteen {
    position: absolute;
    top: 37%;
    left: 26%;
}

.btn-humanoid-fifteen {
    position: absolute;
    top: 28%;
    left: 28%;
}

.btn-humanoid-sixteen {
    position: absolute;
    top: 23%;
    left: 35%;
}

.btn-H {
    position: absolute;
    top: 28%;
    left: 49%;
    transform: translate(-28%, -49%);
}

.btn-hexapod-one {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 59%;
    right: 43%;
}
.btn-hexapod-two {
    position: absolute;
    top: 28%;
    right: 37%;
}
.btn-hexapod-three {
    position: absolute;
    top: 21%;
    right: 32%;
}
.btn-hexapod-four {
    position: absolute;
    bottom: 47%;
    right: 40%;
}
.btn-hexapod-five {
    position: absolute;
    bottom: 47%;
    right: 32%;
}

.btn-hexapod-six {
    position: absolute;
    bottom: 47%;
    right: 25%;
}

.btn-hexapod-seven {
    position: absolute;
    bottom: 34%;
    right: 42%;
}
.btn-hexapod-eight {
    position: absolute;
    bottom: 28%;
    right: 37%;
}
.btn-hexapod-nine {
    position: absolute;
    bottom: 21%;
    right: 32%;
}
.btn-hexapod-ten {
    position: absolute;
    bottom: 59%;
    right: 51%;
}
.btn-hexapod-eleven {
    position: absolute;
    top: 28%;
    left: 39%;
}
.btn-hexapod-tweleve {
    position: absolute;
    top: 21%;
    left: 34%;
}

.btn-hexapod-thirteen {
    position: absolute;
    bottom: 47%;
    right: 54%;
}

.btn-hexapod-fourteen {
    position: absolute;
    bottom: 47%;
    right: 61%;
}

.btn-hexapod-fifteen {
    position: absolute;
    bottom: 47%;
    right: 68%;
}

.btn-hexapod-sixteen {
    position: absolute;
    bottom: 34%;
    right: 52%;
}
.btn-hexapod-seventeen {
    position: absolute;
    bottom: 28%;
    right: 56%;
}

.btn-hexapod-eighteen {
    position: absolute;
    bottom: 21%;
    right: 61%;
}

.btn-klaw-one {
    /* border: 1px solid red; */
    position: absolute;
    bottom: 32%;
    left: 21%;
}
.btn-klaw-two {
    position: absolute;
    bottom: 42%;
    left: 22%;
}
.btn-klaw-three {
    position: absolute;
    bottom: 53%;
    left: 15%;
}
.btn-klaw-four {
    position: absolute;
    top: 27%;
    left: 18%;
}
.btn-klaw-five {
    position: absolute;
    top: 30%;
    left: 24%;
}
.btn-H {
    position: absolute;
    top: 28%;
    left: 49%;
    transform: translate(-28%, -49%);
}
.popupcard {
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 60% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.sm-popcard-button {
    height: 52px;
    width: 52px;
    cursor: pointer;
}

/* FIRST ROW */

.inactive-Card-One {
    position: absolute;
    top: 18%;
    left: 30%;
    /* border: 1px solid red; */
}

.inactive-Card-Two {
    position: absolute;
    top: 18%;
    left: 38%;
}
.inactive-Card-Three {
    position: absolute;
    top: 18%;
    left: 46%;
}

.inactive-Card-Four {
    position: absolute;
    top: 18%;
    left: 54%;
}
.inactive-Card-Five {
    position: absolute;
    top: 35%;
    left: 30%;
}

.inactive-Card-Six {
    position: absolute;
    top: 35%;
    left: 38%;
}

.inactive-Card-Seven {
    position: absolute;
    top: 35%;
    left: 46%;
}

.inactive-Card-Eight {
    position: absolute;
    top: 35%;
    left: 54%;
}
/* Thirsd Row */
.inactive-Card-Nine {
    position: absolute;
    top: 52%;
    left: 30%;
}

.inactive-Card-Ten {
    position: absolute;
    top: 52%;
    left: 38%;
}
.inactive-Card-Eleven {
    position: absolute;
    top: 52%;
    left: 46%;
}

.inactive-Card-Tweleve {
    position: absolute;
    top: 52%;
    left: 54%;
}

/* Third Row */
.inactive-Card-Thirteen {
    position: absolute;
    top: 69%;
    left: 30%;
}
.inactive-Card-Fourteen {
    position: absolute;
    top: 69%;
    left: 38%;
}
.inactive-Card-Fifteen {
    position: absolute;
    top: 69%;
    left: 46%;
}
.inactive-Card-Sixteen {
    position: absolute;
    top: 69%;
    left: 54%;
}

.inactive-Card-H {
    position: absolute;
    top: 44%;
    left: 65%;
}
.select-backbutton {
    height: 60px;
    width: 60px;
    position: absolute;
    bottom: 2%;
    left: 2%;
    transform: translate((-2%, -5%));
    cursor: pointer;
}

.select-nextbutton {
    height: 60px;
    width: 60px;
    position: absolute;
    bottom: 2%;
    right: 3%;
    transform: translate((-5%, -2%));
    cursor: pointer;
}
@media (max-width: 768px) {
    .select-item {
        width: 125px;
    }

    .select-helpbutton {
        height: 73%;
        width: 40px;
        margin-right: 40px;
    }

    .select-bluetooth {
        height: 50px;
        width: 80px;
        margin-top: 5px;
    }
    .select-savebutton {
        height: 73%;
        width: 40px;
        margin-right: 60px;
    }
}

@media (min-width: 769px) and (max-width: 1224px) {
    .select-item {
        width: 125px;
    }

    .select-helpbutton {
        height: 73%;
        width: 40px;
        margin-right: 30px;
    }

    .select-bluetooth {
        height: 50px;
        width: 80px;
        margin-top: 5px;
    }
    .select-savebutton {
        height: 73%;
        width: 40px;
        margin-right: 30px;
    }
}

@media (max-width: 900px) {
    .sm-popcard-button {
        height: 35px;
        width: 35px;
    }

    .sm-toggle-button {
        height: 22px;
        width: 22px;
    }
    .select-backbutton,
    .select-nextbutton {
        height: 62px;
        width: 62px;
    }
}
