.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999;
}

.box {
    position: relative;
    margin: 65px auto;
}

.content {
    position: absolute;
    top: 0%;
    height: 80%;
    width: 68%;
    margin: 7% 0% 0% 37.5%;
}
