.wdt {
    position: relative;
    text-align: center;
    outline: none;
    width: 98% !important;
    display: inline-block;
    transform: translate(10px, 8%);
    top: 0;
    left: -5px;
}
.Hel {
    position: absolute;
    top: 27%;
    right: 78.7%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    /* height: 9%; */
    width: 3%;
    right: 78.65%;
}
.Hel_P {
    background-color: white;
    padding: 6px;

    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.Rem {
    position: absolute;
    top: 39%;
    right: 3.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    /* height: 19%; */
    width: 34%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Rem_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.con {
    position: absolute;
    top: 60%;
    right: 71.3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    width: 3%;
}
.con_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Mus {
    position: absolute;
    top: 60%;
    right: 64.6%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    width: 2%;
}
.Mus_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.Cam {
    position: absolute;
    top: 60%;
    right: 59.9%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    width: 2%;
}
.Cam_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.Ca {
    position: absolute;
    top: 60%;
    right: 52.55%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    width: 2%;
}
.Ca_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}

.Camr {
    position: absolute;
    top: 60%;
    right: 44.35%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    width: 2%;
}
.Camr_P {
    background-color: white;
    padding: 6px;
    /* word-wrap: break-word; */
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}

.img {
    /* height: 85vh !important; */
    width: 100%;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    /* color: black; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    bottom: 80px;
    left: -3%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -1%;
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-slide img {
    display: grayscale;
}

@media only screen and (max-device-width: 1080px) {
    .Hel {
        right: 78.9%;
        top: 29%;
    }
}
