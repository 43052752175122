.wdt {
    position: relative;
    text-align: center;
    /* color: black; */
    outline: none;
    width: 95% !important;
    transform: translate(-50%, 1%);
    top: -0%;
    left: 50%;
}
.Hel {
    position: absolute;
    top: 22%;
    right: 8.3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    padding: 14px, 28px;
    border-radius: 12px;
    /* height: 14%; */
    width: 51%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Hel_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 8%;
  left: 6%;
  height: 50%;
  width: 91%; */
}
.Rem {
    position: absolute;
    top: 39%;
    right: 3.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    padding: 14px, 28px;
    border-radius: 12px;
    height: 19%;
    width: 34%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Rem_P {
    text-align: left;
    align-items: flex-start;
    font-size: 1.8vh;
    letter-spacing: 0px;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    position: absolute;
    top: 9%;
    left: 3%;
    height: 5%;
    width: 92%;
}
.Cam1 {
    position: absolute;
    top: 53%;
    left: 60%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    /* padding: 14px, 28px; */
    /* border-radius: 12px; */
    /* height: 11%; */
    width: 40%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Cam1_P {
    background-color: white;
    padding: 5px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 10%;
  left: 5%;
  height: 5%;
  width: 91%; */
}
.Cam2 {
    position: absolute;
    top: 39%;
    left: 57%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    /* padding: 14px, 28px; */
    /* border-radius: 12px; */
    /* height: 11%; */
    width: 35%;
}
.Cam2_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 10%;
  left: 7.5%;
  height: 5%;
  width: 91%; */
}
.Cam3 {
    position: absolute;
    top: 68.5%;
    left: 63.7%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px; */
    border-radius: 12px;
    /* height: 9%; */
    width: 32%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Cam3_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 14%;
  left: 6.5%;
  height: 5%;
  width: 91%; */
}
.con {
    position: absolute;
    top: 48%;
    left: 38.6%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    /* padding: 14px, 28px; */
    /* border-radius: 12px; */
    /* height: 16%; */
    width: 30%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.con_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 10%;
  left: 6%;
  height: 5%;
  width: 91%; */
}
.Mus {
    position: absolute;
    top: 56%;
    left: 80%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff;
  padding: 14px, 28px;
  border-radius: 12px;
  height: 25%; */
    width: 30%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Mus_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 16px;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 7%;
  left: 7%;
  height: 5%;
  width: 91%; */
}
.Cam {
    position: absolute;
    top: 52%;
    left: 62%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    padding: 14px, 28px;
    border-radius: 12px;
    /* height: 19%; */
    width: 40%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Cam_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 8%;
  left: 5%;
  height: 5%;
  width: 91%; */
}
.Ca {
    position: absolute;
    top: 77.5%;
    left: 31.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff;
  padding: 14px, 28px;
  border-radius: 12px; */
    /* height: 8%; */
    width: 24%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Ca_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 12%;
  left: 5%;
  height: 5%;
  width: 91%; */
}

.Camr {
    position: absolute;
    top: 78.5%;
    left: 74%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #ffffff; */
    /* padding: 14px, 28px; */
    /* border-radius: 12px; */
    /* height: 8%; */
    width: 13%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Camr_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    /* text-align: left;
  align-items: flex-start;
  font-size: 1.8vh;
  letter-spacing: 0px;
  color: #707070;
  font-family: "Halcyon_Regular" !important;
  position: absolute;
  top: 12%;
  left: 8%;
  height: 5%;
  width: 91%; */
}

.img {
    /* height: 85vh !important; */
    width: 100%;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    /* color: black; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    bottom: 80px;
    left: -3%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -1%;
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-slide img {
    display: grayscale;
}
