/* .carousel row {
  margin: 0;
}
.carousel-cell {
  width: 66%;
  margin-right: 3rem;
}

.cell__wrap {
  width: 100%;
  margin: 0 auto;
}

.cell__inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.cell__title {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%) translateX(-20%);
}

.text-split {
  overflow: hidden;
  display: inline-block;
}
.text-split whitespace {
  display: initial;
}

/* @for $i from 1 through 100 {
      &:nth-child(#{$i}) .text-split__inner {
        transition-delay: 0.02s * $i;
      }
    }
  } */

/* .text-split__inner {
  transform: translateY(100%);
  display: inline-block;
  transition: transform 0.3s ease;
}
.is-selected {
  transform: translateY(0);
}

.whitespace {
  display: initial;
}

.cell__thumb {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Work Sans", sans-serif;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  /* background-color: #00011D;
      color: #FFF; */
/* } */
/* .container { */
/* width: 100%; */
/* } */

.wdt {
    position: relative;
    text-align: center;
    /* color: black; */
    outline: none;

    width: 95% !important;
}
.Hel {
    position: absolute;
    top: 26.5%;
    right: 70.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    height: 9%;
    width: 4%;
}
.Hel-P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.Rem {
    position: absolute;
    top: 53.5%;
    right: 70.1%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    height: 9%;
    width: 4%;
}
.Rem_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.con {
    position: absolute;
    top: 55%;
    right: 60.1%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 3%;
}
.con_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.Mus {
    position: absolute;
    top: 54%;
    right: 53.6%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 3%;
}
.Mus_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.Cam {
    position: absolute;
    top: 55%;
    right: 42.1%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 3%;
}
.Cam_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}

.img {
    /* height: 80vh !important; */
    width: 100%;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    /* color: black; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    /* bottom: 2%; */
    bottom: 0%;
    /* left: -3%; */
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
/* .slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
   
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    background-color: #fff;
    -webkit-tap-highlight-color: transparent;
    transform: translate(-30%, 0%);
    top: 50%;
    left: 50%;
    z-index: 100000;
    width: 100vw;
    height: 114vh;
} */
.slick-slide img {
    display: grayscale;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    /* margin-top: -1%; */
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    background-color: #fff;
    -webkit-tap-highlight-color: transparent;
    transform: translate(-50%, -50%);
    top: 48%;
    left: 48%;
    z-index: 100000;
    width: 78vw;
    /* height: 65vh; */
    /* border: 2px solid red; */
}
.slick-slide {
    padding: 34px 80px 0 80px !important;
}
