.container1 {
    height: 60vh;
    padding: 0 3rem;
    font-family: halcyon_Regular;
    /* background-color: red; */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 2rem;
}

.card,
.card-red {
    width: 13rem;
    height: 17rem;

    border: 2px solid white;
    border-radius: 2rem;
    background-image: linear-gradient(to right, #eeeeee, #f9f9f9);

    box-shadow: 0px 20px 25px 10px rgba(192, 187, 187, 0.2);
}

.card-red {
    border: none;
    background-image: none;
    background-image: linear-gradient(to right, #f2a8a4, #f5a5af);
}

.card-selected {
    border: 2px solid #fc9e53;
    scale: 1.05;
}

.card:hover,
.card-red:hover {
    scale: 1.05;
}

footer {
    margin: 0 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    font-size: medium;
    padding: 2rem;
    /* font-family: halcyon_Regular; */
    line-height: 1.5rem;
}

.loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: rgb(155, 153, 153);
    animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}
.loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}
.loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}
@keyframes loader {
    0% {
        top: 8px;
        height: 64px;
    }
    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}
