.visualProgram-container {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 60% 40%;
    user-select: none; /* Likely future */
    /* border: 2px solid blue; */
}

.visualProgram-container .VP-backbtn {
    position: absolute;
    top: 3%;
    left: 3%;
    z-index: 2;
    user-select: none;
    /* Likely future */
}
.helpiconBtnSize {
    position: absolute;
    right: -22%;
    top: 3%;
    z-index: 2;
}
/* .S_slide {
 
  width: 100%;
  
  top: 33%;
  left: 0%;
  transform: translate(10px, 108px);
  position: absolute;
  
  z-index: 100000;
  background: #fff;
  
}  */
.helpClose {
    position: absolute;
    right: 19%;
    top: 18%;
    width: 2.5%;
    z-index: 100000;
    transform: translate(-50%, -50%);
}
.visualProgram-container .VP-programmenucard {
    position: absolute;
    z-index: 1;
    top: -4%;
    left: 4%;
    height: 175px;
    width: 20%;
    user-select: none; /* Likely future */
}

/* .visualProgram-container .VP-programmenucard {
  position: relative;
  z-index: 1;
  changes start
  top: -8%;
  left: 10%;
  transform: translate(-10%, 8%);
  changes end
  height: 175px;
  width: 30%;
  user-select: none; 
  Likely future
  border: 1px solid red;
} */

.visualProgram-container .VP-txt-Menu {
    position: absolute;
    z-index: 3;
    /* changes start */
    top: 5%;
    left: 11%;
    transform: translate(-11%, -5%);
    /* changes end */
    /* top: 5.5%;
  left: 5%; */
    color: #fff;
    font-family: Halcyon_SemiBold !important;
    font-size: 18px;
    user-select: none; /* Likely future */
    /* border: 1px solid blue; */
}

.visualProgram-container .VP-txt-Heading {
    font-size: 28px;
    color: #000;
    font-family: Halcyon_Medium !important;
    position: absolute;
    top: 20%;
    left: -108%;
    transform: translate(-50%, -50%);
}

.visualProgram-container .VP-hr {
    width: 80px;
    height: 4px;
    position: absolute;
    /* border: 1px solid red; */
    background: #30a8ce;
    outline: none;
    border: none;
    /* changes start */
    top: 18.5%;
    left: -78%;
    transform: translate(-50%, -50%);
}
/* changes start */

.visualProgram-container .VP-txt-Heading2 {
    font-size: 28px;
    color: #000;
    font-family: Halcyon_Medium !important;
    position: absolute;
    /* changes start */
    top: 20%;
    right: 27%;
    transform: translate(-50%, -50%);
    /* changes end */
}

.visualProgram-container .VP-hr2 {
    width: 80px;
    height: 4px;
    position: absolute;
    /* changes start */
    top: 18.5%;
    right: 20%;
    transform: translate(-50%, -50%);
    /* changes end */
    background: #30a8ce;
    outline: none;
    border: none;
    /* border: 1px solid blueviolet; */
}
.visualProgram-container .VP-flowchartbased,
.VP-programbased,
.VP-blockbased,
.VP-CCoding,
.VP-pythoncodingbutton {
    height: 46%;
    width: 55%;
    position: absolute;
    /* border: 1px solid red; */
}

/* .visualProgram-container .VP-flowchartbased,
.VP-programbased,
.VP-blockbased,
.VP-CCoding,
.VP-pythoncodingbutton {
  height: 47%;
  width: 22%;
  position: relative;
  border: 1px solid red;
} */

.visualProgram-container .vp-item1 {
    position: absolute;
    top: 50%;
    left: -68%;
    transform: translate(-50%, -50%);
}
.visualProgram-container .vp-item2 {
    position: absolute;
    top: 50%;
    left: -113%;
    transform: translate(-50%, -50%);
}
.visualProgram-container .vp-item3 {
    position: absolute;
    top: 50%;
    left: -21%;
    transform: translate(-50%, -50%);
}
.visualProgram-container .vp-item4 {
    position: absolute;
    top: 50%;
    left: 26%;
    transform: translate(-50%, -50%);
}
.visualProgram-container .vp-item5 {
    position: absolute;
    top: 50%;
    left: 73%;
    transform: translate(-50%, -50%);
}
.vp-item1 .VP-sub1 {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);

    /* border: 1px solid green; */
}
.vp-item2 .VP-sub2 {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);

    /* border: 1px solid green; */
}
.vp-item3 .VP-sub3 {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);

    /* border: 1px solid green; */
}
.vp-item4 .VP-sub4 {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);

    /* border: 1px solid green; */
}
.vp-item5 .VP-sub5 {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);
    /* border: 1px solid green; */
}
.vp-item5 .VP-sub5-04 {
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-55%, -50%);
    opacity: 0.2;
    /* border: 1px solid green; */
}

.VP-sub1 .VP-sub-txt {
    color: #000;
    font-family: Halcyon_SemiBold !important;
    font-size: 22px;
    position: absolute;
    top: 55%;
    white-space: pre-wrap;
}

/* .VP-sub2 .VP-sub2-txt {
    color: #000;
    font-family: Halcyon_SemiBold !important;
    font-size: 22px;
    position: absolute;
    top: 55%;
    white-space: pre-wrap;
} */
/* .VP-sub3 .VP-sub3-txt {
    color: #000;
    font-family: Halcyon_SemiBold !important;
    font-size: 22px;
    position: absolute;
    top: 55%;
    white-space: pre-wrap;
}
.VP-sub4 .VP-sub4-txt {
    color: #000;
    font-family: Halcyon_SemiBold !important;
    font-size: 22px;
    position: absolute;
    top: 55%;
} */
.VP-sub2 .VP-sub2-txt,
.VP-sub3 .VP-sub3-txt,
.VP-sub4 .VP-sub4-txt,
.VP-sub5 .VP-sub5-txt,
.VP-sub5-04 .VP-sub5-txt {
    color: #000;
    font-family: Halcyon_SemiBold !important;
    font-size: 22px;
    position: absolute;
    top: 55%;
    white-space: pre-wrap;
}
/* tablet 768px  
laptop 1024px
desktop 1440px
*/
/* large screen */

@media only screen and (max-width: 1600px) and (min-width: 700px) {
    .visualProgram-container .VP-hr {
        width: 80px;
        height: 4px;
        position: absolute;
        /* border: 1px solid red; */
        background: #30a8ce;
        outline: none;
        border: none;
        /* changes start */
        top: 18.5%;
        left: -65%;
        transform: translate(-50%, -50%);
    }
}

#popup-text {
    visibility: hidden;
    min-width: 150px;
    margin-left: -125px;
    background-color: #d2d2d2;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 40%;
    bottom: 5%;
    font-size: 17px;
}
#popup-text.show {
    visibility: visible;
    -webkit-animation: fadein 0.2s, fadeout 0.5s 4s;
    animation: fadein 0.2s, fadeout 0.5s 4s;
}
.comingSoon_text {
    text-align: center;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
}

@-webkit-keyframes fadein {
    from {
        bottom: 5%;
        opacity: 10%;
    }
    to {
        bottom: 5%;
        opacity: 80%;
    }
}

@keyframes fadein {
    from {
        bottom: 5%;
        opacity: 10%;
    }
    to {
        bottom: 5%;
        opacity: 80%;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 5%;
        opacity: 10%;
    }
    to {
        bottom: 5%;
        opacity: 80%;
    }
}

@keyframes fadeout {
    from {
        bottom: 5%;
        opacity: 10%;
    }
    to {
        bottom: 5%;
        opacity: 80%;
    }
}
