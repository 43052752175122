.wdt {
    position: relative;
    text-align: center;
    /* color: black; */
    outline: none;

    width: 95% !important;
    transform: translate(1%, -1%);
}
.Hel {
    position: absolute;
    top: 26.5%;
    right: 83.4%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    height: 9%;
    width: 3%;
}
.Hel_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Rem {
    position: absolute;
    top: 44.5%;
    right: 83.4%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    height: 9%;
    width: 3%;
}
.Rem_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Rem1 {
    position: absolute;
    top: 55%;
    left: 43.5%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 12px;
    width: 25vh;
}
.Rem_P1 {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Rem2 {
    position: absolute;
    top: 55%;
    left: 55.2%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 12px;
    width: 25vh;
}
.con1 {
    position: absolute;
    top: 44.5%;
    right: 100%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    height: 9%;
    width: 3%;
}
.con_P1 {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.con {
    position: absolute;
    top: 59%;
    right: 80.5%;
    /* transform: translate(-50%, -50%); */
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 2%;
    height: 5%;
}
.con_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Mus {
    position: absolute;
    top: 65%;
    right: 72.4%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 2%;
}
.Mus_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Cam {
    position: absolute;
    top: 64%;
    right: 68.05%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 2%;
}
.Cam_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Re5 {
    position: absolute;
    top: 72%;
    right: 61.7%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 1%;
}
.Re5_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Re6 {
    position: absolute;
    top: 50%;
    right: 56.9%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 2%;
}
.Re6_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}

.Re7 {
    position: absolute;
    top: 50%;
    right: 51.3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    width: 2%;
}

.Re7_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Re8 {
    position: absolute;
    top: 49.5%;
    left: 52.25%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 2%;
    border-radius: 12px;
}
.Re8_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.img {
    width: 100%;
}

.Level {
    position: absolute;
    top: 73%;
    left: 24.5%;
    transform: translate(-50%, -50%);
    width: 3%;
}
.Level_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Gui {
    position: absolute;
    top: 72%;
    left: 32.2%;
    transform: translate(-50%, -50%);
    width: 4%;
}
.Gui_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}

.Rgb {
    position: absolute;
    top: 45%;
    left: 39.3%;
    transform: translate(-50%, -50%);
    width: 4%;
}
.Rgb_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Bep {
    position: absolute;
    top: 45%;
    left: 46.5%;
    transform: translate(-50%, -50%);
    width: 2%;
}
.Bep_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Smi {
    position: absolute;
    top: 58.5%;
    left: 53.5%;
    transform: translate(-50%, -50%);
}
.Smi_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Rob {
    position: absolute;
    top: 55%;
    left: 41.4%;
    transform: translate(-50%, -50%);
    width: 1.2%;
}
.Rob_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Arm {
    position: absolute;
    top: 45%;
    left: 47.5%;
    transform: translate(-50%, -50%);
    width: 1.2%;
}
.Arm_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}
.Mot {
    position: absolute;
    top: 62%;
    left: 52.5%;
    transform: translate(-50%, -50%);
    width: 1.2%;
}
.Mot_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
}

.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    /* color: black; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    bottom: 80px;
    left: -3%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -1%;
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-slide img {
    display: grayscale;
}
