.wdt {
    position: relative;
    text-align: center;
    /* color: black; */
    outline: none;

    width: 95% !important;
    transform: translate(1%, -1%);
}
.Hdg {
    position: absolute;
    top: 26%;
    right: 79.4%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    /* height: 9%; */
    width: 3%;
}

.Hdg_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}

.Mdl {
    position: absolute;
    top: 42%;
    right: 79%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    /* height: 9%; */
    width: 3%;
}
.Mdl_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}
.Ftr {
    position: absolute;
    top: 61%;
    right: 78.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    border-radius: 12px;
    /* height: 9%; */
    width: 4%;
}
.Ftr_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}
.con {
    position: absolute;
    top: 49%;
    left: 24.15%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 2%;
    border-radius: 12px;
}
.con_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}
.Mus {
    position: absolute;
    top: 49%;
    left: 31.3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 2%;
    border-radius: 12px;
}
.Mus_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}
.Cam {
    position: absolute;
    top: 54%;
    left: 38.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 2%;
    border-radius: 12px;
}
.Cam_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}
.Cam1 {
    position: absolute;
    top: 49%;
    left: 45.6%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 2%;
    border-radius: 12px;
}
.Cam1_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}
.Cam2 {
    position: absolute;
    top: 49%;
    left: 52.75%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 2%;
    border-radius: 12px;
}
.Cam2_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0;
}

.img {
    /* height: 85vh !important; */
    width: 100%;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    /* color: black; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    bottom: 80px;
    left: -3%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -1%;
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    background-color: #fff;
    -webkit-tap-highlight-color: transparent;
}
.slick-slide img {
    display: grayscale;
}

/* 



<div className="Hdg">
<p
  style={{
    textAlign: "left",
    alignItems: "flex-start",
    fontSize: "1.8vh",
    letterSpacing: "0px",
    color: "#707070",
    fontFamily: "Halcyon_Regular",
    position: "absolute",
    top: "15%",
    left: "10%",
    height: "5%",
    width: "80%",
    // zIndex: "10",
  }}
>
  {" "}
  Add Button to add or scan for new devices you like to program{" "}
</p>
</div>
<div className="Mdl">
<p
  style={{
    textAlign: "left",
    alignItems: "flex-start",
    fontSize: "1.8vh",
    letterSpacing: "0px",
    color: "#707070",
    fontFamily: "Halcyon_Regular",
    position: "absolute",
    top: "15%",
    left: "10%",
    height: "5%",
    width: "80%",
    // zIndex: "10",
  }}
>
  {" "}
  Add Button to add or scan for new devices you like to program{" "}
</p>
</div>
<div className={"Ftr"}>
<p
  style={{
    textAlign: "left",
    alignItems: "flex-start",
    fontSize: "1.8vh",
    letterSpacing: "0px",
    color: "#707070",
    fontFamily: "Halcyon_Regular",
    position: "absolute",
    top: "15%",
    left: "10%",
    height: "5%",
    width: "80%",
    // zIndex: "10",
  }}
>
  {" "}
  Add Button to add or scan for new devices you like to program{" "}
</p>
</div> */
