.remote-container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.remote-backbutton {
    height: 52px;
    width: 52px;
    position: absolute;
    top: 30px;
    left: 31px;
    cursor: pointer;
}

.remote-backbutton-title {
    font-size: 16px;
    position: absolute;
    top: 40px;
    left: 101px;
    font-family: halcyon-SemiBold !important;
}

.remote-helpbutton {
    height: 52px;
    width: 52px;
    position: absolute;
    top: 30px;
    right: 81px;
    cursor: pointer;
}

.remote-bluetooth-button {
    height: 52px;
    width: 80px;
    position: absolute;
    top: 30px;
    right: 0px;
    cursor: pointer;
}
.remote-klaw-image {
    height: 219px;
    width: 173px;
    position: absolute;
    top: 114px;
    left: 320px;
}

.sub-container {
    width: 95%;
    position: absolute;
    top: 22%;

    /* border: 1px solid green; */
    display: grid;
    grid-template-columns: 15% 30% 50%;
}

.joustick-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}
.toggle-container{
    display: grid;
    align-self: center;
    justify-self: end;
    z-index: 100;
    position: absolute;
    right: 0rem;
    top: 38%;              
}
.klaw-container {
    width: 75%;
    display: flex;
    justify-content: center;
}

.klaw-container > img {
    height: 100%;
    width: 100%;
}
.actionBar-item-1-klaw {
    width: 60%;
    display: grid;
    grid-template-columns: 30% 30% 30%;
}

.action-container-klaw {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width:768px) {
    .sub-container {
        top: 35%;
    }
    .actionBar-item-1-klaw {
        width: 75%;
    }
}

@media screen and (min-width:1024px) {
    .sub-container {
        top: 35%;
    }
    .actionBar-item-1-klaw {
        width: 75%;
    }
}
@media screen and (min-width:1200px) {
    .sub-container {
        top: 22%;
    }
    .actionBar-item-1-klaw {
        width: 60%;
    }
}