/* * {
    font-family: 'Halcyon_Regular' !important;
} */

.input,
.textarea,
.select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: transparent;
    font-family: 'Halcyon_Regular' !important;
    resize: none;
}

.input {
    border: None;
    border: solid 1px rgba(0, 0, 0, 0.5);
}

.textarea {
    height: 10rem;
}

.leftCol {
    position: absolute;
    top: 15%;
    left: 5%;
    width: 60vw;
    height: 88vh;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rightCol {
    position: absolute;
    top: 15%;
    right: 5%;
    width: 30vw;
    height: 88vh;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    /* background-color: yellow; */
}

.caption {
    padding: 0.5rem;
    font-family: 'Halcyon_Regular' !important;
}

.feedback {
    margin-top: auto;
    padding: 2rem;
    font-size: 1.3rem;
    font-family: 'Halcyon_Regular' !important;
}

.success {
    color: green;
}

.error {
    color: red;
}

.inputCover {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    padding: 2rem;
    border-radius: 5px;

    background-color: rgb(233, 233, 233);
}

.btnGrp {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 2rem;
    padding: 1rem;

    margin-top: auto;
    /* background-color: red; */
}
