.mainContainer {
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
}

.mainBody {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: aqua; */
}
.mainImageContainer {
    position: relative;
    width: 48.5vw;

    /* background-color: red; */
}

.mainContentContainer {
    display: grid;
    grid-template-rows: auto auto auto;
    align-items: center;
    justify-content: center;
    grid-gap: 6%;
    position: relative;
    width: 48.5vw;
    margin-top: 4vh;
    /* background-color: yellow; */
}
.logoContainer {
    position: relative;
    display: grid;
    grid-template-rows: auto;

    width: 100%;
    align-items: center;
    justify-content: center;
    /* background-color: pink; */
}
.mainContentLogo {
    position: relative;
    height: 15vh;
}
.paragraphContainer {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    align-items: center;
    justify-content: center;
    width: 48.5vw;

    /* background-color: rgb(255, 255, 255); */
}
.paragraph {
    /* position: relative; */
    width: 25vw;
    /* height: 35vh; */
    justify-content: center;
    text-align: justify;
    font: 0.93vw Halcyon_Regular !important;
    letter-spacing: 0px;
    color: #707070;
    text-overflow: ellipsis;
}

.mainImage {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 15%;
    padding: 0px 0px 0px 0px;
}
.buttonsContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
}
.box {
    position: relative;
    width: 14vw;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
}
.boxImage {
    position: relative;
    width: 12vw;
}
.boxItem {
    position: absolute;
    height: 50%;
    top: 37%;
    left: 16%;
    font-size: 0.9vw;
}
.msg {
    /* border: 1px solid red; */
    color: red;
    font-size: 0.9vw;
    margin-bottom: 1%;
}

.footer {
    position: relative;
    height: 5vh;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    align-items: right;
    justify-content: right;
    /* background-color: blue; */
}

.privacyPolicy {
    font: normal normal normal 12px/15px Halcyon_Regular !important;
    letter-spacing: 0px;
    color: #707070;
    cursor: pointer;
}

.bottomTextDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
.copyRightText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    margin-bottom: 0;
}
