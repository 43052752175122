.wakeWordDiv {
    /* background-size: '100% 100%';
    background-repeat: 'no-repeat'; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}
.wakeWordInnerDiv {
    /* background-size: '100% 100%';
    background-repeat: 'no-repeat'; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 40%;
    gap: 50px;

    /* border: 1px solid green; */
}
.wakeWordInnerDiv input {
    border: 1px solid #717171;
    border-radius: 11px;
    height: 29%;
    width: 35%;
}
.trainQueryDiv {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 49vh;
    /* border: 1px solid red; */
    gap: 10px;
}
.tranColorListDiv::-webkit-scrollbar {
    display: none;
}
.eachQueryDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.eachQueryDiv p {
    margin-bottom: 0px;
    color: #717171;
    font-family: Halcyon_Medium;
}
.eachQueryDiv input {
    border: 1px solid #717171;
    border-radius: 11px;
    height: 45px;
    width: 70%;
    padding-left: 10px;
}
.eachQueryDiv img {
    cursor: pointer;
}
