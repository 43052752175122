/* ------------------------------------------------------- */
input[type='range'][orient='vertical'] {
    writing-mode: vertical-lr;
    direction: rtl;
    /* appearance: slider-vertical; */
    appearance: none;
    width: 0px;
    height: 200px;
    vertical-align: bottom;
    /* background: linear-gradient(to top, rgb(168, 248, 163), rgb(1, 182, 1)); */
}

input[type='range'][orient='vertical']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: url('../../../../../../../Assets/slider button.svg');
    background-size: cover; /* Cover the entire thumb */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; /* Make it circular */
    margin-left: 10px;
    margin-bottom: -20px;
}

.slider__track {
    /* background: linear-gradient(to top, rgb(247, 233, 224), rgb(243, 146, 87)); */
    background-color: rgb(243, 146, 87);
    width: 10px;
    z-index: -1;
    border-radius: 10px;
    height: 190px;
    position: absolute;
    top: 20px;
}

/* ------------------------------------------------------- */

.horizontal_Slider {
    appearance: none;
    width: 210px;
    height: 10px;
    vertical-align: bottom;
    background: transparent;
}

.horizontal_Slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 40px; /* Set a specific slider handle width */
    height: 40px; /* Slider handle height */
    background: url('../../../../../../../Assets/slider button.svg');
    background-size: cover; /* Cover the entire thumb */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%; /* Make it circular */
    margin-left: 10px;
    margin-bottom: -10px;
}

.horizontal_slider__track {
    /* background: linear-gradient(
        to right,
        rgb(247, 233, 224),
        rgb(243, 146, 87)
    ); */
    background-color: rgb(243, 146, 87);
    width: 190px;
    z-index: -1;
    border-radius: 10px;
    height: 10px;
    position: absolute;
    /* left: 20px; */

    top: 15px;
    left: 14px;
}
