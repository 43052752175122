.Main_Select {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 80% 10%;
}
.Select_Header {
    height: 100%;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 10% 25% 65%;
}
.Back_Btn {
    /* position: absolute;
    cursor: pointer;
    top: 60%;
    left: 4.57%;
    transform: translate(-50%, -50%);
    width: 6.7vh; */

    height: auto;
    width: 4%;
    position: absolute;
    top: 40%;
    left: 2%;
    cursor: pointer;
}
.text {
    position: absolute;
    top: 82%;
    left: 19%;
    transform: translate(-50%, -50%);
    font-size: 25px;
}
.setting_Body {
    /* position: relative; */
    display: flex;
}
.setting_BodyDiv {
    position: absolute;
    height: 43vh;
    left: 16%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.Body_Text {
    height: 11%;
    /* width: 13%; */
    position: absolute;
    top: 56%;
    left: 26%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.P_Text {
    font-size: 2.5vh;
    position: absolute;
}

.setting_BodyDiv1 {
    position: absolute;
    height: 43vh;
    left: 35%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.Body_Text1 {
    height: 11%;
    /* width: 13%; */
    position: absolute;
    top: 56%;
    left: 45%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.P_Text1 {
    font-size: 2.5vh;
    position: absolute;
}

.setting_BodyDiv2 {
    position: absolute;
    height: 14vh;
    left: 17.5%;
    top: 20%;
    transform: translate(-50%, -50%);
}
.Body_Text2 {
    height: 11%;
    width: 19%;
    position: relative;
    top: 16vh;
    left: 14%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 4%;

    /* border: 2px solid red; */
}
.P_Text2 {
    font-size: 2vh;
    position: relative;
    transform: translateY(10%);
    margin-bottom: 0px;
}

/* .setting_BodyDiv3 {
    position: absolute;
    height: 14vh;
    left: 17.5%;
    top: 40%;
    transform: translate(-50%, -50%);
} */
.Body_Text3 {
    height: 11%;
    width: 13%;
    position: relative;
    top: 31.5vh;
    left: 15%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.P_Text3 {
    font-size: 1.7vh;
    position: absolute;
}

#Help_Bttn {
    position: relative;
}

.Help_Bttn_Img {
    /* width: 7vh;
    position: absolute;
    top: 58%;
    right: 2%;
    transform: translate(-50%, -50%); */

    width: 6.05%;
    position: absolute;
    top: 84%;
    right: 3.2%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.helpClose {
    width: 4.5vh;
    cursor: pointer;
}
.S_slide {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: -54%;
    z-index: 100000;
    background: #fff;
}

/* Tooltip styles */
/* .Body_Text2  */
.account_setting_div .tooltiptext {
    position: absolute;
    top: 47px;
    left: -49px;
    visibility: hidden;
    background-color: #3b4441;
    color: #e4e5e4;
    text-align: center;
    padding: 6px 7px;
    font-weight: lighter;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.account_setting_div:hover .tooltiptext {
    visibility: visible;
}

.tooltiptext > p {
    margin-bottom: -2.5% !important;
    color: #a4a9ac;
    font-size: 1.5vh;
}

/* .setting_BodyDiv3 {
    position: absolute;
    height: 43vh;
    left: 52%;
    top: 50%;
    transform: translate(-50%, -50%);
} */
.Body_Text4 {
    height: 11%;
    /* width: 13%; */
    position: absolute;
    top: 56%;
    left: 64%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.body,
.inner_body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
.copyRightText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    margin-bottom: 0;
}

/* ========================================================================== */

.account_setting_left_inner {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 13vh;
    margin-left: 8vw;
}
.account_setting_div {
    border: 1px solid white;
    background: linear-gradient(
        to right,
        rgb(249, 199, 207),
        rgb(250, 223, 228)
    );
    box-shadow: 4px 4px 4px 0px rgba(151, 151, 151, 0.5);
    height: 50px;
    width: 300px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
}
.language_setting_div {
    border: 2px solid white;
    background: rgb(222, 221, 221);
    box-shadow: 4px 4px 4px 0px rgba(206, 205, 205, 0.5);
    height: 50px;
    width: 300px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
}

.language_setting_div_select {
    border: 2px solid rgb(244, 165, 79) !important;
}

.setting_Body_right_inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: #65f962;
    background-color: aquamarine; */
    margin-top: 13vh;
    margin-left: 8vw;
}
.setting_Body_confirmBtn_div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.setting_Body_confirmBtn {
    margin-top: 2rem;
    min-width: 2rem;
    padding: 0.65rem 2rem;
    border: 0px;
    border-radius: 1.8rem;
    /* height: 40px ; */
    color: white;
    background: linear-gradient(to right, #fda84e, #f97d62);
    cursor: pointer;
}
/* .OperationMod-btn {
    margin-top: 13vh;
    margin-left: 8vw;
} */
.swipeButtonDiv {
    margin-top: 13vh;
    margin-left: 8vw;
}
/* ------------------------------- */
.toggle-button {
    position: relative;
    width: 300px; /* Button width */
    height: 50px; /* Button height */
    border-radius: 25px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 13px;
    /* color: white; */
    background-color: rgb(216, 214, 214);
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%; /* Cover half of the button */
    height: 100%;
    background-color: rgb(40, 225, 40); /* Default color */
    transition: transform 0.5s ease;
    border-radius: 25px;
    z-index: 1;
}

.slider.right {
    transform: translateX(100%); /* Slide to the right */
    background-color: rgb(40, 225, 40);
}
.button-text {
    position: relative;
    z-index: 2; /* Above the sliding background */
    width: 50%;
    text-align: center;
}
.connect-text {
    padding-left: 10px;
}
.disconnect-text {
    padding-right: 10px;
}
