.PopUpContainer {
    position: fixed;
    height: 40vh;
    width: 25vw;
    border: 3px solid #1866bf;
    top: 50%;
    border-radius: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 9999;
}

.popupTitle {
    font-size: 1.5vw;
    text-align: center;
    color: #311b92;
    margin-top: 10%;
}

.popUpCloseIcon {
    position: absolute;
    height: 70px;
    width: 70px;
    top: -12%;
    right: -12%;
}

.btnYes {
    margin-top: 10%;
    height: 20%;
    width: 85%;
    margin-left: 7%;
    background-color: #311b92;
    border-radius: 50px;
}

.btnLater {
    margin-top: 10%;
    height: 20%;
    width: 85%;
    margin-left: 7%;
    background-color: #311b92;
    border-radius: 50px;
}

.popUpMenuStyle1 {
    color: #fff;
    font-size: 1.4vw;
    text-align: center;
}

.popUpMenuStyle {
    color: #fff;
    font-size: 1.4vw;
    text-align: center;
}
@media (max-width: 850px) and (min-width: 600px) {
    .popupTitle {
        font-size: 1.5vw;
        text-align: center;
        color: #311b92;
        margin-top: 15%;
    }
}
