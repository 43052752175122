@font-face {
    font-family: 'Halcyon Regular';
    src: local('Halcyon Regular'),
        url('../../Halcyon/Halcyon-Regular.otf') format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.scrollable {
    overflow: auto;
    position: absolute;
    top: 10vh;
    left: 0;
    right: 0;
    bottom: 20vh;
    scroll-behavior: smooth;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.inputWrapper {
    border-radius: 12px;
    border: 2px solid black;
    width: 38vh;
    height: 5.5vh;
}

.selectBox {
    width: 38vh;
    height: 7vh;
    border: 0px;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    background: white;
    border: 2px solid black;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background-color: grey; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.gridContainer {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    background-color: white;
    gap: 1px 1px;
    padding: 10px;
    z-index: 10;
}
.gridItem {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid grey;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
}

.popupInner {
    position: absolute;
    padding: 37vh 8vw;
    width: 100%;
    max-width: 70vw;
    height: 20vh;
    background-color: lightgray;
    border-radius: 5vh;
}

.detectionPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.detectionPopupInner {
    position: absolute;
    padding: 34vh 8vw;
    width: 100%;
    max-width: 37vw;
    height: 20vh;
    background-color: white;
    border: 0.8vh solid orange;
    border-radius: 5vh;
}

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 15vw;
    height: 0.9vh;
    background: #ff9933;
    outline: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    padding: 5px 5px;
    appearance: none;
    width: 35px;
    height: 25px;
    background: url('../../Assets/slider button.svg');
    cursor: pointer;
    transform: scale(1);
}
