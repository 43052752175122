.container1,
.container2 {
    height: 70vh;
    padding: 0 3rem;
    font-family: halcyon_Regular;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.container1 {
    justify-content: left;
    overflow-y: scroll;
}

.container2 {
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
}

.card {
    height: 15rem;
    width: 18rem;
    padding: 1rem;
    border-radius: 1.5rem;
    cursor: pointer;

    /* border: 2px solid white; */
    /* box-shadow: 0 0 40px #eaeaea; */
    /* background: linear-gradient(to right, #f0eded, white); */
    background: url('../../../Assets/Project\ all\ included@3x.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.name {
    margin: 7rem 2rem;
    font-family: halcyon_Semibold;
    /* height: 100%; */
    /* background-color: red; */
}
