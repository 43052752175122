.Main_Select {
    height: 100vh;
    width: 100vw;
    /* position: relative; */
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 20% 50% 20%;
}

/* .Select_Headers {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 7% 25% 30% 30% 10%;
    grid-template-areas: 'Sbackbtn  SPlay_Comp SEmpty_div SAdvance_Setting SHelp_Bttn';
} */
#Sbackbtn {
    grid-area: Sbackbtn;
    position: relative;
}
.Back_Btns {
    width: 6.7vh;
    position: absolute;
    top: 60%;
    left: 65%;
    transform: translate(-50%, -50%);
}

#SPlay_Comp {
    grid-area: SPlay_Comp;
    position: relative;
}
.Play_Comp_text {
    font-size: 2.5vh;
    position: absolute;
    top: 55%;
    left: 0%;
    transform: translate(0%, -50%);
    font-family: 'Halcyon_SemiBold';
}

#SEmpty_div {
    grid-area: SEmpty_div;
}
#SAdvance_Setting {
    grid-area: SAdvance_Setting;
    position: relative;
}
.Advance_Setting_Img {
    width: 7vh;
    position: absolute;
    top: 58%;
    right: 0%;
    transform: translate(0%, -50%);
}

#SHelp_Bttn {
    grid-area: SHelp_Bttn;
    position: relative;
}
.Help_Bttn_Img {
    width: 7vh;
    position: absolute;
    top: 58%;
    right: 33%;
    transform: translate(-50%, -50%);
}
.Play_Divv {
    height: 30%;
    width: 100%;
    margin-top: 75%;
    /* position: absolute; */
}
.Play_Body {
    display: grid;
    grid-template-columns: (20% 20% 20% 20% 20%);
    grid-template-rows: 100%;
    grid-template-areas: ' play learn data code app';
    /* overflow-x: auto; */
    overflow-x: scroll;
    overflow-y: hidden;
}
.Play_Body1 {
    display: grid;
    grid-template-columns: (16.66% 16.67% 16.66% 16.67% 16.66% 16.67%);
    grid-template-rows: 100%;
    grid-template-areas: ' ai play learn data code app';
    /* overflow-x: auto; */
    overflow-x: scroll;
    overflow-y: hidden;
}

.Play_Body::-webkit-scrollbar,
.Play_Body1::-webkit-scrollbar {
    display: none;
}
.Play_Body0::-webkit-scrollbar {
    display: none;
}
.Play_Body0 {
    display: grid;
    grid-template-columns: (25% 25% 25% 25%);
    grid-template-rows: 100%;
    grid-template-areas: 'ai play learn code app';
    overflow-x: auto;
}

#ai {
    grid-area: ai;
    position: relative;
}
#play {
    grid-area: play;
    position: relative;
}

#learn {
    grid-area: learn;
    position: relative;
}

#data {
    grid-area: data;
    position: relative;
}
#code {
    grid-area: code;
    position: relative;
}
#app {
    grid-area: app;
    position: relative;
}

.sub_body {
    height: 66%;
    width: 62%;
    /* border: 1px solid red; */
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.text {
    font-size: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(25vh);
    color: white;
}

.Play_Button {
    height: 400px;
    width: 380px;
    position: absolute;
    top: 55%;
    z-index: -1;
    transform: translate(-50%, -50%);
}
.Play_Btn {
    height: auto;
    width: 17.5%;
    position: absolute;
    top: 63%;
    left: 24.5%;
    transform: translate(-50%, -50%);
}
.Program_Button {
    height: 400px;
    width: 380px;
    position: absolute;
    top: 55%;
    z-index: -1;
    transform: translate(-50%, -50%);
}
.Learn_Button {
    height: 400px;
    width: 380px;
    position: absolute;
    top: 56%;
    z-index: -1;
    transform: translate(-50%, -50%);
    border: '2px solid red';
}
.Build_Button {
    height: 400px;
    width: 380px;
    position: absolute;
    top: 54%;
    z-index: -1;

    transform: translate(-50%, -50%);
}
.Play_txt {
    width: 45px;
    /* height: 20px; */
    font-size: 27px;
    font-family: 'Halcyon_SemiBold' !important;
    color: #fff;
    text-align: center;
    position: absolute;
    cursor: pointer;
    top: 59.5%;
    margin-left: -3%;
    transform: translate(-50%, -50%);
}
.Program_txt {
    width: 85px;
    /* height: 25px; */
    font-size: 27px;
    color: #fff;
    font-family: 'Halcyon_SemiBold' !important;
    text-align: center;
    position: absolute;
    cursor: pointer;

    top: 59.5%;
    margin-left: -3%;
    transform: translate(-50%, -50%);
}
.Learn_txt {
    width: 85px;
    /* height: 25px; */
    font-size: 27px;
    color: #fff;
    font-family: 'Halcyon_SemiBold' !important;
    text-align: center;
    position: absolute;
    cursor: pointer;

    top: 60%;
    margin-left: -3%;
    transform: translate(-50%, -50%);
}
.Build_txt {
    width: 45px;
    /* height: 25px; */
    font-size: 27px;
    font-family: 'Halcyon_SemiBold' !important;
    color: #fff;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 59.5%;
    margin-left: -4%;
    transform: translate(-50%, -50%);
}

.S_slide {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: -920%;
    z-index: 100000;
    background: #fff;
}
.helpClose {
    width: 4.5vh;
    cursor: pointer;
}
.load_gif {
    position: absolute;
    top: 45vh;
    left: 45vw;
}

@media only screen and (max-device-width: 1440px) {
    .Play_Button {
        height: 380px;
        width: 360px;
    }
    .Play_Btn {
        width: 23.2%;
        /* position: absolute; */
        top: 65%;
        left: 24.3%;
    }
    .Program_Button {
        height: 380px;
        width: 360px;
    }
    .Learn_Button {
        height: 380px;
        width: 360px;
    }
    .Build_Button {
        height: 380px;
        width: 360px;
    }
    .Learn_txt {
        /* font-size: 30px; */
        top: 63%;
        left: 47%;
    }
    .Program_txt {
        /* font-size: 30px; */
        top: 63%;
        /* left: 47%; */
        left: 71%;
    }
    .Play_txt {
        /* font-size: 30px; */
        top: 62.5%;
        left: 21%;
    }
    .Build_txt {
        /* font-size: 30px; */
        top: 63%;
        left: 71%;
    }
}

@media only screen and (max-device-width: 1053px) {
    .Play_Button {
        height: 380px;
        width: 360px;
    }
    .Play_Btn {
        width: 23.2%;
        /* position: absolute; */
        top: 65%;
        left: 24.3%;
    }
    .Program_Button {
        height: 380px;
        width: 360px;
    }
    .Learn_Button {
        height: 380px;
        width: 360px;
    }
    .Build_Button {
        height: 380px;
        width: 360px;
    }
    .Program_txt {
        font-size: 30px;
        top: 59%;
        /* left: 46%; */
        left: 70%;
    }
    .Learn_txt {
        font-size: 30px;
        top: 59%;
        left: 46%;
    }
    .Play_txt {
        font-size: 30px;
        top: 58.5%;
        left: 20%;
    }
    .Build_txt {
        font-size: 30px;
        top: 59%;
        left: 70%;
    }
}
@media only screen and (max-device-width: 1800px) and (min-device-width: 1441px) {
    .Program_txt {
        top: 62.5%;
    }
    .Play_txt {
        top: 62%;
    }
    .Build_txt {
        top: 62.5%;
    }
    .Learn_txt {
        top: 62.5%;
    }
}
@media only screen and (max-device-width: 2150px) and (min-device-width: 1801px) {
    /* .Play_Comp {
        top: 4%;
        left: 6%;
    } */
    .Play_txt {
        font-size: 28px;
        top: 60.5%;
        left: 22%;
    }
    .Build_txt {
        font-size: 28px;
        top: 61%;
        left: 72%;
    }
    .Program_txt {
        font-size: 28px;
        top: 61%;
        /* left: 47.5%; */
        left: 72%;
    }
    .Learn_txt {
        font-size: 28px;
        top: 61%;
        left: 47.5%;
    }
}
@media only screen and (max-device-width: 1024px) {
    /* .Back_Btn {
        height: 60px;
        width: 60px;
        
    }
    .Play_Comp {
      
        width: 150px;
        font-size: 20px;
       
        top: 5%;
        left: 10%;
    }
    .Help_Bttn {
       
        height: 60px;
        width: 60px;
    } */
    .Play_Button {
        height: 320px;
        width: 300px;
    }
    .Play_Btn {
        /* width: 24.2%; */
        /* position: absolute; */
        /* top: 61.5%; */
        /* left: 24.3%; */
        width: 21.2%;
        /* position: absolute; */
        top: 62.5%;
        left: 24.3%;
    }
    .Program_Button {
        height: 320px;
        width: 300px;
    }
    .Build_Button {
        height: 320px;
        width: 300px;
    }
    .Learn_Button {
        height: 320px;
        width: 300px;
    }
    .Program_txt {
        font-size: 25px;
        top: 59%;
        /* left: 47%; */
        left: 71%;
    }
    .Learn_txt {
        font-size: 25px;
        top: 59%;
        left: 47%;
    }
    .Play_txt {
        font-size: 25px;
        top: 58.5%;
        left: 20%;
    }
    .Build_txt {
        font-size: 25px;
        top: 59%;
        left: 71%;
    }
}

@media (max-width: 786px) {
    /* .Back_Btn {
        height: 55px;
        width: 55px;
    }
    .Play_Comp {
        font-size: 18px;
    
        margin-top: 1.5%;
        left: 11%;
      
    }
    .Help_Bttn {
        height: 55px;
        width: 55px;
    } */
    .Play_Button {
        height: 300px;
        width: 280px;
    }
    .Play_Btn {
        width: 26.2%;
        /* position: absolute; */
        top: 62%;
        left: 24.3%;
    }
    .Program_Button {
        height: 300px;
        width: 280px;
    }
    .Build_Button {
        height: 300px;
        width: 280px;
    }
    .Learn_Button {
        height: 300px;
        width: 280px;
    }
    .Program_txt {
        font-size: 23px;
        top: 59.5%;
        left: 70%;
    }
    .Play_txt {
        font-size: 23px;
        top: 59%;
        left: 20%;
    }
    .Build_txt {
        font-size: 23px;
        top: 59.5%;
        left: 70%;
    }
    .Learn_txt {
        font-size: 23px;
        top: 59.5%;
        left: 47%;
    }
}
/* @media (max-width: 768px) and (max-height: 880px) {
  .Play_Comp {
    top: 5.5%;
  }
}
@media (max-width: 1024px) and (max-height: 880px) {
  .Play_Comp {
    top: 5.5%;
  }
}
@media (max-width: 1440px) and (max-height: 880px) {
  .Play_Comp {
    top: 5%;
    left: 8%;
  }
} */

/* uploading */
/* .arrow_uploading{
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 66%;
    overflow: hidden;
   
}
.arrow_uploading img{
    height: 25px;
    margin-left: 10px;
    transform: translateX(-50%);
    animation: moveup 1s linear infinite;
}
@keyframes moveup {
    0%{
        transform: translateY(100%);
    }
    
    100%{
        transform: translateY(-100%);
    }
} */
.console_log {
    font-size: 1.5vh;
    width: 80%;
    height: 100%;
    resize: None;
    border: solid rgb(192, 189, 189) 2px;
    border-radius: 10px;
    color: white;
    padding: 1rem;
    background-color: black;
}
