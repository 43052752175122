.Main_Header {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 7% 30% 41% 22%;
    grid-template-rows: 100%;
    grid-template-areas: ' firstDiv SecondDiv ThirdDiv ForthDiv';
}

#firstDiv {
    grid-area: firstDiv;
    position: relative;
}
.backBtn {
    position: absolute;
    width: 7vh;
    top: 50%;
    right: 6%;
    transform: translate(3%, -50%);
}

#SecondDiv {
    grid-area: SecondDiv;
    position: relative;
}
.text {
    position: absolute;
    font-size: 2.7vh;
    top: 47%;
    transform: translate(5%, -50%);
}

#ThirdDiv {
    grid-area: ThirdDiv;
}

#ForthDiv {
    grid-area: ForthDiv;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
}

.graphBtn {
    width: 7vh;
}

.helpBtn {
    width: 7vh;
}
.helpBtn1 {
    width: 7vh;
    transform: translate(-25%, 0%);
}
.bluetooth {
    width: 11vh;
}
