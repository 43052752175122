.Main_Container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15% 85%;
    grid-template-areas:
        'Navbar'
        'Body';
}

#Navbar {
    grid-area: Navbar;
}

#Body {
    grid-area: Body;
}
