.Main_Container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 10% 75% 15%;
    grid-template-areas:
        'Navbar'
        'Body'
        'bottom';
}
.recharts_container {
    height: 110% !important;
    left: 2vw;
    position: relative;
}
.Bar_recharts_container {
    height: 100% !important;
    left: 2vw;
    position: relative;
}
#Navbar {
    grid-area: Navbar;
}

#Body {
    grid-area: Body;
}

#bottom {
    grid-area: bottom;
}
