body {
    background-color: #fafafa;
}
.Main_Remote {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 12% 78% 10%;
    grid-template-columns: 100%;
}
.Remote_Header {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 7% 13% 66% 7% 7%;
    grid-template-rows: 100%;
}
.Back_BTN {
    height: auto;
    width: 4%;
    position: absolute;
    top: 4%;
    left: 2%;
    cursor: pointer;
}
.Play_Remote {
    height: auto;
    width: 10%;
    font-size: 25px;
    position: absolute;
    top: 5.5%;
    font-family: 'Halcyon_SemiBold' !important;
}
.Help_Btn {
    height: auto;
    width: 4%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 6%;
}
.Bluetooth_Button {
    height: auto;
    width: 6%;
    position: absolute;
    cursor: pointer;
    top: 4%;
    right: 0.1%;
}
.Remote_Body {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 35% 30% 35%;
    grid-template-rows: 100%;
}
.Buzzer_Lefteye {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
    /* position: absolute; */
    /* top: 10%; */
}
.Ace_Teeth {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 70% 30%;
    grid-template-columns: 100%;
    /* border: 1px solid red; */
}

.Func_Righteye {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
}
.Left_Eye_Backdround {
    height: auto;
    width: 28%;
    position: absolute;
    top: 66%;
    left: 18%;
    transform: translate(-50%, -50%);
}
/* .Green_Slider {
  height: 40%;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.Left_Partition {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 80% 20%;
    grid-template-columns: 100%;
}
.Right_Partition {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 80% 20%;
    grid-template-columns: 100%;
}
.Buzzer_Partition {
    height: 100%;
    width: 100%;
    display: grid;
    /* position: relative; */
    grid-template-rows: 80% 20%;
    grid-template-columns: 100%;
}

.Slider_Div1 {
    height: 20%;
    width: 20%;
    display: grid;
    position: absolute;
    top: 56.5%;
    left: 10%;
    /* margin-top: 10%; */
    /* margin-left: 25%; */
    grid-template-rows: repeat(3, 33%);
    /* row-gap: 3%; */
    grid-template-columns: 100%;
}
.Slider_Div2 {
    height: 20%;
    width: 20%;
    display: grid;
    position: absolute;
    top: 56.5%;
    right: 8%;
    /* margin-top: 10%; */
    /* margin-left: 25%; */
    grid-template-rows: repeat(3, 33%);
    /* row-gap: 3%; */
    grid-template-columns: 100%;
}
.Slider_Div3 {
    height: 5%;
    width: 16%;
    display: grid;
    position: absolute;
    top: 32.5%;
    left: 13%;
    /* margin-top: 10%; */
    /* margin-left: 25%; */
    /* grid-template-rows: repeat(3, 33%); */
    /* row-gap: 3%; */
    /* grid-template-columns: 100%; */
}

.Buzzer_Bg {
    height: auto;
    width: 25%;
    position: absolute;
    top: 36%;
    left: 18%;
    transform: translate(-50%, -50%);
}
.Buzzer_txt {
    height: 34px;
    width: 64px;
    font-size: 18px;
    position: absolute;
    font-family: 'Halcyon_SemiBold' !important;
    top: 35.5%;
    left: 11%;
    transform: translate(-50%, -50%);
}
.Right_Eye_Backdround {
    height: auto;
    width: 28%;
    position: absolute;
    top: 66%;
    left: 80%;
    transform: translate(-50%, -50%);
}
.Left_Eye_txt {
    height: 34px;
    width: 33px;
    position: absolute;
    font-family: 'Halcyon_SemiBold' !important;
    font-size: 22px;
    top: 65%;
    left: 10%;
    transform: translate(-50%, -50%);
}
.Right_Eye_txt {
    height: 34px;
    width: 33px;
    position: absolute;
    font-family: 'Halcyon_SemiBold' !important;
    font-size: 22px;
    top: 65%;
    left: 72%;
    transform: translate(-50%, -50%);
}
.Talkback_IA {
    height: 80px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 38%;
    left: 73%;
    transform: translate(-50%, -50%);
}
.Disco_IA {
    height: 80px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 38%;
    left: 79.5%;
    transform: translate(-50%, -50%);
}
.Gesture_IA {
    height: 80px;
    width: 80px;
    position: absolute;
    cursor: pointer;
    top: 38%;
    left: 86%;
    transform: translate(-50%, -50%);
}
.Talkback_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 73.5%;
    transform: translate(-50%, -50%);
}
.Disco_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 81%;
    transform: translate(-50%, -50%);
}
.Gesture_txt {
    height: 80px;
    width: 80px;
    position: absolute;
    font-family: 'Halcyon_Medium' !important;
    color: #707070;
    top: 48.5%;
    left: 87%;
    transform: translate(-50%, -50%);
}

.PC_img {
    height: auto;
    width: 50.5%;
    position: relative;
    /* background-repeat: no-repeat;
  background-size: 2%; */
    z-index: 0;
    top: 23.8%;
    left: 22.8%;
    /* transform: translate(-50%, -50%); */
}

/* .PC_img {
  width: 300px;
  height: 320px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.Left_Eye_Inactive {
    height: auto;
    width: 8%;
    position: absolute;
    top: 33%;
    left: 39%;
    transform: translate(-50%, -50%);
}
.Right_Eye_Inactive {
    height: auto;
    width: 8%;
    position: absolute;
    top: 33%;
    left: 62%;
    transform: translate(-50%, -50%);
}
.Buzzer_Inactive {
    /* height: 65px;
  width: 220px; */
    height: auto;
    width: 85%;
    position: absolute;
    top: 6%;
    left: 49.4%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth1 {
    width: 2%;
    height: auto;
    position: absolute;
    top: 58.6%;
    left: 42.95%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth2 {
    width: 2%;
    height: auto;
    position: absolute;
    top: 59.8%;
    left: 45.5%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth3 {
    width: 2%;
    height: auto;
    position: absolute;
    top: 59.8%;
    left: 55.31%;
    transform: translate(-50%, -50%);
}
.Ace_Teeth4 {
    width: 2%;
    height: auto;
    position: absolute;
    top: 58.6%;
    left: 58%;
    transform: translate(-50%, -50%);
}
.Ace_4Teeth {
    width: 6%;
    height: auto;
    position: absolute;
    top: 60.5%;
    left: 50.5%;
    transform: translate(-50%, -50%);
}

.Teeth_Bg {
    height: auto;
    width: 20%;
    position: absolute;
    top: 74%;
    left: 49.5%;
    transform: translate(-50%, -50%);
}
.Teeth_Smile1 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 74%;
    left: 43.5%;

    transform: translate(-50%, -50%);
}
.Teeth_Smile2 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 74%;
    left: 47.7%;
    transform: translate(-50%, -50%);
}
.Teeth_Smile3 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 74%;
    left: 52%;
    transform: translate(-50%, -50%);
}
.Teeth_Smile4 {
    height: 48px;
    width: 48px;
    position: absolute;
    cursor: pointer;
    top: 74%;
    left: 56%;
    transform: translate(-50%, -50%);
}

.Rem_Slider {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0%;
    z-index: 100000;
    background: #fff;
    border: 1px solid red;
}
.Cros {
    position: absolute;
    right: 13%;
    top: 8%;
    height: 10%;
    width: 5%;
    z-index: 100000;
}
@media screen and (max-width: 1300px) and (min-width: 1124px) {
    .Ace_Teeth {
        margin-top: 10%;
    }
}
@media screen and (max-width: 1124px) and (min-width: 600px) {
    .Ace_Teeth {
        margin-top: 80px;
    }
    .Play_Remote {
        /* height: auto; */
        /* width: 10%; */
        font-size: 23px;
        /* position: absolute; */
        top: 4.5%;
        /* font-family: "Halcyon_SemiBold" !important; */
    }
    .Talkback_IA {
        height: 65px;
        width: 65px;
    }
    .Disco_IA {
        height: 65px;
        width: 65px;
    }
    .Disco_txt {
        left: 81.5%;
        font-size: 15px;
        top: 47.5%;
    }
    .Talkback_txt {
        font-size: 15px;
        top: 47.5%;
    }

    .Gesture_txt {
        font-size: 15px;
        top: 47.5%;
    }
    .Gesture_IA {
        height: 65px;
        width: 65px;
    }
    .Teeth_Smile1 {
        height: 38px;
        width: 38px;
    }
    .Teeth_Smile2 {
        height: 38px;
        width: 38px;
    }
    .Teeth_Smile3 {
        height: 38px;
        width: 38px;
    }
    .Teeth_Smile4 {
        height: 38px;
        width: 38px;
    }

    .Right_Eye_txt,
    .Left_Eye_txt {
        font-size: 19px;
    }
    .Buzzer_txt {
        font-size: 16px;
        top: 36%;
        left: 12%;
    }
    .Slider_Div2 {
        height: 18%;
        width: 18%;
    }
    .Slider_Div1 {
        height: 18%;
        width: 18%;

        left: 11%;
    }
    .Slider_Div3 {
        width: 15%;
        left: 15%;
    }
}

@media screen and (max-width: 1000px) and (min-width: 850px) {
    .Slider_Div2 {
        height: 16%;

        top: 57.5%;
    }
    .Slider_Div1 {
        height: 16%;

        top: 57.5%;
    }
    .Slider_Div3 {
        top: 33%;
    }
    .Play_Remote {
        font-size: 21px;

        top: 4.1%;
    }
    .Disco_txt {
        left: 82%;
        font-size: 14px;
    }
    .Talkback_txt {
        font-size: 14px;
    }

    .Gesture_txt {
        left: 88%;
        font-size: 14px;
    }
}
@media screen and (max-width: 851px) and (min-width: 750px) {
    .Slider_Div2 {
        height: 13%;

        top: 58.5%;
    }
    .Slider_Div1 {
        height: 13%;

        top: 58.5%;
    }
    .Slider_Div3 {
        top: 33%;
    }
    .Play_Remote {
        font-size: 20px;

        top: 4%;
    }
    .Disco_txt {
        left: 82%;
        font-size: 14px;
    }
    .Talkback_txt {
        font-size: 14px;
    }

    .Gesture_txt {
        left: 88%;
        font-size: 14px;
    }
    .Teeth_Bg {
        top: 69%;
    }

    .Teeth_Smile1,
    .Teeth_Smile2,
    .Teeth_Smile3,
    .Teeth_Smile4 {
        height: 33px;
        width: 33px;
        top: 69.5%;
    }
}
@media screen and (max-width: 750px) and (min-width: 600px) {
    .Slider_Div2 {
        height: 13%;

        top: 58.5%;
    }
    .Slider_Div1 {
        height: 13%;
        left: 12%;
        top: 58.5%;
    }
    .Slider_Div3 {
        top: 33%;
    }
    .Play_Remote {
        font-size: 19px;

        top: 4%;
    }
    .Right_Eye_txt,
    .Left_Eye_txt {
        font-size: 16px;
        top: 66%;
    }
    .Left_Eye_txt {
        left: 11%;
    }
    .Talkback_IA,
    .Disco_IA,
    .Gesture_IA {
        height: 58px;
        width: 58px;
    }
    .Gesture_IA {
        left: 88%;
    }
    .Disco_IA {
        left: 80.5%;
    }
    .Disco_txt {
        left: 83.8%;
        font-size: 13px;
    }
    .Buzzer_txt {
        font-size: 13px;
        top: 36.5%;
        left: 13%;
    }
    .Talkback_txt {
        font-size: 13px;
    }

    .Gesture_txt {
        left: 90.5%;
        font-size: 13px;
    }
    .Teeth_Bg {
        top: 69%;
    }

    .Teeth_Smile1,
    .Teeth_Smile2,
    .Teeth_Smile3,
    .Teeth_Smile4 {
        height: 28px;
        width: 28px;
        top: 69.3%;
    }
}

@media screen and (max-width: 600px) and (min-width: 500px) {
    .Ace_Teeth {
        margin-top: 80px;
    }
    .Slider_Div2 {
        height: 9%;
        width: 18%;
        top: 57%;
        right: 7%;
    }
    .Slider_Div1 {
        height: 9%;
        width: 18%;
        left: 13%;
        top: 57%;
    }
    .Slider_Div3 {
        top: 35.5%;
        width: 14%;

        left: 16%;
    }
    .Play_Remote {
        font-size: 17px;
        top: 4.2%;
    }
    .Right_Eye_txt,
    .Left_Eye_txt {
        font-size: 16px;
    }
    .Left_Eye_txt {
        left: 12%;
    }
    .Talkback_IA,
    .Disco_IA,
    .Gesture_IA {
        height: 42px;
        width: 42px;
    }
    .Gesture_IA {
        left: 88%;
    }
    .Disco_IA {
        left: 80.5%;
    }
    .Disco_txt {
        left: 85%;
        font-size: 11px;
        top: 46.5%;
    }
    .Buzzer_Bg {
        top: 38%;
    }
    .Buzzer_txt {
        font-size: 12px;
        top: 38.5%;
        left: 14%;
    }
    .Talkback_txt {
        font-size: 11px;
        top: 46.5%;
        left: 74.5%;
    }

    .Gesture_txt {
        left: 92%;
        top: 46.5%;
        font-size: 11px;
    }
    .Teeth_Bg {
        top: 65%;
    }

    .Teeth_Smile1,
    .Teeth_Smile2,
    .Teeth_Smile3,
    .Teeth_Smile4 {
        height: 24px;
        width: 24px;
        top: 65.5%;
    }
    .Right_Eye_Backdround,
    .Left_Eye_Backdround,
    .Left_Eye_txt,
    .Right_Eye_txt {
        top: 63%;
    }
    .Right_Eye_txt {
        left: 73%;
    }
}
