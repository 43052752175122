.wdt {
    position: relative;
    text-align: center;
    /* color: black; */
    outline: none;

    width: 95% !important;
}
.Hel {
    position: absolute;
    top: 27%;
    right: 50.3%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    width: 7%;
}
.Hel_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.Rem {
    position: absolute;
    top: 55%;
    left: 43.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    width: 6%;
}
.Rem_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.con {
    position: absolute;
    top: 53%;
    left: 62.2%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    width: 3.6%;
}
.con_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.Mus {
    position: absolute;
    top: 53%;
    left: 46.2%;
    transform: translate(-50%, -50%);
    width: 20%;
}
.Mus_P {
    border-radius: 12px;
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
}
.Cam {
    position: absolute;
    top: 53%;
    left: 55.7%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    width: 20%;
}

.img {
    width: 100%;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    bottom: 80px;
    left: -3%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -1%;
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-slide img {
    display: grayscale;
}

@media only screen and (max-device-width: 1080px) {
    .Hel {
        top: 29%;
        left: 42.2%;
    }
    .con {
        top: 58%;
        left: 61.9%;
    }
}
