.wdt {
    position: relative;
    text-align: center;
    /* color: black; */
    outline: none;

    width: 95% !important;
}
.Hel {
    position: absolute;
    top: 22%;
    right: 1.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    width: 35%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Hel_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.Rem {
    position: absolute;
    top: 51%;
    right: 35.5%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px;
  border-radius: 12px;
  height: 19%; */
    width: 34%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Rem_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.con {
    position: absolute;
    top: 51%;
    left: 49%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px;
  border-radius: 12px;
  height: 8%; */
    width: 25%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.con_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.Mus {
    position: absolute;
    top: 51.5%;
    left: 65.7%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px;
  border-radius: 12px;
  height: 11%; */
    width: 25%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Mus_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}
.Mus1 {
    position: absolute;
    top: 51.5%;
    left: 51.8%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #ffffff;
    /* padding: 14px, 28px;
  border-radius: 12px;
  height: 11%; */
    width: 25%;
    /* border: 1px solid #9b9797; */
    /* max-width: 600px; */
    /* min-width: 300px; */
}
.Mus1_P {
    background-color: white;
    padding: 6px;
    word-wrap: break-word;
    font-size: 1.8vh;
    text-align: left;
    color: #707070;
    font-family: 'Halcyon_Regular' !important;
    border-radius: 12px;
    margin-bottom: 0px;
}

.img {
    /* height: 85vh !important; */
    width: 100%;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border-radius: 10;
    outline: none;
    background: transparent;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 0.25;
    /* color: black; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots {
    position: absolute;
    bottom: 80px;
    left: -3%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-slider {
    position: relative;
    display: block;
    overflow: hidden;
    margin-top: -1%;
    /* margin-left: 7%; */
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-slide img {
    display: grayscale;
}
