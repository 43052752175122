.play-introduction {
    /* background-image: url("/imagesplay/Background.png"); */
    /* background-color: green; */
    width: 100%;
    height: 100vh;
    background-repeat: round;
    background-size: cover;
    /* background-repeat: repeat-x; */
}

/* .modelContent{
  /* z-index: 90; */
/* position:absolute; */
/* padding:0.2vw; */
/* width: 1000px; */
/* background:transparent !important; */
/* background: #FFFFFF 0% 0% no-repeat ; */
/* border: 2px solid #311B92; */
/* border-radius: 500px !important; */
/* border:3px green !impor/tant; */
/* opacity: 1; */
/* } */

/* .modelBody{
  padding:2vw;
  border: 2px solid rgb(88, 182, 0);
border-radius: 19px;
background-color: aqua;
} */

.ternPlay {
    width: auto;
    height: 40vh;
}

.description {
    font: Medium 20px Quicksand;
    letter-spacing: 0px;
    color: #311b92;
    height: 20px;
    font-weight: 640;
    font-size: 3.1vh;
    padding-top: 4%;
}
