.outertabDiv-loop {
    position: relative;
    color: #000;
    /* height: 550px;
    width: 900px; */
    /* top: 42.5%; */
    top: 46%;
    left: 52.5%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-position: -24px -30px;
    /* background-size: 950px 590px; */
    background-repeat: no-repeat;
    background-image: url('../../../../../../Assets/img/propertypanel@2x.png');
    /* border: 1px solid red; */
    height: 80vh;
    width: 64vw;
    background-size: 100% 90%;
}
