.action-contaniner {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.disabledBtn,
.enabledBtn {
    /* display: inline-flex;
	justify-content: center;
	align-items: center; */
    width: 30%;
    padding: 1rem;
    height: 50px;
    border: 0;
    /* border-radius: 1rem; */
    font-family: 'Halcyon_SemiBold' !important;
}

.actionEnableBtn {
    height: 5vh;
    background: linear-gradient(to right, #afd7ee, #c6d0d1);
    color: #ffffff;
    font-family: 'Halcyon_SemiBold' !important;
}

.actionDisabledBtn {
    height: 5vh;
    background: linear-gradient(to right, #ebeaea, #e3e3e3);
    color: black;
    font-family: 'Halcyon_SemiBold' !important;
}

.disabledBtn {
    background: linear-gradient(to right, #9f9f9f, #e3e3e3);
    color: black;
}

.enabledBtn {
    background: linear-gradient(to right, #feba45, #f97e61);
    color: #ffffff;
}
.dropdown {
    position: relative;
}

button {
    font-size: 16px;
    padding: 8px;
    border: none;
    background-color: white;
    color: black;
}

button:hover {
    background-color: gray;
    color: white;
}

.active {
    background-color: blue;
    color: white;
}

.menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    background-color: white;
}

.menu button {
    padding: 8px;
}
.dropdown-menu-wrapper {
    /* background-color: #afd7ee; */
    width: 100%;
    display: flex;
    gap: 2%;
}

.dropdown-menu-wrapper > .dropdown-menu-item-list {
    /* background-color: #fff; */
    border: 2px solid #050202;
    color: #0e0e0f;
    padding: 8px;
    font-size: 16px;
    width: 30%;
    height: 50px;
    border-radius: 1rem;
}

.dropdown-menu-wrapper > .dropdown-menu-item-list option:checked {
    color: #0e0e0f;
}

.action-button {
    border-radius: 1rem;
    width: 30%;
    height: 50px;
}
.create-action {
    background-color: linear-gradient(to right, #feba45, #f97e61);
    color: #ffffff;
}
.create-action.clicked {
    background: linear-gradient(to right, #9f9f9f, #e3e3e3);
    color: black;
}
