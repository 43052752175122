.left-spi {
    position: relative;
    top: 40px;
    right: 8px;
    display: flex;
    flex-direction: column;
    label {
        position: relative;
        right: 8px;
    }
}

.right-spi {
    position: relative;
    left: 32px;
    bottom: 24px;
    background-color: white;
    color: #311b92;
    font-weight: 600;
    border-radius: 18px;
    height: 100%;
}

.right-ic2 {
    position: relative;
    top: 1px;
    color: #311b92;
    box-sizing: border-box;
    padding: 5px;
    font-weight: 600;
    left: 12px;
}

@media (min-width: 777px) {
    .right-spi {
        left: 48px;
    }
}
