.Music-Main {
    height: 100vh;
    width: 100vw;
    /* background-image: url("../../Assets/soundwaves.png"); */
    /* background-position: center; */
    /* box-sizing: content-box; */
    /* background-size: 1218px, 990px; */
    /* background-position-x: 115%; */

    /* background-position: 50% 150%; */
    display: grid;
    /* border: 1px solid red; */
    grid-template-columns: 100%;
    grid-template-rows: 10% 80% 10%;
}
.Music_Header {
    height: 100%;
    width: 100%;
    display: grid;
    /* border: 1px solid red; */
    grid-template-columns: 7% 13% 69% 5% 6%;
    grid-template-rows: 100%;
}

.Music_Body {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 7% 17% 16%;
}
.Music_Instruc {
    height: auto;
    /* width: 33%; */
    font-size: 18px;
    font-family: 'Halcyon_Medium';
    color: #707070;
    /* margin-top: 5%; */
    /* margin-left: 30%; */
    position: absolute;
    bottom: 6%;
    /* changes start */
    left: 51%;
    transform: translate(-50%, -50%);
}
/* changes end */
/* .test {
  font-family: "Halcyon_SemiBold";
} */
.Music_Instruc2 {
    height: auto;
    /* width: 34%; */
    font-size: 18px;
    font-family: 'Halcyon_Medium';
    color: #707070;
    /* margin-top: 5%; */
    /* margin-left: 30%; */
    position: absolute;
    bottom: 6%;
    left: 49%;
    transform: translate(-50%, -50%);
}

.Piano_p {
    height: 19.8vw;
    width: 51.5vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0.1vw solid #ffbc45;
    border-radius: 0.3vw;
    box-shadow: 2vh 0.59vw 0vw #d8d9d9;
    background: #ffbc45 0% 0% no-repeat padding-box;
    z-index: 0;
}

div .li {
    padding: 0;
    list-style: none;
    position: relative;
    float: left;
    z-index: 500;
}

div .white {
    height: 16vw;
    width: 5.9vw;
    z-index: 1;
    /* border: 0.14vw solid black; */
    /* border-radius: 0 0 1vw 1vw; */
    box-shadow: 0.4vh 0.69vw 1vw #0000009e;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#fcfcfc),
        to(#fcfcfc)
    );
    background: linear-gradient(to bottom, #fcfcfc 0%, #fcfcfc 100%);
}

div .white:active {
    box-shadow: 0.2vw 0 0.2vw rgba(0, 0, 0, 0.1) inset,
        -0.5vw 0.5vw 2vw rgba(0, 0, 0, 0.2) inset, 0 0 0.3vw rgba(0, 0, 0, 0.2);
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#fcfcfc),
        to(#fcfcfc)
    );
    background: #fcfcfc;
}
div .black,
.white:hover {
    cursor: pointer;
}

.black {
    height: 7vw;
    width: 3vw;
    margin: 3vw -1.5vw !important;
    z-index: 2;
    border: 0.1vw solid #000;
    /* border-radius: 0 0 0.3vw 0.3vw; */
    /* box-shadow: 0vh 0.39vw 1vw #0000009e; */
    background: linear-gradient(45deg, #222 0%, #555 100%);
    background: #171d1a;
    pointer-events: none;
}

.black:active {
    box-shadow: -0.1vw -0.1vw 0.2vw rgba(255, 255, 255, 0.2) inset,
        0 -0.2vw 0.2vw 0.3vw rgba(0, 0, 0, 0.6) inset,
        0 0.1vw 0.2vw rgba(0, 0, 0, 0.5);
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#444),
        to(#222)
    );
    background: linear-gradient(45deg, #222 0%, rgb(43, 42, 42) 100%);
}

.b,
.c,
.e,
.f {
    margin: 3vw 0 0 0.3vw;
}

.a {
    margin: 3vw 0vh 0 1vw;
}
.d,
.g {
    margin: 3vw 0vh 0 0.3vw;
}
.h {
    margin: 3vw 0vh 0 0.3vw;
    border-radius: 0 0 1vw 1vw;
}
.as,
.bs,
.ds,
.es,
.hs {
    margin: 2vw 0 0 -0.5vw;
}

.hs {
    width: 1.5vw;
    border-bottom-left-radius: 3vw;
}

.h {
    border-bottom-right-radius: 0 !important;
}

.Piano_Big {
    height: auto;
    width: 57%;
    position: absolute;
    top: 29%;
    left: 6%;
    z-index: -1;
}
.Piano_Small {
    height: auto;
    width: 145%;
    position: absolute;
    top: 53%;
    left: -1%;
    transform: translate(-50%, -50%);
    /* z-index: -1; */
}
.Disconnected {
    height: auto;
    width: 54.5%;
    position: absolute;
    /* changes start */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border: 2px solid black; */
    /* changes end */
}
.Disconnect {
    height: auto;
    width: 54.5%;
    position: absolute;
    top: 52%;
    left: -155%;
    transform: translate(-50%, -50%);
    /* border: 1px solid red; */
}
.Music_Ace {
    height: auto;
    width: 107%;
    position: absolute;
    /* changes start */
    top: 50%;
    left: 64%;
    transform: translate(-50%, -50%);
    /* border: 1px solid blue; */
    /* changes end */
}
.Music_Ac {
    height: auto;
    width: 28%;
    position: absolute;
    top: 50%;
    left: 57%;
    transform: translate(-50%, -50%);
}
.Pianotoggle_Bg {
    height: auto;
    width: 42%;
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
}
.Piano_Keys {
    height: auto;
    width: 20%;
    cursor: pointer;
    position: absolute;
    top: 48%;
    left: 70.5%;
    transform: translate(-50%, -50%);
    /* border: 1px solid purple; */
}
.Pc_Piano {
    height: auto;
    width: 20%;
    cursor: pointer;
    position: absolute;
    /* changes start */
    bottom: 38.2%;
    right: 2.2%;
    top: 55%;
    left: 70.5%;
    transform: translate(-50%, -50%);
    /* transform: translate(-2%, -1%); */
    /* border: 1px solid red; */
    /* changes end */
}

.Musc_Slider {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0%;
    z-index: 100000;
    background: #fff;
}
.Ros {
    position: absolute;
    right: 13%;
    top: 8%;
    height: 10%;
    width: 5%;
    z-index: 100000;
}
