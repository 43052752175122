.left-flowchart {
    display: inline-block;
    width: 8.5rem;
    img {
        margin-bottom: 12%;
        width: 100%;
    }
}

.main-flowchart {
    display: flex;
    padding: 1rem;
}

.right-flowchart {
    margin-left: 12%;
    display: inline-block;
    width: 80%;
    position: absolute;
    top: 14%;
}
