.remote-container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

.remote-backbutton {
    height: 52px;
    width: 52px;
    position: absolute;
    top: 30px;
    left: 31px;
    cursor: pointer;
}

.remote-backbutton-title {
    font-size: 16px;
    position: absolute;
    top: 40px;
    left: 101px;
    font-family: halcyon-SemiBold !important;
}

.remote-helpbutton {
    height: 52px;
    width: 52px;
    position: absolute;
    top: 30px;
    right: 81px;
    cursor: pointer;
}

.remote-bluetooth-button {
    height: 52px;
    width: 80px;
    position: absolute;
    top: 30px;
    right: 0px;
    cursor: pointer;
}
.remote-zing-image {
    height: 219px;
    width: 173px;
    position: absolute;
    top: 114px;
    left: 320px;
}

.sub-container {
    /* width: 95%; */
    width: 100%;
    position: absolute;
    top: 29%;

    /* border: 1px solid green; */
    display: grid;
    /* grid-template-columns: 5% 30% 20% 45%; */
    grid-template-columns: 4.5% 28.5% 19% 43% 5%;
}

.joustick-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.zing-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.zing-container > img {
    height: 100%;
    width: 100%;
}
/* .actionBar-item-1,
.dance-item-1 {
    display: flex;
    flex-wrap: wrap;
} */

.actionBar-item-1-humanoid,
.dance-item-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}
.actionBar-item-1-hexapod {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.action-container,
.dance-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.toggle-buttonContainer {
    height: 100%;
    width: 100%;
    /* border: 1px solid green; */
    display: flex;
    justify-content: left;
    align-items: center;
}
.toggle-button-inner {
    height: 34%;
    width: 80%;
    background-color: rgb(226, 226, 226);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 50px;
    cursor: pointer;
}
.buttonActionActive,
.buttonDanceActive {
    width: 85%;
    position: absolute;
}
.buttonActionActive {
    position: absolute;
    top: 15%;
}
.buttonDanceActive {
    position: absolute;
    bottom: 4%;
}
/* ------------------------------------- */
.action-container,
.dance-container {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
/* Handle third div visibility */
.show-action {
    opacity: 1;
    transform: translateY(0);
}
.hide-action {
    opacity: 0;
    transform: translateY(-50vh);
}
/* Handle fourth div visibility */
.hide-dance {
    opacity: 0;
    transform: translateY(50vh);
}
.show-dance {
    opacity: 1;
    transform: translateY(0);
}
