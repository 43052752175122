.HeaderContainer {
    text-align: center;
    width: 100vw;
    height: 8.09vh;
    margin-bottom: 15px;
    padding-top: 0px;
}

.navbarContainer {
    /* height: 61px; */
    height: 10vh;
    width: 100%;
    background-color: #f0f7fa;
    position: relative;
    z-index: 80000;
    /* border: 5px solid blue; */
}

.navbar_content {
    position: absolute;
    width: 60%;
    left: 4.5%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    z-index: 8;
    justify-content: flex-start;
    /* border: 1px solid blue; */
}

.navbar_new {
    width: 90%;
    height: auto;
    font-size: 18px;
    /* font-family: Halcyon_SemiBold; */
    /* color: red; */
    font-family: 'Halcyon_SemiBold' !important;
}

.navbarAction {
    height: 90%;
    width: 32%;
    /* border: 1px solid red; */
    position: absolute;
    right: 0%;
    top: 13%;
    display: flex;
    justify-content: flex-end;
}

.appModeNavbarContainer {
    /* height: 61px; */
    height: 10vh;
    width: 100%;
    background-color: #f0f7fa;
    position: relative;
    /* z-index: 80000; */
    /* border: 5px solid blue; */
}

.appModeNavbar_content {
    width: 50% !important;
    left: 1.5% !important;
}

.appModeNavbar_new {
    list-style: none;
    color: #000000;
    /* font-family: Halcyon_SemiBold; */
    /* color: red; */
    font-family: 'Halcyon_SemiBold' !important;
    /* border: 1px solid lightcoral; */
}

.navbar_right {
    display: inline-block;
}

.appModeIconBtnSize {
    width: 46px;
    height: 46px;
    margin-right: 10px;
    margin-top: -6px;
}

.appModeSaveBtnSize {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-top: 8px;
    cursor: pointer;
}

.isActive {
    color: #fff;
}

@media screen and (max-width: 1200px) {
    .appModeNavbar_new {
        width: 150px !important;
    }
}
@media screen and (max-width: 1000px) {
    .appModeNavbar_new {
        width: 130px !important;
    }
}

@media (max-width: 768px) {
    .appModeNavbarContainer {
        /* height: 61px; */
        height: 10vh;
        width: 100%;
        background-color: #f0f7fa;
        /* position: relative; */
        z-index: 80000;
        /* border: 5px solid green; */
        /* top: 20px; */
    }

    .appModeNavbar_new {
        /* border: 1px solid lightcoral; */
        margin: 10px;
        padding: 20px;
        top: -11%;
        left: 0%;
        transform: translate(-0%, 11%);
    }
}
