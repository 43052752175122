.outertabDiv-output {
    position: relative;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    height: 80vh;
    width: 64vw;
    background-size: 100% 90%;
    background-repeat: no-repeat;
    background-image: url('../../../../../../Assets/img/propertypanel@2x.png');
}
.outertabDiv-outputText {
    height: 50vh;
    width: 156%;
    position: relative;
    color: black;
}

.outertabDiv-outputText > p {
    position: absolute;
    top: 75%;
    left: 32%;
    transform: translate(-50%, -50%);
    text-align: center;
}
/* .subText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.outertabDiv-output .slider-section {
    width: 71%;
    /* border: 1px solid purple; */
    /* display: grid; */
    /* grid-template-columns: 100%; */
    height: 50vh;
    position: absolute;
    left: 58%;
    top: 44%;
    transform: translate(-50%, -50%);
    /* grid-template-rows: auto; */
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding-right: 1em; */

    /* grid-row-gap: 2em; */
    /* row-gap: 3em; */
}

.waitPropertyTxt {
    position: relative;
    top: 5px;
    left: -18px;
}

.hardwareText {
    position: relative;
    left: 15px;
}

.slider-item1-flowchart {
    /* height: 10vh; */
    width: 36vw;
    display: grid;
    grid-template-columns: 23% 74%;
    grid-template-rows: 100%;
    grid-gap: 8%;
    padding-bottom: 5%;
}

.slider-item1-flowchart .portDetails-flowchart {
    height: 100px;
    width: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}

.slider-item1-flowchart .portSlider-flowchart {
    position: relative;
    display: flex;
    align-items: center;
    color: #000;
    justify-content: flex-end;
    background: #dff5f2;
    /* border-radius: 10px; */
    -webkit-user-select: none;
    user-select: none;
    padding-left: 1em;
    /* padding-top: 1em; */
    /* top: -22%; */
    /* padding-bottom: 23%;
   */
    /* padding: 0px 0 134px 10px; */
}

.checkBox-conatiner {
    /* height: 70%;
  width: 75%; */
    border-radius: 6px;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
}

.renderClickfalse {
    background-color: #e6e6e6;
}
.renderClicktrue {
    background-color: #fff;
}

.isActivePortInfofalse {
    opacity: 0.3;
}
.isActivePortInfotrue {
    opacity: 1;
}

.action-contaniner {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.disabledBtn,
.enabledBtn {
    /* display: inline-flex;
	justify-content: center;
	align-items: center; */
    width: 30%;
    padding: 1rem;
    height: 50px;
    border: 0;
    border-radius: 1rem;
    font-family: 'Halcyon_SemiBold' !important;
}

.disabledBtn {
    background: linear-gradient(to right, #9f9f9f, #e3e3e3);
}

.enabledBtn {
    background: linear-gradient(to right, #feba45, #f97e61);
    color: #ffffff;
}

.num0 {
    position: absolute;
    top: 65%;
    left: 33%;
    font-size: 16px;
}

.num1 {
    position: absolute;
    top: 62%;
    right: 21%;
    font-size: 16px;
}
