.Main_Play {
    height: 100vh;
    width: 100vw;
    /* changes start */
    position: relative;
    /* changes end */
    display: grid;
    grid-template-rows: 33% 33% 34%;
    grid-template-columns: 100%;
    /* border: 2px solid red; */
}

.Back_Btn {
    height: 60px;
    width: 60px;
    position: absolute;
    /* changes start */
    top: 5%;
    left: 2.5%;
    transform: translate(-2.5%, -4%);
    /* changes end */
    cursor: pointer;
    /* border: 1px solid rebeccapurple; */
}
.Play_Card {
    height: auto;
    width: 10%;
    /* height: auto; */
    /* width: 10%; */
    position: absolute;
    top: 3%;
    z-index: 0;
    left: 6%;
}
.Play_txxt {
    width: 45px;
    height: 20px;
    font-size: 22px;
    font-family: 'Halcyon_SemiBold' !important;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 7%;
    left: 8%;
}
.Help_Button {
    height: 60px;
    width: 60px;
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 31px;
}
.Play_Functions {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 13% 16% 3% 16% 3% 16% 3% 16% 3% 11%;
    grid-template-rows: 100%;
    /* column-gap: 5%; */
}

.Remote_Card {
    height: auto;
    width: 21%;
    position: absolute;
    top: 50%;
    /* left: 21%; */
    z-index: -1;
    cursor: pointer;
    transform: translate(-50%, -50%);
}


/* .Menu_Card {
  height: auto;
  width: 25%;
  position: absolute;
  top: 53%;
  left: 22%;
  z-index: -1;
  transform: translate(-50%, -50%);
}
.Remote_Icon {
  position: absolute;
  top: 32%;
  left: 15%;
  height: auto;
  width: 10%;
} */

.Speech_Card {
    height: auto;
    width: 21%;
    position: absolute;
    cursor: pointer;
    top: 50%;
    /* left: 40%; */
    z-index: -1;
    transform: translate(-50%, -50%);
}

/* .Speech_Icon {
  position: absolute;
  top: 32%;
  left: 35%;
  height: auto;
  width: 8%;
} */

.Music_Card {
    height: auto;
    width: 21%;
    position: absolute;
    top: 50%;
    left: 59%;
    z-index: -1;
    transform: translate(-50%, -50%);
}
.Camera_Card {
    height: auto;
    width: 21%;
    position: absolute;
    top: 50%;
    z-index: -1;
    left: 78%;
    transform: translate(-50%, -50%);
}
.Remote_txt {
    width: 62px;
    height: 25px;
    font-size: 27px;
    color: #000;
    font-family: 'Halcyon_SemiBold' !important ;
    text-align: center;
    position: absolute;
    top: 60%;
    cursor: pointer;
    left: 19%;
    transform: translate(-50%, -50%);
}
.Speech_txt {
    width: 62px;
    height: 25px;
    color: #000;
    font-family: 'Halcyon_SemiBold' !important ;
    font-size: 27px;
    text-align: center;
    position: absolute;
    top: 60%;
    cursor: pointer;
    /* left: 38%; */
    transform: translate(-50%, -50%);
}
.Music_txt {
    width: 62px;
    height: 25px;
    color: #000;
    font-size: 27px;
    text-align: center;
    font-family: 'Halcyon_SemiBold' !important ;
    position: absolute;
    top: 60%;
    cursor: pointer;
    left: 57%;
    transform: translate(-50%, -50%);
}
.Camera_txt {
    width: 62px;
    height: 25px;
    color: #000;
    font-size: 27px;
    text-align: center;
    font-family: 'Halcyon_SemiBold' !important ;
    position: absolute;
    top: 60%;
    cursor: pointer;
    left: 75.5%;
    transform: translate(-50%, -50%);
}
.IMG-slide {
    z-index: 100000;
}
.help_close {
    position: absolute;
    top: 17%;
    transform: translate(-50%, -50%);
    left: 79%;
    width: 2.5%;
    z-index: 200000;
    cursor: pointer;
}
/* .Menu_Card {
  height: auto;
  width: 26%;
  position: absolute;
  top: 53%;
  left: 22%;
  z-index: -1;
  transform: translate(-50%, -50%);
} */
/* .Remote_Gadget {
  position: absolute;
  top: 35%;
  left: 14%;
  height: auto;
  width: 12%;
} */

@media only screen and (max-device-width: 1440px) {
    .Back_Btn {
        /* height: 70px; */
        /* width: 70px; */
    }
    .Play_Card {
        /* height: auto; */
        /* top: 1.2%; */
        /* width: 12%; */
        left: 7%;
        height: 10%;
    }
    .Play_txxt {
        font-size: 24px;
        margin-top: -0.5%;
        left: 10%;
    }
    .Help_Button {
        /* height: 70px; */
        /* width: 70px; */
    }
    .Remote_txt {
        /* font-size: 22px; */
        top: 64%;
        /* left: 16.5%; */
    }
    .Speech_txt {
        /* font-size: 22px; */
        top: 64%;
        /* left: 38%; */
    }
    .Music_txt {
        /* font-size: 22px; */
        top: 64%;
        /* left: 58%; */
    }
    .Camera_txt {
        /* font-size: 22px; */
        top: 64%;
        /* left: 78%; */
    }
}
@media only screen and (max-device-width: 1699px) and (min-device-width: 1401px) {
    .Play_Card {
        /* width: 9%;
    top: 1%; */
        left: 6%;
    }
    .Play_txxt {
        font-size: 24px;
        top: 6%;
        left: 9%;
    }
    .Remote_txt {
        /* font-size: 22px; */
        top: 60%;
        /* left: 16.5%; */
    }
    .Speech_txt {
        /* font-size: 22px; */
        top: 60%;
        /* left: 38%; */
    }
    .Music_txt {
        /* font-size: 22px; */
        top: 60%;
        /* left: 58%; */
    }
    .Camera_txt {
        /* font-size: 22px; */
        top: 60%;
        /* left: 78%; */
    }
}
@media only screen and (max-device-width: 2100px) and (min-device-width: 1700px) {
    .Play_Card {
        width: 9%;
        top: 2%;
        left: 5%;
    }
    .Play_txxt {
        font-size: 25px;
        top: 5%;
        left: 7.5%;
    }
    .Remote_txt {
        /* font-size: 22px; */
        top: 65%;
        /* left: 16.5%; */
    }
    .Speech_txt {
        /* font-size: 22px; */
        top: 65%;
        /* left: 38%; */
    }
    .Music_txt {
        /* font-size: 22px; */
        top: 65%;
        /* left: 58%; */
    }
    .Camera_txt {
        /* font-size: 22px; */
        top: 65%;
        /* left: 78%; */
    }
}
@media only screen and (max-device-width: 1053px) {
    .Play_Card {
        /* width: 14%; */
        top: 4.2%;
        left: 9%;
    }
    .Play_txxt {
        font-size: 23px;
        top: 4.5%;
        left: 11.5%;
    }
}
@media only screen and (max-device-width: 1024px) {
    .Back_Btn {
        height: 60px;
        width: 60px;
    }
    .Play_Card {
        width: 14%;
        top: 2%;
        left: 9%;
    }
    .Play_txxt {
        font-size: 22px;
        margin-top: 0.7%;
        left: 12%;
    }
    .Help_Button {
        height: 60px;
        width: 60px;
    }
    .Remote_Card {
        height: 245px;
        width: 275px;
        top: 52%;
        left: 19%;
    }
    .Speech_Card {
        height: 245px;
        width: 275px;
        top: 52%;
    }
    .Music_Card {
        height: 245px;
        width: 275px;
        top: 52%;
        left: 60%;
    }
    .Camera_Card {
        height: 245px;
        width: 275px;
        top: 52%;
        left: 80%;
    }
    .Remote_txt {
        font-size: 22px;
        top: 64%;
        left: 16.5%;
    }
    .Speech_txt {
        font-size: 22px;
        top: 64%;
        left: 38%;
    }
    .Music_txt {
        font-size: 22px;
        top: 64%;
        left: 58%;
    }
    .Camera_txt {
        font-size: 22px;
        top: 64%;
        left: 78%;
    }
}

@media (max-width: 768px) {
    .Back_Btn {
        height: 55px;
        width: 55px;
    }
    .Play_Card {
        width: 16%;
        top: 3%;
        left: 11%;
    }
    .Play_txxt {
        font-size: 18px;
        margin-top: 1.5%;
        left: 14.5%;
        /* border: 1px solid red; */
    }
    .Help_Button {
        height: 55px;
        width: 55px;
    }
    .Remote_Card {
        height: 185px;
        width: 215px;
        top: 50%;
        left: 19%;
    }
    .Speech_Card {
        height: 185px;
        width: 215px;
        top: 50%;
    }
    .Music_Card {
        height: 185px;
        width: 215px;
        top: 50%;
        left: 60%;
    }
    .Camera_Card {
        height: 185px;
        width: 215px;
        top: 50%;
        left: 80%;
    }
    .Remote_txt {
        font-size: 18px;
        top: 52%;
        left: 17.5%;
    }
    .Speech_txt {
        font-size: 18px;
        top: 52%;
        left: 39%;
    }
    .Music_txt {
        font-size: 18px;
        top: 52%;
        left: 58.5%;
    }
    .Camera_txt {
        font-size: 18px;
        top: 52%;
        left: 79%;
    }
}
@media (max-width: 768px) and (max-height: 880px) {
    .Play_txxt {
        top: 4%;
    }
    .Play_Card {
        top: 2.5%;
    }
}
