* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.Digital .switch-button-true {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 151px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 120px;
    position: relative;
    margin: 10px 0px 0px 0px;
    &:before {
        content: 'Analog';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -23px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        pointer-events: none;
    }

    &-checkbox {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;

        &:checked + .switch-button-label:before {
            transform: translateX(120px);
            transition: transform 300ms linear;
        }

        & + .switch-button-label {
            position: relative;
            padding: 5px 0px;
            width: 127px;
            left: -45px;
            display: block;
            -webkit-user-select: none;
            user-select: none;
            pointer-events: none;

            &:before {
                content: '';
                background: #30a8ce;

                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 30px;
                transform: translateX(0);
                transition: transform 300ms;
            }

            .switch-button-label-span {
                position: relative;
                left: 23px;
            }
        }
    }
}
.Digital .switch-button-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 151px;
    height: 35px;
    text-align: center;

    position: relative;
    padding-right: 120px;
    position: relative;
    margin: 10px 0px 0px 0px;
    &:before {
        content: 'Analog';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -23px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        pointer-events: none;
    }

    &-checkbox {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;

        &:checked + .switch-button-label:before {
            transform: translateX(120px);
            transition: transform 300ms linear;
        }

        & + .switch-button-label {
            position: relative;
            padding: 5px 0px;
            width: 127px;
            left: -45px;
            display: block;
            -webkit-user-select: none;
            user-select: none;
            pointer-events: none;

            &:before {
                content: '';
                background: #30a8ce;

                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 30px;
                transform: translateX(0);
                transition: transform 300ms;
            }

            .switch-button-label-span {
                position: relative;
                left: 23px;
            }
        }
    }
}

.textsp {
    display: inline-block;
}
.switch-button-label-span {
    // color: #717171;
    color: #fcfcfc;
}
