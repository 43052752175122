.humanoidGridContainer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: -5%;
    padding: 60px 0 0 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 7% 14% 11% 11% 11% 14% 14%;
}

.GridRow1 {
    margin: -11px 0px 0px 174px;
}

.GridRow2 {
}
.checkBox15 {
    margin: 15px 0px 0px 97px;
}

.checkBox16 {
    margin: 15px 0px 0px 140px;
}

.checkBox8 {
    margin: 15px 0px 0px 200px;
}
.checkBox7 {
    margin: 15px 0px 0px 245px;
}

.checkBox14 {
    margin: 15px 0px 0px 80px;
}

.checkBox13 {
    margin: 15px 0px 0px 140px;
}

.checkBox5 {
    margin: 15px 0px 0px 200px;
}
.checkBox6 {
    margin: 15px 0px 0px 260px;
}

.checkBox12 {
    margin: 15px 0px 0px 140px;
}
.checkBox4 {
    margin: 15px 0px 0px 200px;
}

.checkBox11 {
    margin: 15px 0px 0px 140px;
}
.checkBox3 {
    margin: 15px 0px 0px 200px;
}

.checkBox10 {
    margin: 15px 0px 0px 140px;
}
.checkBox2 {
    margin: 15px 0px 0px 200px;
}

.checkBox9 {
    margin: 5px 0px 0px 140px;
}
.checkBox1 {
    margin: 5px 0px 0px 200px;
}

.EnableServos-Item-discription {
    grid-column: 1/3;
}

.EnableServosCamera {
    position: relative;
    height: 100%;
    width: 100%;
    margin-left: 10px;
}

.camer_option {
    height: 45%;
    width: 70%;
    margin: 35% auto;
}

#camer_option_details {
    position: absolute;
    height: 38%;
    width: 62%;
    margin: 36% 0% 0% 4%;
    top: 0%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, 50%);
}
.camer_option_details1 {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 30% 60%;
}
.camer_option_title {
    grid-column: 2;
    color: #311b92;
    font-size: 17px;
    text-align: 'center';
    align-self: center;
    font-weight: 500;
}

.camer_option_details2 {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 70% 30%;
}

.camer_option_description {
    color: #311b92;
    text-align: center;
    font-size: 16px;
    margin-top: -10px;
    font-weight: 500;
    margin-left: 0px;
}
#EnableServosSwitchBtn {
    height: 100%;
    width: 100%;
    cursor: not-allowed;
}

.OPtiontext2 {
    margin: 0px 0px 0px 0px;
}

@media (max-width: 606px) {
    .humanoidGridContainer {
        margin-top: 0px;
        height: 100%;
        width: 100%;
        position: absolute;
        top: -22%;
        left: 3px;
        padding: 60px 0 0 0;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 14% 14% 20% 14% 14% 14% 14%;
    }

    .GridRow1 {
        margin: -13px 0px 0px 80px;
    }

    .checkBox15 {
        margin: 0px 0px 0px 32px;
    }

    .checkBox16 {
        margin: 0px 0px 0px 65px;
    }

    .checkBox8 {
        margin: 0px 0px 0px 100px;
    }
    .checkBox7 {
        margin: 0px 0px 0px 130px;
    }

    .checkBox14 {
        margin: 10px 0px 0px 32px;
    }

    .checkBox13 {
        margin: 0px 0px 0px 65px;
    }

    .checkBox5 {
        margin: 0px 0px 0px 100px;
    }
    .checkBox6 {
        margin: 0px 0px 0px 130px;
    }

    .checkBox12 {
        margin: 0px 0px 0px 65px;
    }
    .checkBox4 {
        margin: 0px 0px 0px 99px;
    }

    .checkBox11 {
        margin: 10px 0px 0px 65px;
    }
    .checkBox3 {
        margin: 10px 0px 0px 99px;
    }

    .checkBox10 {
        margin: 15px 0px 0px 65px;
    }
    .checkBox2 {
        margin: 15px 0px 0px 99px;
    }

    .checkBox9 {
        margin: 15px 0px 0px 65px;
    }
    .checkBox1 {
        margin: 15px 0px 0px 99px;
    }

    .camer_option_title {
        grid-column: 2;
        color: hsla(251, 69%, 34%, 0.082);
        font-size: 8px;
        text-align: 'center';
        align-self: center;
        font-weight: 500;
    }
}

@media (max-width: 900px) and (min-width: 680px) {
    .mainImgContainer {
        /* border: 2px solid red; */
        margin-left: -50%;
    }

    .mainContainerCamera {
        position: relative;
        /* border: 2px solid red; */
        top: 0%;
        left: 15%;
    }

    .camer_option {
        width: 200px;
    }

    #camer_option_details {
        width: 200px;
    }
}

@media (max-width: 1200px) and (min-width: 900px) {
    .mainContainerCamera {
        position: relative;
        top: 0%;
        left: 15%;
    }

    .camer_option {
        width: 100%;
    }

    #camer_option_details {
        width: 200px;
    }
}
