.select-container {
  height: 100vh;
  width: 100vw;
}

.select-navbar {
  height: 10%;
  width: 100%;
  background-color: #f0f7fa;
  position: relative;
  /* border: 1px solid red; */
}

.select-item {
  height: 100%;
  width: 15%;
  /* position: absolute; */
}

.navbar-item-select {
  color: #f0f7fa;
  font-size: 20px;
  font-family: Halcyon-SemiBold !important;
  position: absolute;
  top: 5%;
  left: 5%;
  transform: translate(-5%, -5%);
}

.navbar-item-assemble {
  color: black;
  font-size: 20px;
  font-family: Halcyon-SemiBold !important;
  position: absolute;
  top: 5%;
  left: 22%;
  transform: translate(-22%, -5%);
}

.navbar-item-code {
  color: black;
  font-size: 20px;
  font-family: Halcyon-SemiBold !important ;
  position: absolute;
  top: 5%;
  left: 43%;
  transform: translate(-43%, -5%);
}
.navbar-item-simulate {
  color: black;
  font-size: 20px;
  font-family: Halcyon-SemiBold !important ;
  position: absolute;
  top: 5%;
  left: 62%;
  transform: translate(-62%, -5%);
}

.select-savebutton {
  height: 73%;
  width: 50px;
  position: absolute;
  top: 45%;
  right: 11%;
  transform: translate(-11%, -45%);
}

.select-helpbutton {
  height: 73%;
  width: 50px;
  position: absolute;
  top: 45%;
  right: 6%;
  transform: translate(-6%, -45%);
}
.select-bluetooth {
  height: 55px;
  width: 80px;
  position: absolute;
  top: 75%;
  right: 0%;
  transform: translate(-0%, -75%);
}

.body-container {
  height: 80%;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 26% 26% 5%;
}
.select-humanoid {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 45% 62%;
  background-position: center;
  position: relative;
  /* border: 1px solid purple; */
}
.select-buzzer {
  position: absolute;
  height: 20%;
  width: 50%;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
}
.select-leftEye {
  position: absolute;
  height: 14.2px;
  width: 14.2px;
  top: 22%;
  left: 46.8%;
  transform: translate((-46.8%, -22.1%));
}
.select-rightEye {
  position: absolute;
  height: 14.2px;
  width: 14.2px;
  top: 22%;
  left: 50.4%;
  transform: translate((-50.4%, -22.1%));
}
.select-popupcard1 {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: 115% 90%;
  background-position: center;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
}
.select-inputs {
  position: absolute;
  font-size: 20px;
  font-family: Halcyon-SemiBold;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -18%);
}
.sm-popupcard-button {
  height: 52px;
  width: 52px;
}

.inactive-Button-Battery {
  position: absolute;
  top: 35%;
  left: 36%;
  transform: translate(-36%, -35%);
}

.inactive-Button-Ultrasonic {
  position: absolute;
  top: 35%;
  left: 62%;
  transform: translate(-62%, -35%);
}

.inactive-Button-AccelerometerX {
  position: absolute;
  top: 52%;
  left: 25%;
  transform: translate(-25%, -52%);
}

.inactive-Button-AccelerometerY {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -52%);
}

.inactive-Button-AccelerometerZ {
  position: absolute;
  top: 52%;
  left: 75%;
  transform: translate(-75%, -52%);
}

.inactive-Button-GyroX {
  position: absolute;
  top: 70%;
  left: 25%;
  transform: translate(-25%, -70%);
}
.inactive-Button-GyroY {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
}

.inactive-Button-GyroZ {
  position: absolute;
  top: 70%;
  left: 75%;
  transform: translate(-75%, -70%);
}

.select-Outputs {
  position: absolute;
  font-size: 20px;
  font-family: Halcyon-SemiBold;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -18%);
}
.inactive-Button-LeftRGB {
  position: absolute;
  top: 35%;
  left: 25%;
  transform: translate(-25%, -35%);
}

.inactive-Button-RightRGB {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
}

.inactive-Button-Mp3 {
  position: absolute;
  top: 35%;
  left: 75%;
  transform: translate(-75%, -35%);
}

.inactive-Button-Individual {
  position: absolute;
  top: 52%;
  left: 25%;
  transform: translate(-25%, -52%);
}

.inactive-Button-ActionGroup {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -52%);
}

.inactive-Button-HeadServo {
  position: absolute;
  top: 52%;
  left: 75%;
  transform: translate(-75%, -52%);
}
.select-bottom {
  height: 10%;
  width: 100%;
  position: relative;
  /* border: 1px solid black; */
}
.select-backbutton {
  height: 82px;
  width: 82px;
  position: absolute;
  bottom: 3%;
  left: 3%;
  transform: translate((-3%, -3%));
  cursor: pointer;
}

.select-description {
  position: absolute;
  font-size: 18px;
  font-family: Halcyon-SemiBold;
  color: #707070;
  top: -30%;
  left: 50%;
  transform: translate(-50%, 0%);
  /* border: 1px solid red; */
}

.select-nextbutton {
  height: 82px;
  width: 82px;
  position: absolute;
  bottom: 3%;
  right: 3%;
  transform: translate((-3%, -3%));
  cursor: pointer;
}
.toast {
  min-width: 150px;
  /* margin-left: -125px; */
  background-color: #d2d2d2;
  color: #000;
  text-align: center;
  border-radius: 25px;
  font-family: "Halcyon_Regular" !important;
  padding: 16px;
  position: absolute;
  z-index: 5;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
}
.toast-active {
  display: block;
}
.toast-inactive {
  display: none;
}

@media (max-width: 974px) {
  .select-bluetooth {
    height: 80%;
    width: 100px;
  }
  .select-savebutton {
    margin-right: 65px;
  }
  .select-helpbutton {
    margin-right: 46px;
  }
  .sm-popupcard-button {
    height: 40px;
    width: 40px;
  }
  .select-description {
    margin-left: 1%;
  }

  .select-humanoid {
    height: 100%;
    width: 110%;
  }
  .navbar-item-select,
  .navbar-item-assemble,
  .navbar-item-code,
  .navbar-item-simulate {
    margin-top: 2%;
    /* border: 1px solid red; */
    font-size: 17px;
  }
  .select-popupcard1 {
    height: 95%;
    width: 100%;
  }
  .select-inputs,
  .select-Outputs {
    font-size: 19px;
  }
  .select-backbutton,
  .select-nextbutton {
    height: 72px;
    width: 72px;
  }
}
@media (min-width: 975px) and (max-width: 1224px) {
  .select-bluetooth {
    height: 60px;
    width: 100px;
  }
  .select-helpbutton {
    margin-right: 42px;
  }
  .select-savebutton {
    margin-right: 46px;
  }
  .navbar-item-assemble,
  .navbar-item-code,
  .navbar-item-select,
  .navbar-item-simulate {
    font-size: 23px;
    margin-top: 2%;
  }
  .select-humanoid {
    height: 100%;
    width: 110%;
  }
  .select-popupcard1 {
    height: 95%;
    width: 100%;
  }
  .select-inputs,
  .select-Outputs {
    font-size: 22px;
  }
}
