.switch-button-true {
    opacity: 1;
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 151px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 120px;
    position: relative;
    margin: 7px 0px 0px 0px;
}
.switch-button-true::before {
    content: 'Analog';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -23px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    border: '2px solid red';
    color: white;
}

.switch-button-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 151px;
    height: 35px;
    text-align: center;
    position: relative;
    padding-right: 120px;
    margin: 7px 0px 0px 0px;
}

.switch-button-false::before {
    content: 'Analog';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -23px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    color: #717171;
}

@media only screen and (max-width: 2500px) and (min-width: 1400px) {
    .switch-button-true {
        opacity: 1;
        display: inline-block;
        background: #e6e6e6;
        border-radius: 30px;
        overflow: hidden;
        width: 180px;
        height: 54px;
        text-align: center;
        color: #717171;
        position: relative;
        padding-right: 120px;
        position: relative;
        margin: 10px 0px 0px 0px;
    }

    .switch-button-false {
        opacity: 1;
        display: inline-block;
        background: #e6e6e6;
        border-radius: 30px;
        overflow: hidden;
        width: 180px;
        height: 54px;
        text-align: center;
        color: #717171;
        position: relative;
        padding-right: 120px;
        position: relative;
        margin: 10px 0px 0px 0px;
    }

    .switch-button-true > label {
        position: relative;
        padding: 15px 0px !important;
        width: 135px !important;
        left: -45px;
        display: block;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
    }

    .switch-button-false > label {
        position: relative;
        padding: 15px 0px !important;
        width: 135px !important;
        left: -45px;
        display: block;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
    }
}
