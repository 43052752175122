.Main_Container {
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 15% 5% 80%;
    grid-template-rows: 80% 20%;
    grid-template-areas:
        'sidebar arrowBtn body'
        'sidebar arrowBtn bottom';
}

.Sensor_Body {
    display: flex;
    height: 80%;
    width: 100%;
}
.Sensors_Container {
    /* border: 2px solid red; */
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 17% 16.5% 16.5% 16.5% 16.5% 16.5%;
    height: 100%;
    width: 100%;
    position: relative;
    top: -8%;
}
.Sensors_Container > div > img {
    width: 35vh;
}
.time_headers{
    position: absolute;
    top: 28%;
    left: 1%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.time_headers p{
    width: 15%;
    text-align: center;
}

.Container {
    position: relative;
}
.sub_Container {
    height: 54%;
    width: 67%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
.sub_Container p {
    pointer-events: none;
}
.Container > div {
}
.Container > div > p {
    font-size: 2.5vh;
    position: absolute;
    top: 55%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 72%;
    word-wrap: break-word;
    hyphens: auto;
}

.Ports_Container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 33% 33% 33%;
    height: 100%;
    width: 100%;
}

.Ports_Container > div > img {
    width: 40vh;
}

#sidebar {
    grid-area: sidebar;
    z-index: 2;
    overflow-y: scroll;
    /* display: grid;
    grid-template-columns: 100%; */
}
#head {
    border: '2px solid blue';
}

.sidebarImg {
    position: absolute;
    /* left: 50%; */
    left: 35%;
    transform: translate(-50%, 0);
}
.sidebarImg > img {
    width: 151%;
}
.sidebarText {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg1 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 80%);
}
.sidebarImg1 > img {
    width: 151%;
}
.sidebarText1 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText1 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon1 {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon1 > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg2 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 160%);
}
.sidebarImg2 > img {
    width: 151%;
}
.sidebarText2 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText2 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon2 {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon2 > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg3 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 240%);
}
.sidebarImg3 > img {
    width: 151%;
}
.sidebarText3 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText3 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon3 {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon3 > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg4 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 320%);
}
.sidebarImg4 > img {
    width: 151%;
}
.sidebarText4 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText4 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon4 {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon4 > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg5 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 400%);
}
.sidebarImg5 > img {
    width: 151%;
}
.sidebarText5 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText5 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon5 {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon5 > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg6 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 480%);
}
.sidebarImg6 > img {
    width: 151%;
}
.sidebarText6 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText6 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarIcon6 {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: -21%;
}
.sidebarIcon6 > img {
    width: 1.5vh;
    margin-bottom: 0% !important;
}

.sidebarImg7 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 560%);
}
.sidebarImg7 > img {
    width: 151%;
}
.sidebarText7 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText7 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg8 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 640%);
}
.sidebarImg8 > img {
    width: 151%;
}
.sidebarText8 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText8 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg9 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 720%);
}
.sidebarImg9 > img {
    width: 151%;
}
.sidebarText9 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText9 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg10 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 800%);
}
.sidebarImg10 > img {
    width: 151%;
}
.sidebarText10 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText10 > p {
    font-size: 2vh;
    margin-bottom: 0% !important;
}

.sidebarImg11 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 880%);
}
.sidebarImg11 > img {
    width: 151%;
}
.sidebarText11 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText11 > p {
    font-size: 1.9vh;
    margin-bottom: 0% !important;
}

.sidebarImg12 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 960%);
}
.sidebarImg12 > img {
    width: 151%;
}
.sidebarText12 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText12 > p {
    font-size: 1.9vh;
    margin-bottom: 0% !important;
}

.sidebarImg13 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1040%);
}
.sidebarImg13 > img {
    width: 151%;
}
.sidebarText13 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText13 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg14 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1120%);
}
.sidebarImg14 > img {
    width: 151%;
}
.sidebarText14 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText14 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg15 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1200%);
}
.sidebarImg15 > img {
    width: 151%;
}
.sidebarText15 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText15 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg16 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1280%);
}
.sidebarImg16 > img {
    width: 151%;
}
.sidebarText16 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText16 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg17 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1360%);
}
.sidebarImg17 > img {
    width: 151%;
}
.sidebarText17 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText17 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg18 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1440%);
}
.sidebarImg18 > img {
    width: 151%;
}
.sidebarText18 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText18 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg19 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1520%);
}
.sidebarImg19 > img {
    width: 151%;
}
.sidebarText19 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText19 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg20 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1600%);
}
.sidebarImg20 > img {
    width: 151%;
}
.sidebarText20 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText20 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg21 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1680%);
}
.sidebarImg21 > img {
    width: 151%;
}
.sidebarText21 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText21 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg22 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1760%);
}
.sidebarImg22 > img {
    width: 151%;
}
.sidebarText22 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText22 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg23 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1840%);
}
.sidebarImg23 > img {
    width: 151%;
}
.sidebarText23 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText23 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg24 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 1920%);
}
.sidebarImg24 > img {
    width: 151%;
}
.sidebarText24 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText24 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg25 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 2000%);
}
.sidebarImg25 > img {
    width: 151%;
}
.sidebarText25 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText25 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg26 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 2080%);
}
.sidebarImg26 > img {
    width: 151%;
}
.sidebarText26 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText26 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}

.sidebarImg27 {
    position: absolute;
    /* left: 50%; */

    left: 35%;
    transform: translate(-50%, 2160%);
}
.sidebarImg27 > img {
    width: 151%;
}
.sidebarText27 {
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%);
    left: 75%;
}
.sidebarText27 > p {
    font-size: 2.2vh;
    margin-bottom: 0% !important;
}
#arrowBtn {
    grid-area: arrowBtn;
    z-index: 2;
}

#body {
    grid-area: body;
    transform: translate(-9%, 16%);
    z-index: 1;
    position: relative;
    /* overflow: visible; */
    /* display: flex; */
    /* flex-direction: row; */
}

/* .graph > .graphWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: scroll;
} */

/* .graphWrapper > .yAxisWrapper {
    background: white;
    position: sticky;
    left: 0;
    z-index: 3;
} */

#bottom {
    grid-area: bottom;
    transform: translateY(104%) translateX(-5%);
}
.sub_Bottom {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1%;
    transform: translateX(-1%);
}

.sub_Bottom2 {
    position: absolute;
    right: 0%;
    top: 5vh;
    width: 55px;
    height: 55px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.sub_Bottom1 {
    position: absolute;
    right: 0%;
    top: 5vh;
    width: 55px;
    height: 55px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

/* .recharts-wrapper {
    width: 50vh;
    border: '3px solid blue';
} */

.helpScreen {
    height: 100vh;
    width: 100vw;
    background: rgb(255, 255, 255);
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
.help {
    position: relative;
    transform: translate(-50%, -50%);
    border-radius: 7px;
    z-index: 1000;
}

.help_text {
    background-color: white;
    padding: 2px;
    font-size: 1.8vh;
    text-align: left;
    color: rgb(112, 112, 112);
    font-family: Halcyon_Regular;
    border-radius: 12px;
    margin-bottom: 0px !important;
}
