.PrevBtnBtnContainer {
    height: 6vh;
    width: 8vw;
    border-radius: 50px;
    border: 2px solid black;
    background-color: #311b92;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    position: absolute;
    bottom: 4vh;
    left: 2vw;
    cursor: pointer;
}

.nextBtnBtnContainer {
    height: 6vh;
    width: 8vw;
    border-radius: 50px;
    border: 2px solid black;
    background-color: #311b92;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    position: absolute;
    bottom: 4vh;
    right: 5vw;
    cursor: pointer;
}

@media (max-width: 644px) {
    .PrevBtnBtnContainer {
        right: 70%;
        padding: 3px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .nextBtnBtnContainer {
        left: 70%;
        padding: 3px;
        padding-right: 30px;
        padding-left: 30px;
    }
}
