.colorFaceTrainBody {
    display: flex;
    /* gap: 10vw; */
}

.trainSidebar {
    /* margin-top: 10vh; */
    width: 20%;
    background-color: rgb(241, 241, 241);
    /* height: 68vh; */
    /* border: 1px solid green; */
}

.tranColorFaceListDiv {
    margin-top: 30%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    /* height: 57vh; */
    gap: 20px;
    /* border: 1px solid red; */
}
.tranColorFaceListDiv::-webkit-scrollbar {
    display: none;
}

.mainBody {
    display: flex;
    width: 80%;
    height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.trainControlDiv {
    /* background-image: url('../../../Assets/appModeAi/colorInputBackground.svg');
    background-size: 112% 102%;
    background-repeat: no-repeat; */
    /* height: 58vh;
    width: 29vw; */
    height: 350px;
    width: 400px;
    /* margin-top: 10vh; */
    border: 3px solid white;
    border-radius: 30px;
    background-color: rgb(242, 242, 242);
    box-shadow: 9px 11px 35px -23px rgba(0, 0, 0, 0.45);
}
.trainColorControl_1_Div {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    /* padding-top: 15%;
    padding-left: 17%; */
}
.colorTrainInputs {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 2vw;
    margin-top: 40px;
}

.colorTrainInputNum {
    width: 70px;
    height: 35px;
    border-radius: 15px;
    border: 1px solid #717171;
    text-align: center;
    font-family: Halcyon_SemiBold;
}
.colorTrainInputColor {
    width: 200px;
}

.faceTrainDiv {
    height: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.faceTrainDiv p {
    margin-left: 10%;
    margin-bottom: 0px;
}
.faceTrainDiv input {
    margin-left: 10%;
    width: 200px;
    height: 35px;
    border-radius: 10px;
    padding-left: 10px;
    border: 1px solid #717171;
}
.faceTrainDiv img {
    margin-left: 10%;
    margin-top: 20%;
}
