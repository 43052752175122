.Arm_Body_Klaw {
    /* height: 100vh; */
    width: 100vw;
    height: 75vh;
    /* display: grid;
    grid-template-columns: 35% 35% 30%;
    grid-template-rows: 100%; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    gap: 20%;
}

.Arm_Left_Klaw {
    position: relative;
    width: 20%;
    /* position: relative; */
    left: 7.5%;
    top: -4%;
}
.Arm_Left_Klaw img {
    width: 100%;
}

.label-grip {
    position: absolute;
    top: 29%;
    right: 66%;
    width: 50%;
    height: fit-content;
}
.label-base {
    position: absolute;
    bottom: 32%;
    left: 68%;
    right: 2%;
    width: 60%;
    height: fit-content;
}
.label-servo3 {
    position: absolute;
    top: 24%;
    left: 53%;
    width: 50%;
    height: fit-content;
}
.label-servo2 {
    position: absolute;
    top: 40%;
    left: 76%;
    width: 50%;
    height: fit-content;
}
.label-servo1 {
    position: absolute;
    top: 47%;
    left: 3%;
    width: 50%;
    height: fit-content;
}

.grip,
.base,
.servo1,
.servo2,
.servo3 {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: black;
}

.label-grip p,
.label-base p {
    width: 100%;
    text-align: right;
    margin-top: 5px;
}
.label-servo1 p,
.label-servo2 p,
.label-servo3 p {
    width: 100%;
    text-align: left;
    margin-top: 5px;
}
.Arm_Main_Klaw {
    width: 50%;
    display: grid;
    height: fit-content;
    /* flex-wrap: wrap; */
    /* align-self: center;
    justify-self: center; */
    /* grid-template-rows: 20% 20%; */
    grid-template-columns: 33.33% 33.33% 33.33%;
    /* margin-top: 45%; */
    /* row-gap: 8%; */
    column-gap: 3%;
    position: relative;
    left: 7%;
    top: 40%;
}

.Main_Img_Klaw {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    justify-self: end;
    align-self: end;
    cursor: pointer;
    width: 75%;
}

.Main_Img_Txt {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    justify-self: end;
    cursor: pointer;
    width: 75%;
}
.Main_Img_Txt img,
.Main_Img_Klaw img {
    width: 100%;
}
.Arm_Right_Main {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    /* top: 15%; */
}
.Arm_Right_Klaw {
    width: 100%;
    display: grid;
    height: fit-content;
    grid-template-rows: 30% 30% 20%;
    grid-template-columns: 23% 23%;
    /* margin-left: 10%; */
    /* margin-top: 45%; */
    row-gap: 8%;
    column-gap: 2%;
}
.base-servo {
    display: block;
    margin: 0;
    /* position: fixed;
    right: 20rem; */
    white-space: nowrap;
    width: 55%;
    text-align: center;
    position: relative;
    bottom: 1rem;
}
@media screen and (min-width: 1024px) {
    .base-servo {
        width: 55%;
    }
}
@media screen and (min-width: 1440px) {
    .Main_Img_Klaw,
    .Main_Img_Txt {
        width: 65%;
    }

    .base-servo {
        width: 57%;
        /* top: 35%; */
    }
}
@media screen and (min-width: 1700px) {
    .Main_Img_Klaw,
    .Main_Img_Txt {
        width: 55%;
    }

    .base-servo {
        width: 60%;
        /* top: 20%; */
    }
}
