/* true */
.flowchart-switch-button-true {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    text-align: center;
    color: #717171 !important;
    position: relative;
    right: 8%;
    bottom: -20px;
    opacity: 1;
    width: 210px;
    height: 35px;
    text-align: center;
    position: relative;
    position: relative;
    margin: 10px 0px 0px 0px;
}

.flowchart-switch-button-true input {
    position: absolute;
    opacity: 0;
}
.flowchart-switch-button-true input + label {
    float: left;
    cursor: pointer;
    margin: 0;
    position: relative;
    padding: 5px 0px;
    text-align: end;
    width: 65px;
    display: inline-block;
}
/* .flowchart-switch-button-true input:checked + label {
     background: #30a8ce;
     transition: smooth 300ms;
    } */
#digital-slider-A1,
#digital-slider-A2,
#digital-slider-B1,
#digital-slider-B2,
#digital-slider-C1,
#digital-slider-C2,
#digital-slider-D1,
#digital-slider-D2 {
    background: #30a8ce;
    border-radius: 30px;
    height: 100%;
    width: 35%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(0px);
    transition: transform 200ms linear;
}
.slide-A1-digital,
.slide-A2-digital,
.slide-B1-digital,
.slide-B2-digital,
.slide-C1-digital,
.slide-C2-digital,
.slide-D1-digital,
.slide-D2-digital {
    transform: translateX(0px) !important;
}
.slide-A1-analog,
.slide-A2-analog,
.slide-B1-analog,
.slide-B2-analog,
.slide-C1-analog,
.slide-C2-analog,
.slide-D1-analog,
.slide-D2-analog {
    transform: translateX(75px) !important;
}
.slide-A1-servo,
.slide-A2-servo,
.slide-B1-servo,
.slide-B2-servo,
.slide-C1-servo,
.slide-C2-servo,
.slide-D1-servo,
.slide-D2-servo,
.slide-M3-mp3,
.slide-M4-serialServo {
    transform: translateX(145px) !important;
}

/* .flowchart-switch-button-label-span{
      position: relative;
      left: 23px;
    } */

/* false */
.flowchart-switch-button-false {
    opacity: 0.3;
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    text-align: center;
    color: #717171 !important;
    position: relative;
    right: 8%;
    bottom: -20px;
    width: max-content;
    height: 35px;
    text-align: center;
    position: relative;
    position: relative;
    margin: 10px 0px 0px 0px;
    cursor: not-allowed;
}
.flowchart-switch-button-false input {
    position: absolute;
    opacity: 0;
    cursor: not-allowed;
}
.flowchart-switch-button-false input + label {
    cursor: pointer;
    margin: 0;
    position: relative;
    padding: 5px 0px;
    text-align: initial;
    width: 75px;
    display: inline-block;
    cursor: not-allowed;
}

/* ================================================================= */

/* Port Inputs Digital css*/

.MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    @media (max-width: 800px) {
        transform: scale(0.96);
        left: 2%;
    }
    @media (max-width: 700px) {
        left: 0;
    }
    @media (max-width: 624px) {
        transform: scale(0.9);
    }
    @media (max-width: 584px) {
        transform: scale(0.8);
    }
    @media (max-width: 500px) {
        transform: scale(0.7);
    }
    @media (max-width: 424px) {
        transform: scale(0.65);
    }
    @media (max-width: 388px) {
        transform: scale(0.55);
        bottom: 80px;
    }
    @media (max-width: 320px) {
        transform: scale(0.5);
    }
    @media (min-height: 900px) {
        transform: scale(1);
        top: 150px;
    }
    @media (max-height: 670px) {
        @media (max-height: 400px) {
            transform: scale(0.7);
            bottom: 50px;
        }
    }
}
.MainContainerInput {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    overflow: hidden;
    /* @media (max-width: 800px) {
        transform: scale(0.96);
        left: 2%;
    }
    @media (max-width: 700px) {
        left: 0;
    }
    @media (max-width: 624px) {
        transform: scale(0.9);
    }
    @media (max-width: 584px) {
        transform: scale(0.8);
    }
    @media (max-width: 500px) {
        transform: scale(0.7);
    }
    @media (max-width: 424px) {
        transform: scale(0.65);
    }
    @media (max-width: 388px) {
        transform: scale(0.55);
        bottom: 80px;
    }
    @media (max-width: 320px) {
        transform: scale(0.5);
    }
    @media (min-height: 900px) {
        transform: scale(1);
        top: 150px;
    }
    @media (max-height: 670px) {
        @media (max-height: 400px) {
            transform: scale(0.7);
            bottom: 50px;
        }
    } */
}
/*  PORT */
.ports-Container {
    display: flex;
    position: relative;
    background-color: #eee;
    width: 100%;
    left: 10%;
    height: 100%;
    overflow: scroll;
}

.new-ports-Container,
.IO-ports-Container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #eee;

    width: 60%;
    height: 90%;
    margin-left: auto;
    margin-bottom: auto;
    padding: 13rem 10rem;

    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.port-btn-grp {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    padding: 0.75rem 1.5rem;
    border-radius: 50px;
    border: 2px solid white;
    background: linear-gradient(to left, white, #f5f5f5);
    box-shadow: 0 0 40px #eaeaea;
}
.port-btn {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    border: none;
    background: linear-gradient(to top left, #dcdcdc, #d0d0d0);
}

.port-btn.active {
    color: white;
    background: linear-gradient(to right, #fda84e, #f97d62);
}

.IO-ports-Container {
    padding: 10rem 5rem;
}

.IO-btn-grp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 1.5rem;
    border-radius: 50px;
    border: 2px solid white;
    background: linear-gradient(to left, white, #f5f5f5);
    box-shadow: 0 0 40px #eaeaea;
}

.IO-btn-grp2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    padding: 0.5rem 1.5rem;
}

.IO-input-btn,
.IO-output-btn {
    border: none;
    padding: 0.5rem 1rem;
}

.IO-input-btn {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}

.IO-output-btn {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
}

.properties-Container {
    position: relative;
    flex: 20%;
    top: 15%;
    right: 8%;
}
.properties-b {
    width: 10em;
    height: 22em;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-size: 400px 400px;
    background-position-x: -99px;
    /* background-image: url('../../Assets/properties small bg.svg'); */
    opacity: 1;
}
.properties-bIn {
    display: flex;
    font-size: 0;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    top: 22%;
    left: 39%;
    width: 100%;
}
.properties-InputLabel {
    color: white;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.properties-InputCheckBox {
    appearance: none;
    width: 70px;
    height: 70px;
    background-size: 100%;
    /* background-image: url('../../Assets/a1.svg'); */
    background-repeat: no-repeat;
    position: relative;
}
.properties-InputCheckBox:checked {
    width: 70px;
    height: 70px;
    background-size: 100%;
    /* background-image: url('../../Assets/a1.svg'); */
    background-repeat: no-repeat;
    color: white;
    position: relative;
}
.ButtonBackground {
    border: 2px solid #311b92;
    padding: 10px;
    margin: 12px;
    border-radius: 35px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}
/* .ButtonBackground .im {
      border: 2px solid #311b92;
      padding: 10px;
      margin: 12px;
      border-radius: 35px;
      display: flex;
      justify-content: space-around;
      background: url("./assets/img/popup card.png");
      width: 100%;
      position: relative;
      left: 0;
      top: 0;
    } */

.ButtonDiv {
    position: absolute;
    z-index: 99;
    left: 17%;
}

.ButtonRightDiv {
    position: absolute;
    z-index: 99;
    left: 46%;
}

.CenterImgs {
    display: flex;
    width: 25%;
    position: relative;
}
.iconBtnSize {
    height: 60px;
    width: 60px;
}
.InputLabel {
    color: white;
}
.InputLabel-true {
    opacity: 0.3;
}

.InputCheckBox {
    appearance: none;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    /* background-image: url('../../Assets/img/a1@3x.png'); */
    position: relative;
    background-size: 60px 60px;
    cursor: pointer;
}
.InputCheckBox:checked {
    width: 60px;
    height: 60px;
    /* background-image: url('../../Assets/img/a-1@3x.png'); */
    background-repeat: no-repeat;
    color: white;
    position: relative;
    background-size: 60px 60px;
}

.A1 {
    position: relative;
    top: -1.5em;
    right: 25%;
    pointer-events: none;
}
.BottomContainer {
    border: 1px solid blue;
    border-radius: 15px;
    padding: 15px;
    width: 75%;
    margin-left: 12.5%;
    @media (max-width: 450px) {
        width: 90%;
        margin-left: 0.5%;
    }
}

.SwitchButton {
    position: relative;
    top: 8px;
}
.tlb {
    /* background-image: url('../../Assets/img/popup card@2x.png'); */
    opacity: 1;
    position: relative;
    margin-top: 7%;
    width: 200pt;
    background-size: 200pt 130pt;
    height: 100pt;
    background-repeat: no-repeat;
    background-position-y: center;
    opacity: 1;
}
.tlbIn {
    position: relative;
    left: 25%;
    top: 28%;
    display: inline-block;
}

.textsp {
    position: relative;
    top: -1vh;
    left: -1vw;
}
.upper-label-input {
    position: relative;
    width: 0px;
    height: 0px;
    left: 0vw;
    top: -1vh;
}
label {
    display: inline;
}
.hid1 {
    position: relative;
    left: -1.8vw;
    top: 0.5vh;
}
.ends {
    position: relative;
    top: 48vh;
    font-size: 12px;
    font-weight: normal;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans;
}
.last-item {
    position: relative;
    left: 45.5vw;
    top: -7vh;
}
.left {
    position: relative;
    left: 20.6vw;
    top: 1vh;
}
.left-n {
    position: relative;
    left: 80.6vw;
    top: 1vh;
}
.CenterImgs {
    display: flex;
    position: relative;
    /* left: 8em; */
}
/* ---------------------------------------------------------------- */

/* INPUT  */

.ButtonDivInput {
    position: relative;
    left: 15%;
    top: 4%;
}
.ButtonRightDivInput {
    position: relative;
    left: 20%;
    top: 4%;
}

.Inputs-flow-left-upper {
    /* background-image: url('../../Assets/img/popup card@2x.png'); */
    width: 270pt;
    margin-top: 5%;
    background-repeat: no-repeat;
    background-size: 280pt 186pt;
    background-position: center;
    height: 110pt;
}
.Inputs-flow-left-upper-ultrasonic {
    width: 260pt;
    height: 130pt;
    margin-top: 5%;
    /* background-image: url('../../Assets/popup\ card-5.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 140%;
}
.Inputs-flow-left-upper-rgb {
    width: 260pt;
    height: 130pt;
    margin-top: 5%;
    /* background-image: url('../../Assets/popup\ card-5.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 140%;
}
.Inputs-flow-left-upper-mp3 {
    width: 260pt;
    height: 130pt;
    margin-top: 5%;
    /* background-image: url('../../Assets/popup\ card-5.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 140%;
}
.Inputs-flow-left-upper-grp {
    position: relative;
    top: 20%;
    left: 30%;
}
.Inputs-flow-left-upper-oled {
    width: 260pt;
    height: 130pt;
    margin-top: 5%;
    /* background-image: url('../../Assets/popup\ card-5.png'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 140%;
}
.Inputs-ports-Container {
    display: flex;
    position: relative;
    background-color: #eee;
    width: 100%;
    left: 10%;
    height: 90vh;
    margin-top: -10vh;
    overflow-y: auto;
}
.Inputs-properties-Container {
    position: relative;
    top: 15%;
    right: 8%;
}
.Inputs-properties-b {
    width: 10em;
    height: 22em;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-size: 400px 400px;
    background-position-x: -99px;
    /* background-image: url('../../Assets/properties small bg.svg'); */
    opacity: 1;
}
.Inputs-properties-bIn {
    display: flex;
    font-size: 0;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    top: 22%;
    left: 39%;
    width: 100%;
}
.Inputs-properties-InputLabel {
    color: white;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.Inputs-properties-InputCheckBox {
    appearance: none;
    width: 70px;
    height: 70px;
    background-size: 100%;
    /* background-image: url('../../Assets/a1.svg'); */
    background-repeat: no-repeat;
    position: relative;
}
.Inputs-properties-InputCheckBox:checked {
    width: 70px;
    height: 70px;
    background-size: 100%;
    /* background-image: url('../../Assets/a1.svg'); */
    background-repeat: no-repeat;
    color: white;
    position: relative;
}

.switch-button-ultrasonic-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 151px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 120px;
    position: relative;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
}
.switch-button-ultrasonic-false::before {
    content: 'Ultrasonic';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 12%;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    font-family: 'Halcyon_Regular';
}
.switch-button-ultrasonic-true::before {
    content: 'Ultrasonic';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    border-radius: 30px;
    overflow: hidden;
    width: 150px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-ultrasonic-true {
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 150px;
    height: 35px;
    text-align: center;
    background-color: #30a8ce;
    color: #000000;
    position: relative;
    padding-right: 120px;
    cursor: pointer;
    position: relative;
    margin: 10px 0px 0px 0px;
}
.switch-button-checkbox-ultrasonic {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.upper-label-input-ultrasonic {
    left: 2vh !important ;
}
.Inputs-flow-left-upper-grp-ultrasonic {
    position: relative;
    top: 10%;
    left: 30%;
}
.ultrasonic-disabled-true {
    /* background-color: #e6e6e6 !important; */
    opacity: 0.3;
}

.switch-button-rgb-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 157px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 90px;
    position: relative;
    margin: 10px 0px 0px 40px;
    cursor: pointer;
}
.switch-button-rgb-false::before {
    content: 'RGB';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 50%;
    width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    font-family: 'Halcyon_Regular';
}
.switch-button-rgb-true::before {
    content: 'RGB';
    position: absolute;
    top: 0;
    bottom: 0;
    /* right: 0px; */
    left: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    border-radius: 30px;
    overflow: hidden;
    width: 31px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-rgb-true {
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 157px;
    height: 35px;
    text-align: center;
    background-color: #30a8ce;
    color: #000000;
    position: relative;
    padding-right: 90px;
    position: relative;
    margin: 10px 0px 0px 40px;
    cursor: pointer;
}
.switch-button-checkbox-rgb {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.upper-label-input-rgb {
    left: -3vh !important ;
}
.Inputs-flow-left-upper-grp-rgb {
    position: relative;
    top: 10%;
    left: 30%;
}
.rgb-disabled-true {
    /* background-color: #e6e6e6 !important; */
    opacity: 0.3;
}

.switch-button-mp3-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 0px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 90px;
    position: relative;
    margin: 18px 0px 0px 10px;
    cursor: pointer;
}
.switch-button-mp3-false::before {
    content: 'MP3';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 50%;
    width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    font-family: 'Halcyon_Regular';
}
.switch-button-mp3-true::before {
    content: 'MP3';
    position: absolute;
    top: 0;
    bottom: 0;
    /* right: 0px; */
    left: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    border-radius: 30px;
    overflow: hidden;
    /* width: 31px; */
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-mp3-true {
    display: inline-block;

    border-radius: 30px;
    overflow: hidden;
    width: 0px;
    height: 35px;
    text-align: center;
    background-color: #30a8ce;
    color: #000000;
    position: relative;
    padding-right: 90px;
    position: relative;
    margin: 18px 0px 0px 10px;
    cursor: pointer;
}
.switch-button-checkbox-mp3 {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 2;
}
/* .upper-label-input-mp3 {
    left: -3vh !important ;
} */
.Inputs-flow-left-upper-grp-mp3 {
    position: relative;
    top: 10%;
    left: 30%;
}
.mp3-disabled-true {
    background-color: #e6e6e6 !important;
    opacity: 1;
}

.switch-button-serialServo-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 0px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 100px;
    position: relative;
    margin: 18px 0px 0px 2px;
    cursor: pointer;
}
.switch-button-serialServo-false::before {
    content: 'serialServo';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 52%;
    width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    font-family: 'Halcyon_Regular';
}
.switch-button-serialServo-true::before {
    content: 'serialServo';
    position: absolute;
    top: 0;
    bottom: 0;
    /* width: 100%; */
    /* right: 0px; */
    left: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    border-radius: 30px;
    overflow: hidden;
    /* width: 31px; */
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-serialServo-true {
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 0px;
    height: 35px;
    text-align: center;
    background-color: #30a8ce;
    color: #000000;
    position: relative;
    padding-right: 100px;
    position: relative;
    margin: 18px 0px 0px 2px;
    cursor: pointer;
}
.switch-button-checkbox-serialServo {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.upper-label-input-serialServo {
    left: -3vh !important ;
}
.Inputs-flow-left-upper-grp-serialServo {
    position: relative;
    top: 10%;
    left: 30%;
}
.serialServo-disabled-true {
    background-color: #e6e6e6 !important;
    opacity: 0.3;
}

.switch-button-oled-false {
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 151px;
    height: 35px;
    text-align: center;
    color: #717171;
    position: relative;
    padding-right: 120px;
    position: relative;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
}
.switch-button-oled-false::before {
    content: 'OLED';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    left: 12%;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    font-family: 'Halcyon_Regular';
}
.switch-button-oled-true::before {
    content: 'OLED';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    border-radius: 30px;
    overflow: hidden;
    width: 150px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-oled-true {
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 150px;
    height: 35px;
    text-align: center;
    background-color: #30a8ce;
    color: #000000;
    position: relative;
    padding-right: 120px;
    cursor: pointer;
    position: relative;
    margin: 10px 0px 0px 0px;
}
.switch-button-checkbox-oled {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.upper-label-input-oled {
    left: 2vh !important ;
}
.Inputs-flow-left-upper-grp-oled {
    position: relative;
    top: 10%;
    left: 30%;
}
.oled-disabled-true {
    /* background-color: #e6e6e6 !important; */
    opacity: 0.3;
}

/* ---------------------------------------------------------------- */

/* Digital  */
.Digital {
    overflow: hidden;
    height: 100%;
}
.digital-ButtonDivInput {
    position: relative;
    left: 15%;
    top: 4%;
}
.digital-ButtonRightDivInput {
    position: relative;
    left: 20%;
    top: 4%;
}

.upper-label-input > span,
.lower-label-input > span {
    top: -1vh !important;
}

.digital-flow-left-upper {
    position: relative;
    height: 155pt;
    background-size: 260pt 200pt;
    background-repeat: no-repeat;
    /* background-image: url('../../Assets/popup\ card-5.png'); */
    background-position: center;
}
.digital-flow-left-upper-grp {
    position: relative;
    left: 30%;
    top: 10%;
}
.Digital-flow-left-upper {
    width: 280pt;
    height: 110pt;
    background-size: 270pt 190pt;
    /* background-image: url('../../Assets/popup\ card-5.png'); */
    /* background-image: url('../../Assets/img/popup card@2x.png'); */
    background-repeat: no-repeat;
    background-position: center;
}

.input-btn-grp {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 10px;

    justify-content: center;
    align-items: center;
}

.single-input-grp-enabled,
.single-input-grp-disabled {
    display: flex;
    align-items: center;
    gap: 1rem;

    scale: 1.05;
}

.single-input-grp-disabled {
    /* pointer-events: none; */
    opacity: 0.4;
}

.input-btn-on,
.input-btn-off {
    all: unset;
    min-width: 75px;
    text-align: center;

    padding: 0.25rem 1rem;
    border-radius: 1rem;
    color: rgb(90, 88, 88);
    background-color: #e6e6e6;
}

.input-btn-on {
    color: white;
    background-color: #30a8ce;
}

.Digital-flow-left-upper-grp {
    position: relative;
    top: 22%;
    left: 30%;
}

.upper-label-input > .digital-textsp {
    left: -60px !important;
    top: 1.5vh !important;
}
/* 
    .Digital-CenterImg{
      left: -20vh !important;
      top: 0;
    } */
/* .Digital-ports-Container {
    display: flex;
    position: relative;
    background-color: #eee;
    width: 100%;
    left: 20%;
    height: 100%;
  } */
.Digital-properties-Container {
    position: relative;
    flex: 20%;
    top: 15%;
    right: 8%;
}

.Digital-ports-Container {
    display: flex;
    position: relative;
    background-color: #eee;
    width: 100%;
    left: 10%;
    height: 90vh;
    margin-top: -10vh;
    overflow-y: auto;
}
.Digital-properties-Container {
    position: relative;
    top: 15%;
    right: 8%;
}
.Digital-properties-b {
    width: 10em;
    height: 22em;
    background-repeat: no-repeat;
    /* background-size: 100% 100%; */
    background-size: 400px 400px;
    background-position-x: -99px;
    /* background-image: url('../../Assets/properties small bg.svg'); */
    opacity: 1;
}
.Digital-properties-bDi {
    display: flex;
    font-size: 0;
    position: relative;
    flex-wrap: wrap;
    flex-direction: column;
    top: 22%;
    left: 39%;
    width: 100%;
}
.Digital-properties-DigitalLabel {
    color: white;
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.Digital-properties-DigitalCheckBox {
    appearance: none;
    width: 70px;
    height: 70px;
    background-size: 100%;
    /* background-image: url('../../Assets/a1.svg'); */
    background-repeat: no-repeat;
    position: relative;
}
.Digital-properties-DigitalCheckBox:checked {
    width: 80px;
    height: 70px;
    background-size: 100%;
    /* background-image: url('../../Assets/a1.svg'); */
    background-repeat: no-repeat;
    color: white;
    position: relative;
}

.switch-button-servo-true {
    opacity: 1;
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 75px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    background-color: #30a8ce;
    position: relative;
    padding-top: 5px;
    content: 'Servo';
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
}
.switch-button-servo-true::before {
    content: 'Servo(180)';
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
}
.switch-button-servo-false::before {
    content: 'Servo(180)';
    color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
}
.switch-button-servo-false {
    opacity: 0.6;
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 75px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #717171;
    position: relative;
    padding-top: 5px;
    font-size: 13.5px;
}
.switch-button-checkbox-servo {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
.upper-label-input-servo {
    left: 1vw !important;
}
.upper-label-input-servo360 {
    position: relative;
    width: 0px;
    height: 0px;
    left: 3vw;
    top: -1vh;
}
.upper-label-input-servo-false {
    opacity: 0.3;
    pointer-events: none;
}

.switch-buttons-servo-true {
    opacity: 1;
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 75px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    background-color: #30a8ce;
    position: relative;
    padding-top: 5px;
    content: 'Servo';
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
    transform: translateX(9%);
}
.switch-buttons-servo-true::before {
    content: 'Servo(360)';
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
    /* transform: translateX(9%); */
}
.switch-buttons-servo-false::before {
    content: 'Servo(360)';
    color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
    /* transform: translateX(9%); */
}
.switch-buttons-servo-false {
    opacity: 0.6;
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 75px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #717171;
    position: relative;
    padding-top: 5px;
    font-size: 13.5px;
    transform: translateX(9%);
}

.switch-buttons-servo270-true {
    opacity: 1;
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 75px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    background-color: #30a8ce;
    position: relative;
    padding-top: 5px;
    content: 'Servo';
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
    transform: translateX(9%);
}
.switch-buttons-servo270-true::before {
    content: 'Servo(270)';
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
    /* transform: translateX(9%); */
}
.switch-buttons-servo270-false::before {
    content: 'Servo(270)';
    color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
    font-size: 13.5px;
    /* transform: translateX(9%); */
}
.switch-buttons-servo270-false {
    opacity: 0.6;
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 75px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #717171;
    position: relative;
    padding-top: 5px;
    font-size: 13.5px;
    transform: translateX(9%);
}

.switch-buttons-checkbox-servo {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}

.switch-button-mp3-true {
    opacity: 1;
    display: inline-block;
    border-radius: 30px;
    overflow: hidden;
    width: 100px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    background-color: #30a8ce;
    position: relative;
    padding-top: 5px;
    content: 'Servo';
    font-family: 'Halcyon_Regular';
    top: -1vh;
    left: -2%;
}
.switch-button-mp3-true::before {
    content: 'MP3';
    color: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-mp3-false::before {
    content: 'MP3';
    color: #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    font-family: 'Halcyon_Regular';
}
.switch-button-mp3-false {
    opacity: 0.6;
    display: inline-block;
    background: #e6e6e6;
    border-radius: 30px;
    overflow: hidden;
    width: 100px;
    height: 35px;
    text-align: center;
    justify-content: center;
    color: #717171;
    position: relative;
    padding-top: 5px;
    top: -1vh;
    left: -2%;
}
.switch-button-checkbox-mp3 {
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}
/* .upper-label-input-mp3 {
    left: 1vw !important;
} */
.upper-label-input-mp3-false {
    opacity: 1;
    /* pointer-events: none; */
}

.A1 {
    color: black;
}
.A2 {
    position: relative;
    top: -1.5em;
    right: 25%;
    pointer-events: none;
    color: white;
}
.InputCheckBox:disabled {
    opacity: 0.5;
}
.A1:disabled {
    opacity: 0.5;
}

.disabled-true {
    opacity: 0.5;
    /* border: 1px solid red; */
}

.toast-msg {
    z-index: 10000;
    /* height: 60px; */
    width: 18vw;
    position: absolute;
    top: 93%;
    right: 32%;
    transform: translate(-50%, -50%);
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-family: 'Halcyon_Regular' !important;
    padding: 16px;
    border-radius: 25px;
    background-color: #d2d2d2;
}
